import { render, staticRenderFns } from "./DistanceTicketSalesContract.vue?vue&type=template&id=ca3665b6&scoped=true&"
import script from "./DistanceTicketSalesContract.vue?vue&type=script&lang=js&"
export * from "./DistanceTicketSalesContract.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca3665b6",
  null
  
)

export default component.exports