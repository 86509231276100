import Vue from "vue";
import axios from "axios";

function getAffiliateHash() {
  const timestampData = Math.floor(Date.now() / 1000);
  const signatureData = Vue.CryptoJS.HmacSHA256(
    `${timestampData}${process.env.VUE_APP_WEB_SECRET}`,
    process.env.VUE_APP_WEB_SECRET
  ).toString(Vue.CryptoJS.enc.Hex);

  const hashData = {
    timestamp: timestampData,
    signature: signatureData
  };

  return Vue.CryptoJS.enc.Base64.stringify(Vue.CryptoJS.enc.Utf8.parse(JSON.stringify(hashData)));
}

const services = {
  async post(url, payload) {
    const options = {
      headers: {
        "Content-type": "application/json",
        "X-Requested-Hash": getAffiliateHash(),
        "X-Requested-Channel": process.env.VUE_APP_WEB_CHANNEL,
        Language: localStorage.getItem("kulewslang")
      },
      validateStatus: (status) => status < 500
    };

    if (typeof Vue.prototype.$session.get("cusdet") !== "undefined") {
      options.headers.Authorization = `Bearer ${Vue.prototype.$session.get("cusdet").token}`;
    }

    const { data } = await axios.post(url, payload, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  },
  async delete(url, params) {
    const options = {
      headers: {
        "Content-type": "application/json",
        "X-Requested-Hash": getAffiliateHash(),
        "X-Requested-Channel": process.env.VUE_APP_WEB_CHANNEL,
        Language: localStorage.getItem("kulewslang")
      },
      params,
      validateStatus: (status) => status < 500
    };

    if (typeof Vue.prototype.$session.get("cusdet") !== "undefined") {
      options.headers.Authorization = `Bearer ${Vue.prototype.$session.get("cusdet").token}`;
    }

    const { data } = await axios.delete(url, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  },
  async get(url, params) {
    const options = {
      headers: {
        "Content-type": "application/json",
        "X-Requested-Hash": getAffiliateHash(),
        "X-Requested-Channel": process.env.VUE_APP_WEB_CHANNEL,
        Language: localStorage.getItem("kulewslang")
      },
      params,
      validateStatus: (status) => status < 500
    };

    if (typeof Vue.prototype.$session.get("cusdet") !== "undefined") {
      options.headers.Authorization = `Bearer ${Vue.prototype.$session.get("cusdet").token}`;
    }

    const { data } = await axios.get(url, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  },
  async put(url, payload) {
    const options = {
      headers: {
        "Content-type": "application/json",
        "X-Requested-Hash": getAffiliateHash(),
        "X-Requested-Channel": process.env.VUE_APP_WEB_CHANNEL,
        Language: localStorage.getItem("kulewslang")
      },
      validateStatus: (status) => status < 500
    };

    if (typeof Vue.prototype.$session.get("cusdet") !== "undefined") {
      options.headers.Authorization = `Bearer ${Vue.prototype.$session.get("cusdet").token}`;
    }

    const { data } = await axios.put(url, payload, options)
      .then((res) => res)
      .catch((err) => err);

    return data;
  }
};

export default services;
