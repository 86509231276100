<template>
  <section class="homepage-headline hero">
    <BannerPopup
      v-show="showHourPopup"
      :image="'kule_ring_araci_guzargahlari_ve_kalkislar_popup.jpg'"
      @popup-closed="showHourPopup = false"
    >
    </BannerPopup>
    <div class="container">
      <div class="row middle-xs">
        <div class="col-md-offset-1 col-md-6">
          <img
            class="headline-master-image"
            src="@/assets/images/hero-master.png"
            alt=""
            v-if="lang == 'tr'"
          />
          <img
            class="headline-master-image"
            src="@/assets/images/hero-master-en.png"
            alt=""
            v-if="lang == 'en'"
          />
        </div>
        <div
          class="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2
                      col-lg-8 headline-content"
        >
          <div class="headline-cta-container first-sm">
            <div class="headline-cta-column">
              <img
                src="@/assets/images/icons/ticket.svg"
                :alt="$t('homepage.ticket_price_title')"
              />
              <ul>
                {{
                  $t("homepage.ticket_price_title")
                }}
                <li>
                  {{ $t("homepage.ticket_tam") }}:
                  {{ this.dynamicContents.homepage_ticket_tam_price || '-' }} TL</li>
                <li>
                  {{ $t("homepage.ticket_indirimli") }}:
                  {{ this.dynamicContents.homepage_ticket_indirimli_price || '-' }} TL
                  <div class="tooltip">
                    <img
                      style="width: 15px; margin-bottom: -3px;"
                      src="/img/faq-green.3cfba16c.svg"
                    />
                    <span class="tooltiptext">{{ $t("homepage.discount_ticket_info") }}</span>
                  </div>
                </li>
                <li>{{ $t("homepage.ticket_hizli") }}:
                  {{ this.dynamicContents.homepage_ticket_hizli_price || '-' }} TL</li>
                <li>{{ $t("homepage.ticket_adult") }}:
                  {{ this.dynamicContents.homepage_ticket_adult_price || '-' }} TL</li>
                <li>{{ $t("homepage.ticket_fast_track") }}:
                  {{ this.dynamicContents.homepage_ticket_fast_track_price || '-' }} TL</li>
                <li>
                  <span style="font-size: 11px;">
                    {{ $t("homepage.ticket_free_description") }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="headline-cta-column">
              <img src="@/assets/images/icons/time.svg" :alt="$t('homepage.working_hours_title')" />
              <ul>
                {{
                  $t("homepage.working_hours_title")
                }}
                <li>{{ $t("homepage.weekday") }}: {{ this.dynamicContents.homepage_weekday }}</li>
                <li>{{ $t("homepage.weekend") }}: {{ this.dynamicContents.homepage_weekend }}</li>
              </ul>
            </div>
            <div class="headline-cta-column">
              <img
                src="@/assets/images/icons/directions-black.svg"
                :alt="$t('homepage.reach_kule_title')"
              />
              <ul>
                {{
                  $t("homepage.reach_kule_title")
                }}
                <li><div v-html="this.dynamicContents.homepage_transport" /></li>
                <li>
                  <a
                    href="https://www.google.com.tr/maps/dir//Küçük+Çamlıca+Televizyon+ve+Radyo+Kulesi,+Küçük+Çamlıca,+Çilehane+Yolu+Cd.+No:33,+34696+Üsküdar%2Fİstanbul/@41.0163958,28.9953875,12z/"
                  >
                    <div class="show-more">
                      {{ $t("homepage.road_desc") }}
                    </div>
                  </a>
                </li>
                <!--<li>
                 <a href="#" @click="openPopup" >
                   <div class="show-more">
                     {{ $t('homepage.service_hours') }}
                   </div></a>
                </li>-->
              </ul>
            </div>
          </div>
          <div class="headline-cta-buttons first-xs">
            <a
              class="button filled b"
              style="background-color: #60cec4;"
              href="https://www.360kule.com/"
              target="_blank"
            >
              {{ $t("homepage.do_reservation_restaurant") }}
            </a>
            <a href="/bilet-al" class="button filled green b">
              {{ $t("homepage.do_reservation_view") }}
              <img src="@/assets/images/icons/arrow-right-black.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <BackgroundMovement></BackgroundMovement>
  </section>
</template>

<script>
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import BannerPopup from "@/components/homepage/BannerPopup.vue";

export default {
  name: "HeadlineHero",
  components: { BackgroundMovement, BannerPopup },
  data() {
    return {
      showHourPopup: false,
      lang: "tr",
      dynamicContents: {}
    };
  },
  async created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
    this.dynamicContents = this.getDynamicContents();
  },
  methods: {
    openPopup() {
      this.showHourPopup = true;
    }
  }
};
</script>

<style scoped></style>
