<template>
  <section class="homepage-contact">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-10 col-lg-6">
          <div class="section-content">
            <div class="section-header">{{ $t('homepage.reach_kule_title') }}</div>
            <div class="section-description">
              <p>{{ $t('homepage.reach_kule_desc') }}</p>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="icon"><img src="@/assets/images/icons/bus-white.svg" alt=""></div>
                <div class="box-header">{{ $t('homepage.bus') }}</div>
                <div class="info">
                  129T<br>
                  34AS<br>
                  34Z
                </div>
              </div>
              <div class="card-content">
                <div class="icon"><img src="@/assets/images/icons/marmaray.png" alt=""></div>
                <div class="box-header">{{ $t('homepage.train') }}</div>
                <div class="info">
                  Marmaray
                </div>
              </div>
              <div class="card-content">
                <div class="icon"><img src="@/assets/images/icons/metro.png" alt=""></div>
                <div class="box-header">{{ $t('homepage.metro') }}</div>
                <div class="info">
                  M4
                </div>
              </div>
              <div class="card-content">
                <div class="icon"><img src="@/assets/images/icons/dolmus.svg" alt=""></div>
                <div class="box-header">{{ $t('homepage.minibus') }}</div>
                <div class="info">
                  Dolmuş: D-36<br>
                  Dolmuş: D-61
                </div>
              </div>
              <div class="navigation-shortcut">
                <p>{{ $t('homepage.reach_by_car_desc') }}</p>
                <a class="button filled white" href="https://www.google.com.tr/maps/dir//Küçük+Çamlıca+Televizyon+ve+Radyo+Kulesi,+Küçük+Çamlıca,+Çilehane+Yolu+Cd.+No:33,+34696+Üsküdar%2Fİstanbul/@41.0163958,28.9953875,12z/" target="_blank">
                  <img src="@/assets/images/icons/directions-green.svg" alt="">
                  {{ $t('homepage.road_desc_button') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact"
};
</script>

<style scoped>

</style>
