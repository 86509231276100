import HttpService from "@/services/http-service";

const dynamicContentService = {
  getAllRecords() {
    return HttpService.get(`${process.env.VUE_APP_CONTENT_API_URL}/content/dynamic`);
  },
  getSlider(data) {
    return HttpService.post(`${process.env.VUE_APP_CONTENT_API_URL}/content/slider`, data);
  },
  getPopup(data) {
    return HttpService.post(`${process.env.VUE_APP_CONTENT_API_URL}/content/popup`, data);
  }
};

export default dynamicContentService;
