import HttpService from "@/services/http-service";

const customerService = {
  register(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/sign-up`, payload);
  },
  login(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/sign-in`, payload);
  },
  getInformation() {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/information`, {});
  },
  verifyEmail(uuid, token) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/email-validation/verification/${uuid}/${token}`, {});
  },
  updateInformation(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/update-information`, payload);
  },
  changePassword(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/change-password`, payload);
  },
  getReservations(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/reservations`, payload);
  },
  sendForgotPasswordEmail(emailHash) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/forgot-password/confirmation/${emailHash}`, {});
  },
  verifyPasswordReset(uuid, token) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/forgot-password/verification/${uuid}/${token}`, {});
  },
  createNewPassword(uuid, token, payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/forgot-password/change/${uuid}/${token}`, payload);
  }
};

export default customerService;
