<template>
  <div class="col-xs-12 col-md-5 col-lg-4 center-xs no-gutter-xs sidebar-summary">
    <div class="sidebar bg-white">
      <div class="mobile-static-bg bg-dark"></div>
      <div v-if="resultTimer > 0" class="box-header">
        <countdown @end="endReservation" class="countdown-text" :time="resultTimer">
          <template slot-scope="props">
            <div class="text">{{ $t('reservation.time_left') }}:</div>
            {{ props.minutes >= 10 ? props.minutes : "0" + props.minutes }}:
            {{ props.seconds >= 10 ? props.seconds : "0" + props.seconds }}
          </template>
        </countdown>
      </div>
      <div class="box-header">
        {{ $t('reservation.reservation_summary') }}
      </div>
      <div v-if="reservationTicketInfo.ticket_count <= 0" class="section-description">
        <p>{{ $t('reservation.summary_not_selected') }}</p>
      </div>
      <div v-if="reservationTicketInfo.ticket_count > 0" class="booked-date-time-visitor">
        <div class="section-description" v-if="lang=='tr'">
          {{ $t('reservation.date') }}:
          <strong>{{ formattedTicketDate | dateFormat("DD MMMM YYYY",
             dateFormatConfigTr) }}</strong>
        </div>
         <div class="section-description" v-if="lang=='en'">
          {{ $t('reservation.date') }}:
          <strong>{{ formattedTicketDate | dateFormat("DD MMMM YYYY",
             dateFormatConfigEn) }}</strong>
        </div>
        <div class="section-description">
          {{ $t('reservation.time') }}:
          <strong>{{ reservationTicketInfo.time_description }}</strong>
        </div>
        <div class="section-description">
          {{ $t('reservation.guest_count') }}:
          <strong>{{ reservationTicketInfo.ticket_count }} {{ $t('reservation.person') }}</strong>
        </div>
      </div>
      <div v-if="
          typeof reservationSummary.tickets !== 'undefined' && reservationSummary.tickets.length > 0
        " class="booked-tickets">
        <div
          v-for="(ticket, index) in reservationSummary.tickets"
          v-bind:key="index"
          class="section-description"
        >
          <div class="sub-info_">
            <strong>{{ ticket.type.name }} {{ $t('reservation.ticket') }}:</strong>
          </div>
          <b class="price">{{ currencyFormat(ticket.unit_price.sale_price) }} TL</b>
          <div
            class="sub-detail-container"
            v-if="typeof ticket.additionalServices !== 'undefined' &&
                ticket.additionalServices.length > 0">
            <div
              v-for="(service, index2) in ticket.additionalServices"
              v-bind:key="index2"
              class="section-description sub-detail"
            >
              <div class="sub-info 22">{{ service.name }}</div>
              <b class="price">{{ currencyFormat(service.unit_price.sale_price) }} TL</b>
            </div>
          </div>
        </div>
      </div>
      <div v-if="typeof reservationSummary.total_price !== 'undefined'" class="booked-price">
        <div
          class="section-description"
          v-if="reservationSummary.total_price.total_discount_price > 0"
        >
          {{ $t('reservation.total_discount') }}:
          <b>{{ currencyFormat(reservationSummary.total_price.total_discount_price) }} TL</b>
        </div>
        <div class="section-description">
          {{ $t('reservation.total_tax') }}:
          <b>{{ currencyFormat(reservationSummary.total_price.total_vat_price) }} TL</b>
        </div>
      </div>
      <div v-if="typeof reservationSummary.total_price !== 'undefined'" class="booked-total">
        <div class="section-description">
          {{ $t('reservation.total_amount') }}:
          <b>{{ currencyFormat(reservationSummary.total_price.total_sale_price) }} TL</b>
        </div>
      </div>
      <div v-if="activeStep === 5" class="book-approve">
        <div class="material-radio-group">
          <input
            v-validate="{ required: true }"
            type="checkbox"
            id="book-approve"
            name="Mesafeli Bilet Satış Sözleşmesi"
          />
          <label v-html="$t('reservation.read_usage_condition')" for="book-approve"></label>
          <span v-show="errors.has('Mesafeli Bilet Satış Sözleşmesi')" class="error-message">
            {{ errors.first("Mesafeli Bilet Satış Sözleşmesi") }}
          </span>
        </div>
      </div>
      <div
        v-if="activeStep > 0 && !paymentApproved"
        class="buttons res-btn"
        :class="`step${activeStep}-${resultTimer}`"
      >
        <a
          v-if="activeStep > 1 && activeStep < 5"
          href="#"
          @click="stepBackward"
          class="button solid mid back"
        >
          <img src="@/assets/images/icons/arrow-left.svg" alt="" />
        </a>
        <a
          href="#"
          v-if="activeStep === 5"
          @click="stepForward"
          class="button filled green b continue"
        >
          {{ $t('reservation.make_payment') }}
        </a>
        <a
          href="#"
          v-if="activeStep === 4"
          @click="stepForward"
          class="button filled green b continue"
        >
          {{ $t('reservation.confirm') }}
        </a>
        <a
          href="#"
          v-if="activeStep < 4"
          @click="stepForward"
          class="button filled green b continue"
        >
          {{ $t('reservation.continue') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Summary",
  props: [
    "reservationSummary",
    "activeStep",
    "resultTimer",
    "paymentApproved",
    "reservationTicketInfo"
  ],
  data() {
    return {
      lang: "tr",
      dateFormatConfigEn: {
        dayOfWeekNames: [
          "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday",
          "Friday", "Saturday"
        ],
        dayOfWeekNamesShort: [
          "Su", "Mo", "Tu", "We", "Tr", "Fr", "Sa"
        ],
        monthNames: [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ],
        monthNamesShort: [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ]
      },
      dateFormatConfigTr: {
        dayOfWeekNames: [
          "Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe",
          "Cuma", "Cumartesi"
        ],
        dayOfWeekNamesShort: [
          "Pa", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"
        ],
        monthNames: [
          "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
          "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ],
        monthNamesShort: [
          "Oca", "Şub", "Mar", "Nis", "May", "Haz",
          "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"
        ]
      }
    };
  },
  async created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
  },
  computed: {
    formattedTicketDate() {
      return new Date(this.reservationTicketInfo.selected_date);
    }
  },
  methods: {
    stepForward() {
      if (this.activeStep === 5) {
        this.$validator.validate("Mesafeli Bilet Satış Sözleşmesi").then((result) => {
          if (result) {
            this.$emit("step-forward");
          }
        });
      } else {
        this.$emit("step-forward");
      }
    },
    stepBackward() {
      this.$emit("step-backward");
    },
    endReservation() {
      this.$emit("reservation-time-out");
    }
  }
};
</script>

<style scoped>
.booked-date-time-visitor,
.booked-tickets,
.booked-price {
  margin-bottom: 20px;
  margin-top: 30px;
  border-bottom: 1px solid rgba(215, 214, 219, 0.3);
}
.sidebar-summary {
  z-index: 3 !important;
}
@media (max-width: 480px) {
  .res-btn {
    position: fixed !important;
    bottom: 0 !important;
    width: 75% !important;
    margin: 0 !important;
    left: 0;
  }
  .res-btn.step1-0 {
    width: 100% !important;
  }
  .countdown-text {
    padding: 9px 10px 12px !important;
    right: 0 !important;
    width: 23% !important;
    position: fixed !important;
    top: unset !important;
    z-index: 4;
    display: block;
    bottom: 10px !important;
  }
  .mobile-static-bg {
    height: 73px;
    background: #fff;
    position: fixed;
    bottom: 0;
    z-index: 3;
    width: 100%;
    left: 0;
  }
}
</style>
