import DynamicContentService from "@/services/content/dynamic-content-service";

export default {
  data() {
    return {
      dynamicContents: {}
    };
  },
  methods: {
    getDynamicContents() {
      if (localStorage.getItem("dynamicContents") != null) {
        this.dynamicContents = JSON.parse(localStorage.getItem("dynamicContents"));
      }
      const data = DynamicContentService.getAllRecords();
      data.then((res) => {
        if (res.status === 200) {
          if (res.data.numberOfElements > 0) {
            this.dynamicContents = Object
              .fromEntries(res.data.content.map((e) => [e.dynamicKey, e.value]));
            localStorage.setItem("dynamicContents", JSON.stringify(this.dynamicContents));
          }
        }
      });
      return this.dynamicContents;
    }
  }
};
