<template>
  <div v-show="messageBar.message.length > 0">
    <div class="message-bar" :class="messageBar.type" @click="hideMessageBar">
      {{ messageBar.message }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "MessageBar",
  computed: {
    ...mapState([
      "messageBar"
    ])
  },
  methods: {
    ...mapActions([
      "hideMessageBar"
    ])
  }
};
</script>
