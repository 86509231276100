export default {
  methods: {
    normalizeObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToItem(item) {
      document.querySelector(item).scrollIntoView({ behavior: "smooth", block: "center" });
      document.querySelector(item).focus();
    },
    removeFromStep(steps, stepName) {
      return steps.filter((ele) => ele !== stepName);
    },
    checkObject(obj, value) {
      return Object.values(obj).every((x) => (x === value));
    },
    checkArrayByValue(arr, key, val) {
      return arr.every((e) => e[key] > val);
    }
  }
};
