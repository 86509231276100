<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="contact hero">
      <div class="container">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('header.contact') }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 margin-auto">
            <MessageBar></MessageBar>
          </div>
        </div>
        <div class="row center-md">
          <div class="col-xs-12 col-md-6 col-lg-offset-1 col-lg-9 start-lg">
            <div class="box-header">{{ $t('contact.write_to_us') }}</div>
          </div>
        </div>
        <div class="row center-lg">
          <div class="col-xs-12 col-md-6 col-lg-offset-1 col-lg-4">
            <div class="bg-transparent">
              <form data-vv-scope="contact">
                <div class="material-form-group">
                  <input
                    v-validate="{ required: true }"
                    :class="{
                      invalid: errors.has('contact.Konu'),
                      valid: contactData.subject.length > 0 && !errors.has('contact.Konu'),
                      required: true
                    }"
                    v-model="contactData.subject"
                    type="text"
                    name="Konu"
                    placeholder=" "
                  />
                  <label>{{ $t('contact.subject') }}</label>
                  <span v-show="errors.has('contact.Konu')" class="error-message">
                    {{ errors.first("contact.Konu") }}
                  </span>
                </div>
                <div class="material-form-group">
                  <input
                    v-validate="{ required: true, min: 3 }"
                    v-model="contactData.name"
                    :class="{
                      invalid: errors.has('contact.Ad Soyad'),
                      valid: contactData.name.length > 0 && !errors.has('contact.Ad Soyad'),
                      required: true
                    }"
                    type="text"
                    name="Ad Soyad"
                    placeholder=" "
                  />
                  <label>{{ $t('contact.name') }}</label>
                  <span v-show="errors.has('contact.Ad Soyad')" class="error-message">
                    {{ errors.first("contact.Ad Soyad") }}
                  </span>
                </div>
                <div class="material-form-group">
                  <input
                    v-validate="{ required: true, email: true }"
                    :class="{
                      invalid: errors.has('contact.Email'),
                      valid: contactData.emailAddress.length > 0 && !errors.has('contact.Email'),
                      required: true
                    }"
                    v-model="contactData.emailAddress"
                    type="text"
                    name="Email"
                    placeholder=" "
                  />
                  <label>{{ $t('contact.email') }}</label>
                  <span v-show="errors.has('contact.Email')" class="error-message">
                    {{ errors.first("contact.Email") }}
                  </span>
                </div>
                <div class="material-form-group textarea">
                  <textarea
                    v-validate="{ required: true, min: 10 }"
                    v-model="contactData.message"
                    :class="{
                      invalid: errors.has('contact.Mesaj'),
                      valid: contactData.message.length > 0 && !errors.has('contact.Mesaj'),
                      required: true
                    }"
                    placeholder=" "
                    name="Mesaj"
                    rows="4"
                  ></textarea>
                  <label>{{ $t('contact.subject') }}</label>
                  <span v-show="errors.has('contact.Mesaj')" class="error-message">
                    {{ errors.first("contact.Mesaj") }}
                  </span>
                </div>
                <div class="material-form-group">
                  <vue-recaptcha
                    sitekey="6LeQkdIZAAAAACdVqfkz3BChzh2s4kf70ticnHVX"
                    @verify="verifyCaptcha"
                    @expired="expireCaptcha"
                    :language="lang"
                    class="captcha"
                    :loadRecaptchaScript=true
                  ></vue-recaptcha>
                  <span v-show="captchaError.length > 0" class="error-message">
                    {{ captchaError }}
                  </span>
                </div>
                <div @click="submitMessage" class="button filled green b block send-button">
                  <vue-element-loading
                    :active="isLoading"
                    background-color="rgba(255, 255, 255, 0.8)"
                    color="#00baab"
                    spinner="spinner"
                  />
                  {{ $t('contact.send_message') }}
                </div>
              </form>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 col-lg-offset-1 col-lg-4">
            <div class="bg-white">
              <div class="box-header">{{ $t('footer.address') }}</div>
              <div class="section-description">
                <p>{{ dynamicContents.contact_address }}</p>
                <p>{{ $t('footer.phone') }}:</p>
              </div>
              <div class="button black filled g block">
                <a :href="'tel:'+cleanSpace(dynamicContents.phone)" class="green">
                  {{ dynamicContents.phone }}
                </a>
              </div>
              <div class="section-description">
                <p>{{ $t('contact.restaurant') }}:</p>
              </div>
              <div class="button black filled g block">
                <a :href="'tel:'+cleanSpace(dynamicContents.phone_restaurant)" class="green">
                  {{ dynamicContents.phone_restaurant }}
                </a>
              </div>
              <div class="section-description">
                <p>{{ $t('footer.email') }}</p>
              </div>
              <div class="button black filled g block">
                 <a :href= "'mailto:' + dynamicContents.email " class="green">
                  {{ dynamicContents.email }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <div class="seperator">
      <img src="@/assets/images/white-clouds-3.png" alt="" />
    </div>
    <ContactBar></ContactBar>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import ContactBar from "@/components/main/ContactBar.vue";
import AssistantService from "@/services/notification/assistant-service";
import MessageBar from "@/components/main/MessageBar.vue";
import { mapActions } from "vuex";
import DynamicContentService from "@/services/content/dynamic-content-service";

export default {
  name: "Contact",
  components: {
    MessageBar,
    BackgroundMovement,
    Footer,
    HeaderNav,
    ContactBar
  },
  data() {
    return {
      lang: "tr",
      isLoading: false,
      captchaError: "",
      contactData: {
        name: "",
        emailAddress: "",
        subject: "",
        message: "",
        "g-recaptcha-response": ""
      },
      dynamicContents: {}
    };
  },
  created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
    this.getAllRecords();
  },
  methods: {
    ...mapActions(["showMessageBar", "hideMessageBar"]),
    verifyCaptcha(data) {
      this.contactData["g-recaptcha-response"] = data;
    },
    expireCaptcha() {
      this.contactData["g-recaptcha-response"] = "";
    },
    submitMessage() {
      this.hideMessageBar();
      this.isLoading = true;
      this.captchaError = "";

      this.$validator.validateAll("contact").then((result) => {
        if (result) {
          if (this.contactData["g-recaptcha-response"].length > 0) {
            const data = AssistantService.sendContactForm(this.contactData);

            data.then((res) => {
              if (res.status === 200) {
                this.showMessageBar({
                  message: res.data,
                  type: "success"
                });

                this.contactData = {
                  name: "",
                  emailAddress: "",
                  subject: "",
                  message: "",
                  "g-recaptcha-response": ""
                };
              } else {
                this.showMessageBar({
                  message: res.message,
                  type: "error"
                });
              }

              this.isLoading = false;
            });
          } else {
            this.captchaError = this.$t("contact.confirm_human");
            this.isLoading = false;
          }
        } else {
          const firstErrorItem = "input[aria-invalid=\"true\"]";
          this.scrollToItem(firstErrorItem);

          this.isLoading = false;
        }
      });
    },
    getAllRecords() {
      const data = DynamicContentService.getAllRecords();
      data.then((res) => {
        if (res.status === 200) {
          this.dynamicContents = Object
            .fromEntries(res.data.content.map((e) => [e.dynamicKey, e.value]));
        }
      });
    },
    cleanSpace(val) {
      // eslint-disable-next-line no-new-wrappers
      return new String(val).replace(/\s+/g, "");
    }
  }
};
</script>

<style scoped>
.send-button {
  margin-top: 40px;
}
.error-message {
  background-color: #fff;
}
.captcha div {
  margin: auto !important;
}
form input,
form textarea {
  background-color: #fff !important;
}
</style>
