<template>
  <div>
    <section class="homepage-gallery">
      <div class="container-fluid">
        <div
          class="row end-md"
          ref="myGallery"
          :data-name="styleMarginLeft"
          :style="{ marginLeft: styleMarginLeft + 'px', marginRight: styleMarginRight + 'px' }"
        >
          <div class="col-xs-12 col-md-3" v-for="(item, index) in galleryData.slice(0,3)"
          :key="index">
            <img :src="`${item.image}`" alt="" />
            <div class="slide-header" v-html="item.title"></div>
            <div class="slider-description" v-html="item.description"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="homepage-gallery">
      <div class="container-fluid">
        <div class="row"
          :style="{ marginLeft: styleMarginRight + 'px', marginRight: styleMarginLeft + 'px' }">
         <div class="col-xs-12 col-md-3" v-for="(item, index) in galleryData.slice(3,6)"
          :key="index">
            <img :src="`${item.image}`" alt="" />
            <div class="slide-header" v-html="item.title"></div>
            <div class="slider-description" v-html="item.description"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: ["galleryData"],
  data() {
    return {
      windowTop: 0,
      styleMarginLeft: 1,
      styleMarginRight: -1
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onScroll() {
      const self = this;
      setTimeout(() => {
        const { top } = self.$refs.myGallery.getBoundingClientRect();
        const currentScrollPosition = top;
        if (currentScrollPosition > self.scrollPosition && window.innerWidth > 768) {
          if (top - window.innerHeight < 0 && window.innerWidth > 768) {
            // console.log('top - window.innerHeight', top - window.innerHeight);
            self.styleMarginLeft += 1;
            self.styleMarginRight -= 1;
          } else {
            self.styleMarginLeft = 1;
            self.styleMarginRight = -1;
          }
        } else if (top - window.innerHeight < 0 && window.innerWidth > 768) {
          // console.log('top - window.innerHeight', top - window.innerHeight);
          self.styleMarginLeft -= 1;
          self.styleMarginRight += 1;
        } else {
          self.styleMarginLeft = 1;
          self.styleMarginRight = -1;
        }
        self.scrollPosition = currentScrollPosition;
      }, 60);
    }
  }
};
</script>

<style scoped></style>
