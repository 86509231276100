<template>
  <div>
    <HeaderNav></HeaderNav>
    <PopupError v-show="showPopupError"></PopupError>
    <section class="booking hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('reservation.title') }}</div>
          </div>
        </div>

        <StepNav
          :activeStep="activeStep"
          :paymentApproved.sync="paymentApproved"
          :completedSteps="completedSteps"
          :stepMapping="stepMapping"
        >
        </StepNav>

        <div class="row">
          <div class="col-xs-12 col-md-7 col-lg-8">
            <MessageBar></MessageBar>
          </div>
          <div class="col-xs-12 col-md-7 col-lg-8 no-gutter-xs">
            <vue-element-loading
              :active="loaderStatus"
              background-color="rgba(255, 255, 255, 0.8)"
              color="#00baab"
              spinner="spinner"
            />
            <TimeOutBox v-if="showTimeOut"></TimeOutBox>
            <DateTimeSelection
              @step-completed="stepCompleted"
              @reset-timer="resetTimer"
              @time-selected="timeSelected"
              @ticket-count-changed="timeSelected"
              @validation-failed="changeValidateStepDataStatus"
              @reservation-time-out="reservationTimeOut('ticket')"
              :reservationData="reservationData"
              :validateStepData.sync="validateStepData"
              v-if="activeStep === 1"
            ></DateTimeSelection>
            <VisitorInfo
              :reservationData="reservationData"
              @validation-failed="changeValidateStepDataStatus"
              @ticket-selected="calculatePreviewSectionData"
              @sms-failed="reservationTimeOut('sms')"
              :calculateObject="calculateObject"
              :validateStepData.sync="validateStepData"
              @step-completed="stepCompleted"
              v-if="activeStep === 2"
            ></VisitorInfo>
            <AdditionalService
              :reservationData="reservationData"
              @validation-failed="changeValidateStepDataStatus"
              @service-selected="calculatePreviewSectionData"
              :previousStep="previousStep"
              :activeStep="activeStep"
              :calculateObject="calculateObject"
              :validateStepData.sync="validateStepData"
              @step-completed="stepCompleted"
              @step-backward="goPreviousStep"
              v-if="activeStep === 3"
            ></AdditionalService>
            <ReservationPreview
              :reservationSummary="reservationSummaryData"
              :reservationData="reservationData"
              :reservationTicketInfo="reservationTicketInfo"
              @step-completed="stepCompleted"
              @validation-failed="changeValidateStepDataStatus"
              :validateStepData.sync="validateStepData"
              v-if="activeStep === 4"
            ></ReservationPreview>
            <Payment
              :validateStepData.sync="validateStepData"
              :reservationData="reservationData"
              :calculateObject="calculateObject"
              :paymentApproved.sync="paymentApproved"
              :retryPayment="retryPayment"
              @payment-approved="paymentCompleted"
              @validation-failed="changeValidateStepDataStatus"
              @payment-retried="disableRetryPaymentStatus"
              v-if="activeStep === 5"
            ></Payment>
          </div>

          <Summary
            v-if="!paymentApproved"
            :reservationSummary="reservationSummaryData"
            v-bind:key="summaryComponentKey"
            :reservationTicketInfo="reservationTicketInfo"
            :activeStep="activeStep"
            :resultTimer.sync="resultTimer"
            :paymentApproved.sync="paymentApproved"
            @reservation-time-out="reservationTimeOut('ticket')"
            @step-forward="goNextStep"
            @step-backward="goPreviousStep"
          >
          </Summary>
        </div>
      </div>

      <BackgroundMovement></BackgroundMovement>
    </section>
    <section class="help-cta">
      <div class="container">
        <div class="row middle-xs">
          <div class="col-xs-12 col-lg-6">
            <div class="section-header">{{ $t("reservation.need_help") }}</div>
            <div class="section-description">
              <p>
                {{ $t("reservation.need_help_desc") }}
              </p>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3 center-xs">
            <a :href="'tel:'+
              cleanSpace(this.dynamicContents.phone)" class="button solid mid g">
                <img src="@/assets/images/icons/call-forwarded.svg" alt="" />
                {{ this.dynamicContents.phone }} </a>
          </div>
          <div class="col-xs-12 col-md-4 col-lg-3 center-xs">
             <a :href= "'mailto:' + this.dynamicContents.email " class="button solid mid g">
                <img src="@/assets/images/icons/mail.svg" alt="" />{{ this.dynamicContents.email }}
              </a>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import Summary from "@/components/reservation/Summary.vue";
import StepNav from "@/components/reservation/StepNav.vue";
import DateTimeSelection from "@/components/reservation/DateTimeSelection.vue";
import VisitorInfo from "@/components/reservation/VisitorInfo.vue";
import AdditionalService from "@/components/reservation/AdditionalService.vue";
import Payment from "@/components/reservation/Payment.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import TicketService from "@/services/reservation/ticket-service";
import ReservationPreview from "@/components/reservation/ReservationPreview.vue";
import TimeOutBox from "@/components/reservation/TimeOutBox.vue";
import { mapState, mapActions } from "vuex";
import PopupError from "@/components/main/PopupError.vue";
import MessageBar from "@/components/main/MessageBar.vue";

export default {
  name: "Reservation",
  components: {
    PopupError,
    TimeOutBox,
    ReservationPreview,
    BackgroundMovement,
    Payment,
    AdditionalService,
    VisitorInfo,
    DateTimeSelection,
    StepNav,
    Summary,
    Footer,
    HeaderNav,
    MessageBar
  },
  computed: {
    ...mapState(["loaderStatus", "showPopupError"])
  },
  data() {
    return {
      paymentApproved: false,
      paymentError: this.$route.query.hata,
      validateStepData: false,
      completedSteps: [],
      resultTimer: 0,
      activeStep: 0,
      previousStep: 0,
      summaryComponentKey: 0,
      retryPayment: false,
      showTimeOut: false,
      stepMapping: {
        1: "DateTimeSelection",
        2: "VisitorInfo",
        3: "AdditionalService",
        4: "ReservationPreview",
        5: "Payment"
      },
      reservationSummaryData: {},
      reservationTicketInfo: {
        selected_date: "",
        selected_time: "",
        time_description: "",
        ticket_count: ""
      },
      reservationDetail: {},
      calculateObject: {
        tickets: []
      },
      reservationData: {},
      stepButtonStatus: false,
      reservationToken:
        typeof this.$route.query.rezervasyon === "undefined" ? null : this.$route.query.rezervasyon,
      dynamicContents: {}
    };
  },
  async created() {
    if (this.reservationToken !== null) {
      if (typeof this.paymentError !== "undefined") {
        this.reservationData = this.$session.get("resdet");
        this.reservationSummaryData = this.$session.get("ressummary");
        this.reservationTicketInfo = this.$session.get("resinfo");
        for (let i = 1; i < this.activeStep; i += 1) {
          this.completedSteps.push(this.stepMapping[i]);
        }
        this.activeStep = 5;
        this.resultTimer = 300000;
        this.retryPayment = true;

        this.changeErrorMessage({
          title: "Ödeme Hatası",
          message: this.paymentError,
          type: "validation"
        });
        this.changePopupErrorStatus(true);
        await this.$router.replace({});
      } else {
        this.activeStep = 1;
        this.$session.set("restoken", this.reservationToken);
      }
    } else {
      this.activeStep = 1;
      this.$session.remove("restoken");
      this.$session.remove("resdet");
      this.$session.remove("resinfo");
      this.$session.remove("ressummary");
    }
    this.dynamicContents = this.getDynamicContents();
  },
  methods: {
    ...mapActions(["changePopupErrorStatus", "changeErrorMessage"]),
    goNextStep() {
      this.validateStepData = true;
    },
    goPreviousStep() {
      this.previousStep = JSON.parse(JSON.stringify(this.activeStep));
      this.completedSteps = this.removeFromStep(
        this.completedSteps,
        this.stepMapping[this.activeStep]
      );
      delete this.reservationData[this.stepMapping[this.activeStep]];
      this.activeStep -= 1;
      this.validateStepData = false;
      this.completedSteps = this.removeFromStep(
        this.completedSteps,
        this.stepMapping[this.activeStep]
      );
    },
    disableRetryPaymentStatus() {
      this.retryPayment = false;
    },
    stepCompleted(stepData, stepNumber) {
      this.reservationData[this.stepMapping[stepNumber]] = stepData;
      this.completedSteps.push(this.stepMapping[this.activeStep]);
      this.fillReservationSummary();
      this.validateStepData = false;
      this.activeStep += 1;
    },
    calculatePreviewSectionData(stepData, stepNumber) {
      this.reservationData[this.stepMapping[stepNumber]] = stepData;
      this.fillReservationSummary();
    },
    calculateReservationSummary() {
      const data = TicketService.calculateReservationPrice(this.calculateObject);

      data.then((res) => {
        if (res.status === 200) {
          this.reservationSummaryData = res.data;
        }
      });
    },
    async getBlockDetail() {
      const data = TicketService.getBlockDetail(this.reservationToken, {});

      await data.then((res) => {
        if (res.status === 200) {
          this.$session.set("restoken", this.reservationToken);
          this.reservationData.DateTimeSelection = {};
          this.reservationData.DateTimeSelection.selected_date = new Date(
            this.getDateByGiven(res.data.date)
          );
          this.reservationData.DateTimeSelection.selected_time = res.data.regularReservationTimeId;
          this.reservationData.DateTimeSelection.ticket_count = res.data.ticketCount;
          this.reservationData.DateTimeSelection.time_description = "";
        }
      });
    },
    deleteBlockedReservation() {
      const data = TicketService.deleteBlockedReservation(this.$session.get("restoken"), {});

      data.then(() => {});
    },
    changeValidateStepDataStatus() {
      this.validateStepData = false;
    },
    paymentCompleted(data) {
      this.reservationDetail = data;
      this.completedSteps.push(this.stepMapping[this.activeStep]);
      this.paymentApproved = true;
      this.activeStep = 0;
      this.$router.push(`/rezervasyon-detay/${data.uuid}`);
    },
    timeSelected(data) {
      this.reservationTicketInfo = data;
    },
    resetTimer() {
      this.resultTimer = 900000;
      this.summaryComponentKey += 1;
    },
    reservationTimeOut(type) {
      this.changeErrorMessage({
        title: "Süre Doldu",
        message:
          type === "ticket"
            ? "Size ayrılan süre içinde rezervayonu tamamlayamadınız."
            : "Size ayrılan süre içinde SMS onayını gerçekleştiremediniz.",
        type: "validation"
      });
      this.changePopupErrorStatus(true);
      this.validateStepData = false;

      this.$session.remove("restoken");
      this.activeStep = 1;
      this.resultTimer = 0;
      this.reservationSummaryData = {};
      this.reservationData = {};
      this.reservationTicketInfo = {
        selected_date: "",
        selected_time: "",
        time_description: "",
        ticket_count: ""
      };
    },
    fillReservationSummary() {
      if (this.resultTimer === 0) {
        this.resultTimer = 900000;
      }

      this.calculateObject.tickets = [];

      if (typeof this.reservationData.DateTimeSelection !== "undefined") {
        this.reservationTicketInfo = this.reservationData.DateTimeSelection;
      }

      if (typeof this.reservationData.VisitorInfo !== "undefined") {
        this.reservationData.VisitorInfo.ticket_details.forEach((ticket) => {
          this.calculateObject.tickets.push({ typeId: ticket.id });
        });
      }

      if (
        typeof this.reservationData.AdditionalService !== "undefined"
        && this.reservationData.DateTimeSelection.ticket_count
          === this.reservationData.AdditionalService.selected_additional_list.length
      ) {
        this.reservationData.AdditionalService.additional_ticket_types.forEach((ticket, index) => {
          ticket.selected_list.forEach((additionalData) => {
            if (typeof this.calculateObject.tickets[index].additionalServiceIds === "undefined") {
              this.calculateObject.tickets[index].additionalServiceIds = [];
              this.calculateObject.tickets[index].additionalServiceIds.push(additionalData.id);
            } else {
              this.calculateObject.tickets[index].additionalServiceIds.push(additionalData.id);
            }
          });
        });
      }

      this.calculateReservationSummary();
    },
    cleanSpace(val) {
      // eslint-disable-next-line no-new-wrappers
      return new String(val).replace(/\s+/g, "");
    }
  }
};
</script>

<style scoped>
.velmld-overlay {
  right: 10px !important;
  left: 10px !important;
}
</style>
