<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="terms-of-use hero">
      <div class="container no-gutter-xs">
        <div class="row center-xs">
          <div class="col-xs-12">
            <div class="section-header">{{ $t('temsofuse.title') }}</div>
          </div>
        </div>
        <div class="row terms-of-use center-xs">
          <div class="col-xs-12 col-md-9">
            <div class="content">
              <div class="content-body">
                <p>
                  <ol>
                    <li>{{ $t('temsofuse.item1') }}<b>“camlicakule.istanbul”</b>
                    {{ $t('temsofuse.item1_1') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item2') }}<b>“camlicakule.istanbul”</b>
                    {{ $t('temsofuse.item2_1') }}
                    </li>
                    <br>
                     <li>{{ $t('temsofuse.item3') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item4') }}<b>“camlicakule.istanbul”</b>
                    {{ $t('temsofuse.item4_1') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item5') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item6') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item7') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item8') }}<b>“camlicakule.istanbul”</b>
                    {{ $t('temsofuse.item8_1') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item9') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item10') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item11') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item12') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item13') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item14') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item15') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item16') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item17') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item18') }}<b>“camlicakule.istanbul”</b>
                    {{ $t('temsofuse.item18_1') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item19') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item20') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item21') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item22') }}<b>“camlicakule.istanbul”</b>
                    {{ $t('temsofuse.item22_1') }}
                    </li>
                    <br>
                    <li>{{ $t('temsofuse.item23') }}<b>“camlicakule.istanbul”</b>
                    {{ $t('temsofuse.item23_1') }}
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";

export default {
  name: "TermsOfUse",
  components: { Footer, HeaderNav }
};
</script>

<style scoped>

</style>
