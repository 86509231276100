<template>
  <div class="background-movement">
    <div class="one rellax" v-rellax="{ speed: 2 }">
      <img src="@/assets/images/parallax-0.png" alt="">
    </div>
    <div class="two rellax" v-rellax="{ speed: 3 }">
      <img src="@/assets/images/parallax-1.png" alt="">
    </div>
    <div class="three rellax" v-rellax="{ speed: 5 }">
      <img src="@/assets/images/parallax-2.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "BackgroundMovement"
};
</script>

<style scoped>
  .background-movement {
    z-index: 1;
  }
</style>
