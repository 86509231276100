import HttpService from "@/services/http-service";

const faqService = {
  getAllRecords(lang) {
    return HttpService.get(`${process.env.VUE_APP_CONTENT_API_URL}/content/faq?lang=${lang}`);
  },
  getLast5Records(lang) {
    return HttpService.get(`${process.env.VUE_APP_CONTENT_API_URL}/content/faq?page=0&size=5&lang=${lang}`);
  }
};

export default faqService;
