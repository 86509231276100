<template>
  <section class="popup verify-timer">
    <!--    <div class="popup-header">-->
    <!--      <div class="approve-text">-->
    <!--        Telefon Numaranızı Doğrulayın-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="popup-body">
      <div @click="cancelValidation" class="popup-closer"></div>
      <div class="popup-box-header">
        {{ $t("sms_verification.verify_phone") }}
      </div>
      <div v-if="errorMessage.length > 0" class="section-description error">
        <p>{{ errorMessage }}</p>
      </div>
      <vue-element-loading
        :active="isLoading"
        background-color="rgba(255, 255, 255, 1)"
        color="#00baab"
        spinner="spinner"
      />
      <p v-if="smsTimer > 0" class="section-description">
        <strong>{{ phoneNumber }}</strong> {{ $t("sms_verification.enter_password") }}
      </p>
      <p v-if="smsTimer > 0" class="remaining-time">
        <img src="@/assets/images/icons/timer.svg" alt="" />
        <countdown @end="endSmsOtp" class="countdown-text" :time="smsTimer">
          <template slot-scope="props">
            {{ props.minutes >= 10 ? props.minutes : "0" + props.minutes }}:
            {{ props.seconds >= 10 ? props.seconds : "0" + props.seconds }}
          </template>
        </countdown>
      </p>
      <div v-if="sendAgainActive" @click="sendSmsCode" class="button filled green b">
        {{ $t("sms_verification.send_new_pass") }}
      </div>
      <form v-if="smsTimer > 0 && !sendAgainActive" data-vv-scope="sms-verification">
        <div class="material-form-group">
          <input
            id="sms-code"
            v-model="smsCode"
            v-validate="{ required: true, regex: /^[0-9]{4}$/ }"
            v-mask="'####'"
            name="SMS Kodu"
            maxlength="4"
            type="tel"
            placeholder=" "
          />
          <label>SMS Kodu</label>
          <span v-show="errors.has('sms-verification.SMS Kodu')" class="error-message">
            {{ errors.first("sms-verification.SMS Kodu") }}
          </span>
        </div>
        <div @click="verifyCode" class="button filled green b">
          <vue-element-loading
            :active="verificationLoader"
            background-color="rgba(255, 255, 255, 1)"
            color="#00baab"
            spinner="spinner"
          />
          {{ $t("sms_verification.verify") }}
        </div>
        <div @click="cancelValidation" class="button filled b grey">
          {{ $t("sms_verification.cancel") }}
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import OtpService from "@/services/notification/otp-service";

export default {
  name: "SmsVerification",
  props: ["phoneNumber"],
  data() {
    return {
      errorMessage: "",
      smsTimer: 0,
      sendAgainActive: false,
      sentCount: 0,
      smsCode: "",
      verificationLoader: false,
      isLoading: false
    };
  },
  async created() {
    await this.sendSmsCode();
    document.getElementById("sms-code").focus();
  },
  methods: {
    endSmsOtp() {
      if (this.sentCount < 2) {
        this.sendAgainActive = true;
      } else {
        this.$emit("sms-time-out");
      }
    },
    cancelValidation() {
      this.$emit("cancel-validation");
    },
    async sendSmsCode() {
      this.errorMessage = "";
      this.sendAgainActive = false;
      this.isLoading = true;

      const data = OtpService.sendSmsConfirmation({
        phoneNumber: this.phoneNumber.replace(/[^A-Z0-9]/gi, "")
      });

      await data.then((res) => {
        if (res.status === 200) {
          this.sentCount += 1;
          this.smsTimer = 180000;
        } else {
          this.sendAgainActive = true;
          this.errorMessage = res.message;
        }

        this.isLoading = false;
      });
    },
    verifyCode() {
      this.$validator.validateAll("sms-verification").then((result) => {
        if (result) {
          this.errorMessage = "";
          this.verificationLoader = true;

          const data = OtpService.verifyPhoneNumber({
            phoneNumber: this.phoneNumber.replace(/[^A-Z0-9]/gi, ""),
            token: this.smsCode
          });

          data.then((res) => {
            if (res.status === 200) {
              this.$emit("phone-verified");
            } else {
              this.errorMessage = res.message;
            }

            this.verificationLoader = false;
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.button.grey {
  background-color: rgba(10, 10, 15, 0.1);
}
</style>
