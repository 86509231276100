<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Çamlıca Kulesi";
      }
    }
  }
};
</script>
<style>
.error-message {
  color: #d60d38;
  font: 400 10px/18px "Poppins", sans-serif;
  text-align: left;
  display: block;
  margin-bottom: 15px;
}
.section-description.error p {
  color: #d60d38 !important;
}
.margin-auto {
  margin: auto !important;
}
.material-form-group select {
  width: 95% !important;
}
.material-form-group select {
  border: 0;
  color: #202625;
  font: 500 14px/18px "Poppins", sans-serif;
  height: 100%;
  outline: none;
  padding: 15px 0 0 12px;
  width: 100%;
}
.material-form-group select.valid + label, .material-form-group select.invalid + label {
  align-items: flex-start;
  color: #9997a2;
  font: 500 11px/12px "Poppins", sans-serif !important;
  padding-left: 12px;
  padding-top: 5px;
}
.material-form-group select:focus + label:after {
  transform: scale(1, 1);
}
.material-form-group select + label {
  align-items: flex-start;
  color: rgba(37, 51, 66, 0.4);
  font: 500 14px/37px "Poppins", sans-serif !important;
}
.material-form-group select.valid + label:after {
  transform: scale(1, 1);
  border-bottom: 2px solid #00a092;
}
.material-form-group select.required + label {
  background: url(./assets/images/asterix.svg) no-repeat right 10px top 8px / 6px;
}
</style>
