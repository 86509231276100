<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="account hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t("header.signup") }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 margin-auto">
            <MessageBar></MessageBar>
          </div>

          <div class="col-xs-12 col-md-12 no-gutter-xs">
            <div class="row">
              <div class="col-xs-12 col-md-6 margin-auto">
                <div class="bg-white">
                  <div class="box-header">{{ $t("register.register_info") }}</div>
                  <div class="section-description">
                    <p>{{ $t("register.info_desc") }}</p>
                  </div>
                  <form data-vv-scope="register">
                    <div class="material-form-group">
                      <input
                        v-model="registerObject.firstName"
                        v-validate="{ required: true }"
                        :class="{
                          invalid: errors.has('register.İsim'),
                          valid:
                            registerObject.firstName.length > 0 && !errors.has('register.İsim'),
                          required: true
                        }"
                        name="İsim"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("register.name") }}</label>
                      <span v-show="errors.has('register.İsim')" class="error-message">
                        {{ errors.first("register.İsim") }}
                      </span>
                    </div>
                    <div class="material-form-group">
                      <input
                        v-validate="{ required: true }"
                        name="Soyisim"
                        v-model="registerObject.lastName"
                        :class="{
                          invalid: errors.has('register.Soyisim'),
                          valid:
                            registerObject.lastName.length > 0 && !errors.has('register.Soyisim'),
                          required: true
                        }"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("register.surname") }}</label>
                      <span v-show="errors.has('register.Soyisim')" class="error-message">
                        {{ errors.first("register.Soyisim") }}
                      </span>
                    </div>
                    <div class="row">
                      <div class="col-xs-5">
                        <div class="material-form-group">
                          <select
                            name="Ülke Kodu"
                            id="phoneCountryCode"
                            v-validate="{ required: true }"
                            :class="{
                              invalid: errors.has('register.Ülke Kodu'),
                              valid:
                                registerObject.phoneCountryCode.length > 0 &&
                                !errors.has('register.Ülke Kodu'),
                              required: true
                            }"
                            v-model="registerObject.phoneCountryCode"
                          >
                            <option
                              v-for="(item, index) in phoneCountryCodeList"
                              v-bind:key="index"
                              :value="item.dial_code.substring(1)"
                            >
                              {{ item.name }} ({{ item.dial_code }})
                            </option>
                          </select>
                          <label for="phoneCountryCode">{{ $t("register.country_code") }}</label>
                          <span v-show="errors.has('register.Ülke Kodu')" class="error-message">
                            {{ errors.first("register.Ülke Kodu") }}
                          </span>
                        </div>
                      </div>
                      <div class="col-xs-7">
                        <div class="material-form-group">
                          <input
                            v-model="registerObject.phoneNumber"
                            name="Telefon"
                            :class="{
                              invalid: errors.has('register.Telefon'),
                              valid:
                                registerObject.phoneNumber.length > 0 &&
                                !errors.has('register.Telefon'),
                              required: true
                            }"
                            type="tel"
                            v-validate="{
                              required: true,
                              regex: /^[0-9]*$/
                            }"
                            placeholder=" "
                          />
                          <label>{{ $t("register.phone") }}</label>
                          <span v-show="errors.has('register.Telefon')" class="error-message">
                            {{ errors.first("register.Telefon") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="material-form-group">
                      <input
                        v-model="registerObject.emailAddress"
                        v-validate="{ required: true }"
                        :class="{
                          invalid: errors.has('register.Email'),
                          valid:
                            registerObject.emailAddress.length > 0 && !errors.has('register.Email'),
                          required: true
                        }"
                        name="Email"
                        type="email"
                        placeholder=" "
                      />
                      <label>{{ $t("register.email") }}</label>
                      <span v-show="errors.has('register.Email')" class="error-message">
                        {{ errors.first("register.Email") }}
                      </span>
                    </div>
                    <div class="material-form-group">
                      <input
                        v-model="registerObject.password"
                        v-validate="{ required: true }"
                        :class="{
                          invalid: errors.has('register.Şifre'),
                          valid:
                            registerObject.password.length > 0 && !errors.has('register.Şifre'),
                          required: true
                        }"
                        ref="password"
                        name="Şifre"
                        type="password"
                        placeholder=" "
                        autocomplete="on"
                      />
                      <label>{{ $t("register.password") }}</label>
                    </div>
                    <ul class="requirements">
                      <li :class="{ ok: passwordValidation.charLength }">
                        {{ $t("register.password_six_chars") }}
                      </li>
                      <li :class="{ ok: passwordValidation.uppercaseChar }">
                        {{ $t("register.password_uppercase") }}
                      </li>
                      <li :class="{ ok: passwordValidation.lowercaseChar }">
                        {{ $t("register.password_lowercase") }}
                      </li>
                      <li :class="{ ok: passwordValidation.number }">
                        {{ $t("register.password_number") }}
                      </li>
                    </ul>
                    <div class="material-form-group">
                      <input
                        v-validate="{ required: true, confirmed: 'password' }"
                        v-model="registerObject.password_again"
                        :class="{
                          invalid: errors.has('register.password_again'),
                          valid:
                            registerObject.password_again.length > 0 &&
                            !errors.has('register.password_again'),
                          required: true
                        }"
                        name="password_again"
                        type="password"
                        placeholder=" "
                        autocomplete="on"
                      />
                      <label>{{ $t("register.password_reenter") }}</label>
                    </div>
                    <ul class="requirements">
                      <li :class="{ ok: passwordValidation.repeatPasswordSame }">
                        {{ $t("register.password_same") }}
                      </li>
                    </ul>
                    <div class="gdpr-description">
                      <p v-html="$t('register.personal_info_protection')"></p>
                      <div class="gdpr-approve">
                        <div class="material-radio-group">
                          <input
                            type="checkbox"
                            v-model="registerObject.smsNotification"
                            id="sms-approve"
                            name="Mesafeli Bilet Satış Sözleşmesi"
                          />
                          <label for="sms-approve"> {{ $t("register.sms_notification") }}</label>
                        </div>
                        <div class="material-radio-group">
                          <input
                            type="checkbox"
                            v-model="registerObject.emailNotification"
                            id="email-approve"
                            name="Mesafeli Bilet Satış Sözleşmesi"
                          />
                          <label for="email-approve"> {{ $t("register.mail_notification") }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="material-form-group">
                      <vue-recaptcha
                        sitekey="6LeQkdIZAAAAACdVqfkz3BChzh2s4kf70ticnHVX"
                        @verify="verifyCaptcha"
                        @expired="expireCaptcha"
                        :language="lang"
                        class="captcha"
                        :loadRecaptchaScript="true"
                      ></vue-recaptcha>
                      <span v-show="captchaError.length > 0" class="error-message">
                        {{ captchaError }}
                      </span>
                    </div>
                    <div
                      @click="registerCustomer"
                      class="button filled green b centered submit-btn"
                    >
                      <vue-element-loading
                        :active="isLoading"
                        background-color="rgba(255, 255, 255, 0.8)"
                        color="#00baab"
                        spinner="spinner"
                      />
                      {{ $t("register.create_account") }}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="row info-box">
              <div class="col-xs-12 col-md-6 margin-auto">
                <div class="bg-white">
                  <div class="box-header">{{ $t("register.already_member") }}</div>
                  <div @click="$router.push('giris-yap')" class="button solid mid g">
                    {{ $t("header.login") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import CustomerService from "@/services/customer/customer-service";
import { mapActions } from "vuex";
import MessageBar from "@/components/main/MessageBar.vue";

const countryPhoneList = require("@/data/countryPhoneList.json");

export default {
  name: "Register",
  beforeCreate() {
    if (typeof this.$session.get("cusdet") !== "undefined") {
      this.$router.push("/");
    }
  },
  data() {
    return {
      lang: "tr",
      isLoading: false,
      captchaError: "",
      phoneCountryCodeList: countryPhoneList,
      registerObject: {
        firstName: "",
        lastName: "",
        phoneCountryCode: "90",
        phoneNumber: "",
        emailAddress: "",
        password: "",
        password_again: "",
        emailNotification: false,
        smsNotification: false,
        "g-recaptcha-response": ""
      },
      passwordValidation: {
        charLength: false,
        uppercaseChar: false,
        lowercaseChar: false,
        number: false,
        repeatPasswordSame: false
      }
    };
  },
  async created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
  },
  watch: {
    "registerObject.password_again": {
      handler() {
        this.$validator.validate("register.password_again").then((result) => {
          if (result) {
            this.passwordValidation.repeatPasswordSame = true;
          } else {
            this.passwordValidation.repeatPasswordSame = false;
          }
        });
      }
    },
    "registerObject.password": {
      handler(newVal) {
        const charRegex = RegExp(/^.{6,}$/);
        const uppercaseCharRegex = RegExp(/.*[A-Z].*/);
        const lowercaseCharRegex = RegExp(/.*[a-z].*/);
        const numberRegex = RegExp(/\d/);

        if (charRegex.test(newVal)) {
          this.passwordValidation.charLength = true;
        } else {
          this.passwordValidation.charLength = false;
        }

        if (uppercaseCharRegex.test(newVal)) {
          this.passwordValidation.uppercaseChar = true;
        } else {
          this.passwordValidation.uppercaseChar = false;
        }

        if (lowercaseCharRegex.test(newVal)) {
          this.passwordValidation.lowercaseChar = true;
        } else {
          this.passwordValidation.lowercaseChar = false;
        }

        if (numberRegex.test(newVal)) {
          this.passwordValidation.number = true;
        } else {
          this.passwordValidation.number = false;
        }

        if (this.registerObject.password_again.length > 0) {
          this.$validator.validate("register.password_again").then((result) => {
            if (result) {
              this.passwordValidation.repeatPasswordSame = true;
            } else {
              this.passwordValidation.repeatPasswordSame = false;
            }
          });
        }
      }
    }
  },
  components: {
    MessageBar,
    BackgroundMovement,
    Footer,
    HeaderNav
  },
  methods: {
    ...mapActions(["changeRegisterSuccessStatus", "hideMessageBar", "showMessageBar"]),
    verifyCaptcha(data) {
      this.captchaError = "";
      this.registerObject["g-recaptcha-response"] = data;
    },
    expireCaptcha() {
      this.registerObject["g-recaptcha-response"] = "";
    },
    registerCustomer() {
      this.captchaError = "";
      this.isLoading = true;
      this.hideMessageBar();

      this.$validator.validateAll("register").then((result) => {
        if (result && this.checkObject(this.passwordValidation, true)) {
          if (this.registerObject["g-recaptcha-response"].length > 0) {
            const requestData = this.normalizeObject(this.registerObject);
            requestData.phoneNumber = `${
              requestData.phoneCountryCode
            } ${requestData.phoneNumber.replace(/[^A-Z0-9]/gi, "")}`;

            const data = CustomerService.register(requestData);

            data.then((res) => {
              if (res.status === 200) {
                this.showMessageBar({
                  message: `${this.$t("generic.successful")}, ${this.$t("register.read_email")}`,
                  type: "success"
                });
                this.$router.push("giris-yap");
              } else {
                this.showMessageBar({
                  message: `${this.$t("generic.unexpected_error")}(${res.message})`,
                  type: "error"
                });
              }

              this.isLoading = false;
            });
          } else {
            this.captchaError = this.$t("register.captchaError");
            this.isLoading = false;
          }
        } else if (!this.checkObject(this.passwordValidation, true)) {
          this.isLoading = false;
        } else {
          const firstErrorItem = "input[aria-invalid=\"true\"]";

          this.scrollToItem(firstErrorItem);
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.margin-auto {
  margin: auto !important;
}
.info-box {
  text-align: center;
  margin-top: 50px;
}
.error-message {
  color: #d60d38;
  font: 400 10px/18px "Poppins", sans-serif;
}
.centered {
  display: block;
  width: fit-content;
  margin: auto;
}
.submit-btn {
  margin-top: 50px;
}
.captcha div {
  margin: auto !important;
}
</style>
