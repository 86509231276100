import Vue from "vue";
import VueRellax from "vue-rellax";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSession from "vue-session";
import VueMoment from "vue-moment";
import VueCryptojs from "vue-cryptojs";
import VueFilterDateFormat from "vue-filter-date-format";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueMask from "v-mask";
import VueElementLoading from "vue-element-loading";
import VeeValidate, { Validator } from "vee-validate";
import WindowHelper from "@/mixins/WindowHelper";
import NumberHelper from "@/mixins/NumberHelper";
import DataHelper from "@/mixins/DataHelper";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import DateHelper from "@/mixins/DateHelper";
import MobileDetectionHelper from "@/mixins/MobileDetectionHelper";
import DynamicContent from "@/mixins/DynamicContent";
import VueRecaptcha from "vue-recaptcha";
import tr from "vee-validate/dist/locale/tr";
import en from "vee-validate/dist/locale/en";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import VueGtag from "vue-gtag";
import VueSplide from "@splidejs/vue-splide";
import i18n from "./i18n";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "@/assets/scss/app.scss";

Vue.use(
  VueGtag,
  {
    config: { id: "G-D3H4LN4NFJ" }
  },
  router
);
if (i18n.locale === "tr") {
  Validator.localize("tr", tr);
} else {
  Validator.localize("en", en);
}
Vue.use(VueCryptojs);
Vue.use(VueRellax);
Vue.use(VueAxios, axios);
Vue.use(VueSession, { persist: true });
Vue.component(VueCountdown.name, VueCountdown);
Vue.component("VueElementLoading", VueElementLoading);
Vue.component(VueQrcode.name, VueQrcode);
Vue.component("VueRecaptcha", VueRecaptcha);
Vue.use(VeeValidate);
Vue.use(VueMoment);
Vue.use(VueMask);
Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    "Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe",
    "Cuma", "Cumartesi"
  ],
  dayOfWeekNamesShort: [
    "Pa", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"
  ],
  monthNames: [
    "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", i18n.t("generic.jun", i18n.locale),
    "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
  ],
  monthNamesShort: [
    "Oca", "Şub", "Mar", "Nis", "May", "Haz",
    "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"
  ]
});
Vue.use(VueSplide);
Vue.mixin(DateHelper);
Vue.mixin(MobileDetectionHelper);
Vue.mixin(WindowHelper);
Vue.mixin(NumberHelper);
Vue.mixin(DataHelper);
Vue.mixin(DynamicContent);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
