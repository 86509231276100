<template>
  <div class="row">
    <div class="col-xs-12 no-gutter-xs">
      <div class="step-numbers" :class="{ approved: paymentApproved }">
        <div v-if="paymentApproved" class="header-icon">
          <img src="@/assets/images/icons/check-mark-white.svg" alt="" />
        </div>
        <div v-if="paymentApproved" class="approve-text">
          {{ $t("step_nav.reservation_approved") }}!
        </div>
        <div class="row">
          <div
            class="col-md left-xs middle-xs flex"
            :class="{
              active: activeStep === 1,
              checked: completedSteps.indexOf(stepMapping[1]) >= 0
            }"
          >
            <div class="button solid mid w"><span>1</span></div>
            <div class="step-text">{{ $t("step_nav.datetime") }}</div>
          </div>
          <div
            class="col-md left-xs middle-xs flex"
            :class="{
              active: activeStep === 2,
              checked: completedSteps.indexOf(stepMapping[2]) >= 0
            }"
          >
            <div class="button solid mid w"><span>2</span></div>
            <div class="step-text">{{ $t("step_nav.visitor_info") }}</div>
          </div>
          <div
            class="col-md left-xs middle-xs flex"
            :class="{
              active: activeStep === 3,
              checked: completedSteps.indexOf(stepMapping[3]) >= 0
            }"
          >
            <div class="button solid mid w"><span>3</span></div>
            <div class="step-text">{{ $t("step_nav.additional_services") }}</div>
          </div>
          <div
            class="col-md left-xs middle-xs flex"
            :class="{
              active: activeStep === 4,
              checked: completedSteps.indexOf(stepMapping[4]) >= 0
            }"
          >
            <div class="button solid mid w"><span>4</span></div>
            <div class="step-text">{{ $t("step_nav.verification") }}</div>
          </div>
          <div
            class="col-md left-xs middle-xs flex"
            :class="{
              active: activeStep === 5,
              checked: completedSteps.indexOf(stepMapping[5]) >= 0
            }"
          >
            <div class="button solid mid w"><span>5</span></div>
            <div class="step-text">{{ $t("step_nav.payment") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepNav",
  props: ["activeStep", "completedSteps", "stepMapping", "paymentApproved"]
};
</script>

<style scoped></style>
