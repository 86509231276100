<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="faq-tabs hero">
      <div class="container">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('header.faq') }}</div>
          </div>
        </div>
        <div class="row center-xs">
<!--          <div class="col-xs-12 col-md-3">-->
<!--            <ul class="sidebar-menu">-->
<!--              Konular-->
<!--              <li class="active">Güvenlik Protokolleri</li>-->
<!--              <li>Erişilebilirlik</li>-->
<!--              <li>Yardım Yönlendirmesi</li>-->
<!--              <li>Otopark</li>-->
<!--              <li>Üyelik Avantajları Neler?</li>-->
<!--            </ul>-->
<!--          </div>-->
          <div class="col-xs-12 col-md-9">
            <details v-for="(faq, index) in faqs" v-bind:key="index">
              <summary>{{faq.title}}</summary>
              <p>
                {{faq.description}}
              </p>
            </details>
          </div>
        </div>
      </div>
      <div class="one rellax" data-rellax-speed="2">
        <img src="@/assets/images/parallax-0.png" alt="">
      </div>
      <div class="two rellax" data-rellax-speed="3">
        <img src="@/assets/images/parallax-1.png" alt="">
      </div>
      <div class="three rellax" data-rellax-speed="5">
        <img src="@/assets/images/parallax-2.png" alt="">
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import FaqService from "@/services/content/faq-service";

export default {
  name: "Faq",
  components: { BackgroundMovement, Footer, HeaderNav },
  data() {
    return {
      lang: "tr",
      faqs: {}
    };
  },
  async created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
    await this.getAllRecords();
  },
  methods: {
    async getAllRecords() {
      const data = FaqService.getAllRecords(this.lang);
      await data.then((res) => {
        if (res.status === 200) {
          this.faqs = res.data.content;
        } else {
          this.$router.push("/404");
        }
      });
    }
  }
};
</script>

<style scoped>
section.faq-tabs details summary:after {
  content: "+";
  color: #000;
  margin: auto 10px; }
section.faq-tabs details[open] summary:after {
    content: "-"; }
</style>
