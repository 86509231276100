<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="corporate-identity hero">
      <div class="container no-gutter-xs">
        <div class="row center-xs">
          <div class="col-xs-12 col-md-9">
            <div class="section-header">{{ $t('corporate_identity.maintitle') }}</div>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 col-md-9">
            <div class="commercial-title">
              <div class="section-title">{{ $t('corporate_identity.title') }}</div>
              <div class="section-body">
                <table>
                  <tr>
                    <td>{{ $t('corporate_identity.registration_number') }}</td>
                    <td>81033/5</td>
                  </tr>
                  <tr>
                    <td>{{ $t('corporate_identity.trade_name') }}</td>
                    <td>Kule Verici Tesisleri İşletim ve Teknolojileri Anonim Şirketi</td>
                  </tr>
                  <tr>
                    <td>{{ $t('corporate_identity.center_of_enterprice') }}</td>
                    <td>İstanbul</td>
                  </tr>
                  <tr>
                    <td>{{ $t('corporate_identity.tax_office') }}</td>
                    <td>Üsküdar</td>
                  </tr>
                  <tr>
                    <td>{{ $t('corporate_identity.tax_no') }}</td>
                    <td>591 040 4831</td>
                  </tr>
                  <tr>
                    <td>{{ $t('corporate_identity.date_of_registration_of_the_compnay') }}</td>
                    <td>20/04/2017</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";

export default {
  name: "CorporateIdentity",
  components: {
    BackgroundMovement,
    Footer,
    HeaderNav
  }
};
</script>
