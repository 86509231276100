export default {
  data() {
    return {
      localMonthNameMapping: {
        0: this.$t("generic.jan"),
        1: this.$t("generic.feb"),
        2: this.$t("generic.mar"),
        3: this.$t("generic.apr"),
        4: this.$t("generic.may"),
        5: this.$t("generic.jun"),
        6: this.$t("generic.jul"),
        7: this.$t("generic.aug"),
        8: this.$t("generic.sep"),
        9: this.$t("generic.oct"),
        10: this.$t("generic.nov"),
        11: this.$t("generic.dec")
      }
    };
  },
  methods: {
    getFormattedDate(d) {
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
    getDateByGiven(d) {
      return `${d.split("/")[2]}-${d.split("/")[1]}-${d.split("/")[0]}`;
    },
    getCurrentDate() {
      const now = new Date();

      return {
        current_month: now.getMonth(),
        current_year: now.getFullYear(),
        current_day: now.getDay(),
        current_date: now.getDate(),
        selected_date: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
        today: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
      };
    },
    getDaysInMonth(month, year) {
      const date = new Date(year, month, 1);
      const days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    getCalendarObject(month, year) {
      let week = 0;
      const calendarObject = {};
      const days = this.getDaysInMonth(month, year);
      const firstDateOfMonth = days[0];
      const firstDayOfMonth = firstDateOfMonth.getDay();
      let counter = 0;

      days.forEach((date, index) => {
        if ((firstDayOfMonth + index) % 7 === 1) {
          week += 1;
        }

        counter += 1;
        let value = date;

        if (index < 7 && week === 0 && counter < 2) {
          value = "";
          const forLimit = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

          for (let i = 0; i < forLimit; i += 1) {
            if (typeof calendarObject[week] !== "undefined") {
              calendarObject[week].push(value);
            } else {
              calendarObject[week] = [value];
            }
          }

          value = date;
        }

        if (typeof calendarObject[week] !== "undefined") {
          calendarObject[week].push(value);
        } else {
          calendarObject[week] = [value];
        }
      });

      return calendarObject;
    }
  }
};
