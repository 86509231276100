<template>
  <div>
    <BannerPopup
      v-for="(popup, index) in popupData"
      :key="index"
      :is-dynamic="true"
      v-show="!closedPopups.includes(popup.uuid) && isPopupActive(popup)"
      :image="popup.image"
      @popup-closed="onPopupClosed(popup)"
    >
    </BannerPopup>
    <HeaderNav></HeaderNav>
    <HeadlineHero></HeadlineHero>
    <Slider v-if="sliderData.length > 0" :sliderData="sliderData"></Slider>
    <Featured></Featured>
    <Gallery :galleryData="galleryData"></Gallery>
    <Tabs></Tabs>
    <ContactBar></ContactBar>
    <BuyTicketComponent></BuyTicketComponent>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import HeadlineHero from "@/components/homepage/HeadlineHero.vue";
import Footer from "@/components/main/Footer.vue";
import Featured from "@/components/homepage/Featured.vue";
import Gallery from "@/components/homepage/Gallery.vue";
import Tabs from "@/components/homepage/Tabs.vue";
import ContactBar from "@/components/main/ContactBar.vue";
import BuyTicketComponent from "@/components/main/BuyTicketComponent.vue";
import BannerPopup from "@/components/homepage/BannerPopup.vue";
import Slider from "@/components/homepage/Slider.vue";
import DynamicContentService from "@/services/content/dynamic-content-service";
import GalleryService from "@/services/content/gallery-service";

export default {
  name: "Home",

  components: {
    Slider,
    BannerPopup,
    BuyTicketComponent,
    ContactBar,
    Tabs,
    Gallery,
    Featured,
    HeadlineHero,
    HeaderNav,
    Footer
  },
  data() {
    return {
      lang: "tr",
      closedPopups: [],
      popupData: [],
      sliderData: [],
      sliderRequest: {
        lang: "tr",
        slider: []
      },
      galleryData: [],
      galleryRequest: {
        lang: "tr",
        gallery: []
      }
    };
  },
  async created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }

    await this.getSliderContent();
    await this.getPopupContent();
    await this.getGalleryContent();
  },
  methods: {
    isPopupActive(data) {
      if (typeof data.alwaysOnDisplay === "undefined" || !data.alwaysOnDisplay) {
        return localStorage.getItem(`popup_${data.uuid}`) === null;
      }
      localStorage.removeItem(`popup_${data.uuid}`);
      return true;
    },
    onPopupClosed(data) {
      if (typeof data.alwaysOnDisplay === "undefined" || !data.alwaysOnDisplay) {
        localStorage.setItem(`popup_${data.uuid}`, JSON.stringify(true));
      }

      this.closedPopups.push(data.uuid);
    },
    async getSliderContent() {
      this.sliderRequest.lang = this.lang;
      const data = DynamicContentService.getSlider(this.sliderRequest);

      data.then((res) => {
        if (res.status === 200) {
          this.sliderData = res.data.content;
        }
      });
    },
    async getPopupContent() {
      const data = DynamicContentService.getPopup({
        lang: this.lang,
        popup: []
      });

      data.then((res) => {
        if (res.status === 200) {
          this.popupData = res.data.content;
        }
      });
    },
    async getGalleryContent() {
      this.galleryRequest.lang = this.lang;
      const data = GalleryService.getGallery(this.galleryRequest);

      data.then((res) => {
        if (res.status === 200) {
          this.galleryData = res.data.content;
        }
      });
    }
  }
};
</script>
