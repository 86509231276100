<template>
  <section class="homepage-tabs">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-12 col-lg-offset-1 col-lg-9">
          <div class="tab-container">
            <input type="radio" id="r1" name="r" checked />
            <!--input type="radio" id='r2' name="r"-->
            <input type="radio" id="r2" name="r" />
            <!--input type="radio" id='r4' name="r">
            <input type="radio" id='r5' name="r"-->
            <input type="radio" id="r3" name="r" />
            <div class="tab-nav">
              <ul>
                <li>
                  <label for="r1">
                    <img src="@/assets/images/icons/announcement-green.svg" alt="" />
                    {{ $t("homepage.announcement_title") }}
                  </label>
                </li>
                <!--li>
                  <label for='r2'>
                    <img src="@/assets/images/icons/fork-green.svg" alt="">Tesisler
                  </label>
                </li-->
                <li>
                  <label for="r2">
                    <img src="@/assets/images/icons/accessibility-green.svg" alt="" />
                    {{ $t("homepage.accessibility_title") }}
                  </label>
                </li>
                <!--li>
                  <label for='r4'>
                    <img src="@/assets/images/icons/shop-green.svg" alt="">Mağazalar
                  </label>
                </li>
                <li>
                  <label for='r5'>
                    <img src="@/assets/images/icons/bus-green.svg" alt="">Okul Ziyareti
                  </label>
                </li-->
                <li>
                  <label for="r3">
                    <img src="@/assets/images/icons/faq-green.svg" alt="" />
                    {{ $t("homepage.faq_title") }}
                  </label>
                </li>
              </ul>
            </div>
            <div class="tab-content">
              <div class="article ">
                <div
                  class="horizontal-card"
                  v-for="(announcement, index) in announcements"
                  v-bind:key="index"
                >
                  <div class="card-thumbnail">
                    <img :src="appContentStorageUrl + announcement.image" alt="" />
                  </div>
                  <div class="card-content">
                    <div class="box-header">
                      {{ announcement.title }}
                    </div>
                    <div
                      class="card-description"
                      v-html="'<p>' + announcement.spot.substring(0, 360) + '...</p>'"
                    ></div>
                    <!--<a href="#" class="show-more">
                      Detayları Gör <img src="@/assets/images/icons/right-arrow-green.svg" alt="">
                    </a>-->
                  </div>
                </div>
                <div @click="$router.push('/duyurular')" class="button black filled">
                  {{ $t("homepage.see_all_announcement") }}
                </div>
              </div>
              <!-- <div class="article">
                <p>
                  ...
                </p>
              </div> -->
              <div v-html="$t('homepage.accessibility_desc')" class="article"></div>
              <!-- <div class="article">
                <p>
                  ...
                </p>
              </div>
              <div class="article">
                <p>
                  ...
                </p>
              </div>
              -->
              <div class="article">
                <details v-for="(faq, index) in faqs" v-bind:key="index">
                  <summary>{{ faq.title }}</summary>
                  <p>
                    {{ faq.description }}
                  </p>
                </details>
                <div @click="$router.push('/sss')" class="button black filled">
                  {{ $t("homepage.see_all_questions") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="seperator">
      <img src="@/assets/images/white-clouds-3.png" alt="" />
    </div>
  </section>
</template>

<script>
import FaqService from "@/services/content/faq-service";
import AnnouncementService from "@/services/content/announcement-service";

export default {
  name: "Tabs",
  data() {
    return {
      lang: "tr",
      announcements: {},
      faqs: {},
      appContentStorageUrl: process.env.VUE_APP_CONTENT_STORAGE_URL
    };
  },
  async created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
    await this.getAnnouncements();
    await this.getFaqs();
  },
  methods: {
    async getAnnouncements() {
      const data = AnnouncementService.getLast2Records(this.lang);
      await data.then((res) => {
        if (res.status === 200) {
          this.announcements = res.data.content;
        } else {
          this.$router.push("/404");
        }
      });
    },
    async getFaqs() {
      const data = FaqService.getLast5Records(this.lang);
      await data.then((res) => {
        if (res.status === 200) {
          this.faqs = res.data.content;
        } else {
          this.$router.push("/404");
        }
      });
    }
  }
};
</script>

<style scoped></style>
