import HttpService from "@/services/http-service";

const otpService = {
  sendSmsConfirmation(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/notification/otp/confirmation`, payload);
  },
  verifyPhoneNumber(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/notification/otp/verification`, payload);
  },
  shareTicket(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/notification/share/ticket/sms`, payload);
  },
  shareReservation(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/notification/share/reservation/sms`, payload);
  }
};

export default otpService;
