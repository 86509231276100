<template>
  <div class="bg-white tab-additional-services">
    <vue-element-loading
      :active="isDataLoading"
      background-color="rgba(255, 255, 255, 1)"
      color="#00baab"
      spinner="spinner"
    />
    <div class="row">
      <div class="col-xs-12">
        <div class="box-header">{{ $t("reservation.extra_service") }}</div>
        <div class="section-description">
          <p>{{ $t("reservation.extra_service_desc") }}</p>
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in stepData.additional_ticket_types"
      v-bind:key="index"
      class="row between-xs additional-service"
    >
      <div class="col-xs-12 box-mini-header">
        {{ index + 1 }}. ({{ reservationData.VisitorInfo.ticket_details[index].name }})
        {{ $t("reservation.extra_service_ticket") }}
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div v-for="(service, serviceIndex) in filteredAdditionalServiceList(index)"
          v-bind:key="serviceIndex">
            <div v-if="selectedServices[index].length <= 1
                        || selectedServices[index].includes(service.id.toString())"
                 class="col-lg-12 material-radio-group">
              <input
                type="checkbox"
                :id="`ticket-type-${index}-${serviceIndex}`"
                :name="`ticket-type-${index}-${serviceIndex}`"
                v-model="stepData.selected_additional_list[index].selected_list"
                :value="service.id"
              />
              <label class="add-label" :for="`ticket-type-${index}-${serviceIndex}`">
                <b>{{ service.name }} </b>{{ currencyFormat(service.sale_price) }} TL
                <span class="discount" v-if="service.discount_rate > 0">
                  {{ currencyFormat(service.discount_amount) }} TL
                  {{ $t("reservation.discount") }}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from "@/services/reservation/ticket-service";

export default {
  name: "AdditionalService",
  props: ["reservationData", "validateStepData", "calculateObject", "previousStep", "activeStep"],
  data() {
    return {
      token: this.$session.get("restoken"),
      isDataLoading: false,
      additionalServiceList: {},
      selectedServices: {},
      stepData: {
        additional_ticket_types: [],
        summary: {},
        selected_additional_list: []
      }
    };
  },
  async created() {
    await this.getAdditionalServices();
    this.prepareStepData();

    this.checkStepStatus();
  },
  watch: {
    "stepData.selected_additional_list": {
      deep: true,
      handler() {
        this.fillStepData();
        this.$emit("service-selected", this.stepData, 3);
      }
    },
    validateStepData: {
      handler(newVal) {
        if (newVal) {
          this.validateStep();
        }
      }
    }
  },
  methods: {
    filteredAdditionalServiceList(index) {
      const filteredList = this.additionalServiceList.filter(
        (item) => item.ticket_type_ids
          .includes(this.reservationData.VisitorInfo.ticket_details[index].id)
      );
      const selectedTime = this.reservationData.DateTimeSelection.selected_time;
      return filteredList
        .filter((value) => !(selectedTime > 19
                              && value.name.startsWith("Hedef")));
    },
    async validateStep() {
      this.fillStepData();

      const updateBlockedResult = await this.$store.dispatch("updateBlockedReservation", {
        token: this.token,
        tickets: this.calculateObject.tickets,
        step: 2
      });

      if (!updateBlockedResult) {
        this.$emit("validation-failed");
        return;
      }

      this.$emit("step-completed", this.stepData, 3);
    },
    async getAdditionalServices() {
      this.isDataLoading = true;

      const data = TicketService.getAdditionalServices({});

      await data.then((res) => {
        if (res.status === 200) {
          this.additionalServiceList = res.data;
        }

        this.isDataLoading = false;
      });
    },
    fillStepData() {
      this.stepData.summary = {};

      this.stepData.selected_additional_list.forEach((item, index) => {
        this.stepData.additional_ticket_types[index].selected_list = [];

        item.selected_list.forEach((service) => {
          const additionalServiceObject = this.additionalServiceList
            .find((obj) => obj.id === service);
          this.stepData.additional_ticket_types[index].selected_list.push(
            additionalServiceObject
          );

          if (
            typeof this.stepData.summary[additionalServiceObject.id] === "undefined"
          ) {
            this.stepData.summary[additionalServiceObject.id] = {
              name: additionalServiceObject.name,
              total_price: additionalServiceObject.sale_price,
              total_count: 1
            };
          } else {
            this.stepData.summary[additionalServiceObject.id].total_count += 1;
            this.stepData.summary[
              additionalServiceObject.id
            ].total_price += additionalServiceObject.sale_price;
          }
        });
      });
    },
    prepareStepData() {
      for (let i = 0; i < this.reservationData.DateTimeSelection.ticket_count; i += 1) {
        this.stepData.additional_ticket_types.push({ selected_list: [] });
        this.stepData.selected_additional_list.push({ selected_list: [] });

        if (
          typeof this.reservationData.AdditionalService !== "undefined"
          && this.reservationData.AdditionalService.selected_additional_list.length
            === this.reservationData.DateTimeSelection.ticket_count
        ) {
          this.stepData.selected_additional_list[
            i
          ] = this.reservationData.AdditionalService.selected_additional_list[i];
        }
      }

      this.selectedServices = this.normalizeObject(
        this.reservationData.VisitorInfo.selected_additional_services
      );

      Object.keys(this.selectedServices).forEach((index) => {
        this.selectedServices[index] = this.selectedServices[index].join().split(",");
      });
    },
    checkStepStatus() {
      // Prepare default variables.
      const result = [];
      const selectedAdditionalServiceGroups = [];
      const isFromNextStep = this.previousStep > this.activeStep;
      let passStep = true;

      // Loop ticket types.
      this.stepData.additional_ticket_types.forEach((ticketType, index) => {
        // Prepare ticket type loop variables.
        result[index] = [];
        selectedAdditionalServiceGroups[index] = [];
        const indexFilteredAddtionalServices = this.filteredAdditionalServiceList(index);
        const selectedAdditionalServices = this.reservationData
          .VisitorInfo.selected_additional_services[index];

        // Precondition selected services.
        if (Array.isArray(this.selectedServices[index])
            && this.selectedServices[index][0] === "") {
          passStep = false;
          return;
        }

        // Initialize selected additional services.
        selectedAdditionalServices.forEach((value1, index1) => {
          selectedAdditionalServiceGroups[index][index1] = { value: value1, count: 0 };
        });

        // Count group by selected additional services.
        indexFilteredAddtionalServices
          .forEach((value1) => {
            const additionalServiceId = value1.id;
            selectedAdditionalServiceGroups[index]
              .forEach((value2) => {
                const selectedAdditionalServiceCount = value2;
                if (selectedAdditionalServiceCount.value.includes(additionalServiceId)) {
                  selectedAdditionalServiceCount.count += 1;
                }
              });
          });

        // Conditional push additional service to result array for check operation.
        indexFilteredAddtionalServices
          .forEach((service) => {
            const serviceId = service.id;
            const isSingle = selectedAdditionalServiceGroups[index]
              .filter((value) => value.value.includes(serviceId) && value.count === 1)
              .length === 1;
            if (isSingle) {
              result[index].push(serviceId);
            }
          });

        // Conditional set pass step.
        if (passStep === true
              && result[index].length !== selectedAdditionalServiceGroups[index].length) {
          passStep = false;
        }
      });

      // Check additional service checkbox.
      result.forEach((item1, index1) => {
        if (!isFromNextStep) {
          item1.forEach((item2) => {
            this.stepData.selected_additional_list[index1].selected_list.push(item2);
          });
        }
      });

      // Check pass step.
      if (passStep) {
        if (isFromNextStep) {
          this.$emit("step-backward");
        } else {
          this.validateStep();
        }
      }
    }
  }
};
</script>

<style scoped>
.discount {
  display: block;
  color: #00ba59;
  font: 400 12px/1.38 "Poppins", sans-serif;
  margin-top: 4px;
}
.add-label {
  line-height: 30px;
}
</style>
