<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="account hero">
      <div class="container no-gutter-xs">
        <SharePopup
          v-show="showSharePopup"
          @close-popup="closeSharePopup"
          v-bind:shareType="shareType"
          v-bind:uuid="shareUuid"
        ></SharePopup>
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header MYACCOUNT">{{ $t("header.my_account") }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-3 no-gutter-xs">
            <ul class="sidebar-menu">
              <span>Menü</span>
              <li @click="selectTab('tab-1')" :class="{ active: activeTab === 'tab-1' }">
                {{ $t("register.register_info") }}
              </li>
              <li @click="selectTab('tab-2')" :class="{ active: activeTab === 'tab-2' }">
                {{ $t("account.tickets") }}
              </li>
            </ul>
          </div>
          <div v-show="activeTab === 'tab-1'" class="col-xs-12 col-md-9 no-gutter-xs">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <form data-vv-scope="account">
                  <MessageBar></MessageBar>
                  <div class="bg-white">
                    <div class="box-header">{{ $t("register.register_info") }}</div>
                    <div class="material-form-group">
                      <input
                        v-model="accountDetail.firstName"
                        name="İsim"
                        v-validate="{ required: true }"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("register.name") }}</label>
                      <span v-show="errors.has('account.İsim')" class="error-message">
                        {{ errors.first("account.İsim") }}
                      </span>
                    </div>
                    <div class="material-form-group">
                      <input
                        v-model="accountDetail.lastName"
                        v-validate="{ required: true }"
                        name="Soyisim"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("register.surname") }}</label>
                      <span v-show="errors.has('account.Soyisim')" class="error-message">
                        {{ errors.first("account.Soyisim") }}
                      </span>
                    </div>
                    <div class="row">
                      <div class="col-xs-5">
                        <div class="material-form-group">
                          <select
                            name="Ülke Kodu"
                            id="phoneCountryCode"
                            v-validate="{ required: true }"
                            :class="{
                              invalid: errors.has('account.Ülke Kodu'),
                              valid:
                                accountDetail.phoneCountryCode.length > 0 &&
                                !errors.has('account.Ülke Kodu'),
                              required: true
                            }"
                            v-model="accountDetail.phoneCountryCode"
                          >
                            <option
                              v-for="(item, index) in phoneCountryCodeList"
                              v-bind:key="index"
                              :value="item.dial_code.substring(1)"
                            >
                              {{ item.name }} ({{ item.dial_code }})
                            </option>
                          </select>
                          <label for="phoneCountryCode">{{ $t("register.country_code") }}</label>
                          <span v-show="errors.has('account.Ülke Kodu')" class="error-message">
                            {{ errors.first("account.Ülke Kodu") }}
                          </span>
                        </div>
                      </div>
                      <div class="col-xs-7">
                        <div class="material-form-group">
                          <input
                            v-model="accountDetail.phoneNumber"
                            name="Telefon"
                            :class="{
                              invalid: errors.has('account.Telefon'),
                              valid:
                                accountDetail.phoneNumber.length > 0 &&
                                !errors.has('account.Telefon'),
                              required: true
                            }"
                            type="tel"
                            v-mask="accountDetail.phoneCountryCode === '90' ? '##########' : ''"
                            v-validate="{ required: true, regex: /^[0-9]*$/ }"
                            placeholder=" "
                          />
                          <label>{{ $t("footer.phone") }}</label>
                          <span v-show="errors.has('account.Telefon')" class="error-message">
                            {{ errors.first("account.Telefon") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="material-form-group">
                      <input
                        v-model="accountDetail.emailAddress"
                        name="E-posta"
                        v-validate="{ required: true, email: true }"
                        disabled
                        type="email"
                        placeholder=" "
                      />
                      <label>{{ $t("register.email") }}</label>
                      <span v-show="errors.has('account.E-posta')" class="error-message">
                        {{ errors.first("account.E-posta") }}
                      </span>
                    </div>
                    <div class="gdpr-description">
                      <p>
                        <a
                          target="_blank"
                          href="/kisisel-verilerin-korunmasi-ve-isletilmesi-politikasi"
                        >
                          {{ $t("account.kvkk_name") }}
                        </a>
                        {{ $t("account.kvkk_content") }}
                      </p>
                      <div class="gdpr-approve">
                        <div class="material-radio-group">
                          <input
                            type="checkbox"
                            v-model="accountDetail.smsNotification"
                            id="sms-approve"
                            name="Mesafeli Bilet Satış Sözleşmesi"
                          />
                          <label for="sms-approve">
                            {{ $t("register.sms_notification") }}
                          </label>
                        </div>
                        <div class="material-radio-group">
                          <input
                            type="checkbox"
                            v-model="accountDetail.emailNotification"
                            id="email-approve"
                            name="Mesafeli Bilet Satış Sözleşmesi"
                          />
                          <label for="email-approve">
                            {{ $t("register.mail_notification") }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div @click="updateInfo" class="button filled green b">
                      <vue-element-loading
                        :active="isLoading"
                        background-color="rgba(255, 255, 255, 0.8)"
                        color="#00baab"
                        spinner="spinner"
                      />
                      {{ $t("account.save") }}
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-xs-12 col-md-6">
                <div class="bg-white">
                  <div class="box-header">{{ $t("account.my_password") }}</div>
                  <div class="section-description">
                    <p>{{ $t("account.password_change_desc") }}</p>
                  </div>
                  <div @click="$router.push('hesabim/sifre-degistir')" class="button black filled">
                    {{ $t("account.change_password") }}
                  </div>
                </div>
                <div class="bg-white">
                  <div class="box-header">{{ $t("account.logout_title") }}</div>
                  <div class="section-description">
                    <p>{{ $t("account.logout_desc") }}</p>
                  </div>
                  <div @click="customerLoggedOut" class="button solid mid g">
                    {{ $t("account.logout") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeTab === 'tab-2'" class="col-xs-12 col-md-9 no-gutter-xs">
            <MessageBar></MessageBar>
            <div v-if="ticketLoader" class="bg-white tab-approved">
              <vue-element-loading
                :active="ticketLoader"
                background-color="rgba(255, 255, 255, 0.8)"
                color="#00baab"
                spinner="spinner"
              />
            </div>
            <div v-if="reservationList.length <= 0 && !ticketLoader" class="bg-white tab-approved">
              <p class="summary-text">{{ $t("account.no_ticket_found") }}</p>
            </div>
            <div v-if="reservationList.length > 0 && !ticketLoader" class="bg-white tab-approved">
              <div class="row between-xs summary-header">
                <div class="col-xs-12 col-md-3 center-xs start-md">
                  <div class="box-mini-header">{{ $t("account.payment_info") }}</div>
                </div>
                <div class="col-xs-12 col-md-4 center-xs">
                  <div class="box-mini-header">{{ $t("account.reservation_info") }}</div>
                </div>
                <div class="col-xs-12 col-md-3 center-xs end-md">
                  <div class="box-mini-header">{{ $t("account.amount") }}</div>
                </div>
              </div>
              <div
                v-for="(item, index) in reservationList"
                v-bind:key="index"
                class="row between-xs middle-xs summary-content"
              >
                <div class="col-xs-12">
                  <details>
                    <summary>
                      <div class="row between-xs middle-xs">
                        <div class="col-xs-12 col-md-3 center-xs start-md">
                          <div class="section-description">
                            {{ $t("account.reservation_no") }}
                          </div>
                          <div class="section-silent">{{ item.shortNumber }}</div>
                        </div>
                        <div class="col-xs-12 col-md-4 center-xs">
                          <div class="section-description">
                            {{ item.tickets[0].estimatedVisitDate }} -
                            {{ item.tickets[0].estimatedVisitTime }}
                          </div>
                          <div class="section-silent">
                            {{ item.tickets.length }} {{ $t("account.number_of_ticket") }}
                          </div>
                        </div>
                        <div class="col-xs-12 col-md-3 center-xs end-md v-hr">
                          <div class="total">{{ currencyFormat(item.totalSalePrice) }} TL</div>
                          <div class="section-silent">
                            {{ $t("account.credit_card") }}
                          </div>
                        </div>
                      </div>
                    </summary>
                    <hr />

                    <div
                      class="row summary-items summary-items--type1 between-xs"
                      v-bind:key="index2"
                      v-for="(ticket, index2) in item.tickets"
                    >
                      <div class="col-xs-12 col-lg-4 center-xs start-lg">
                        <div class="summary-text">{{ ticket.type.name }} Bilet</div>
                        <div
                          class="summary-silent-text"
                          v-for="(additionalData, index3) in ticket.additionalServices"
                          v-bind:key="index3"
                        >
                          {{ additionalData.name }}
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-5 center-xs start-lg">
                        <a :href="`/b/${ticket.shortNumber}`" class="summary-action">
                          {{ $t("account.see_ticket") }}
                        </a>
                        <div @click="shareTicket(ticket.uuid)" class="summary-action">
                          {{ $t("account.share") }}
                        </div>
                        <div
                          @click="giveSurvey(ticket.uuid)"
                          v-if="ticket.status.code === 'USED'"
                          class="summary-action"
                        >
                          {{ $t("account.make_survey") }}
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-3 center-xs end-lg v-hr">
                        <div class="summary-text">
                          {{ currencyFormat(ticket.totalSalePrice) }} TL
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12">
                        <hr />
                      </div>
                    </div>
                    <div class="row summary-actions between-xs center-md">
                      <div class="col-xs-11 col-md-5">
                        <div
                          class="button solid mid g"
                          @click="$router.push(`/r/${item.shortNumber}`)"
                        >
                          {{ $t("account.see_all_tickets") }}
                        </div>
                      </div>
                      <div class="col-xs-11 col-md-5">
                        <div @click="shareReservation(item.uuid)" class="button filled green b">
                          {{ $t("account.share_all_tickets") }}
                        </div>
                      </div>
                    </div>
                    <section v-if="surveyTicket.length > 0" class="popup">
                      <div class="popup-body">
                        <div @click="closeSurveyPopup" class="popup-closer"></div>
                        <div class="voting-actions">
                          <div class="box-mini-header">
                            {{ $t("account.like_visit_title") }}
                          </div>
                          <div class="section-description">
                            {{ $t("account.like_visit_desc") }}
                          </div>
                          <div class="voting-points">
                            <div class="voting-numbers">
                              <a href="#1" @click="submitSurvey(`${surveyTicket}#1`)"
                                ><img src="@/assets/images/icons/voting/1.png" alt=""
                              /></a>
                              <a href="#2" @click="submitSurvey(`${surveyTicket}#2`)"
                                ><img src="@/assets/images/icons/voting/2.png" alt=""
                              /></a>
                              <a href="#3" @click="submitSurvey(`${surveyTicket}#3`)"
                                ><img src="@/assets/images/icons/voting/3.png" alt=""
                              /></a>
                              <a href="#4" @click="submitSurvey(`${surveyTicket}#4`)"
                                ><img src="@/assets/images/icons/voting/4.png" alt=""
                              /></a>
                              <a href="#5" @click="submitSurvey(`${surveyTicket}#5`)"
                                ><img src="@/assets/images/icons/voting/5.png" alt=""
                              /></a>
                            </div>
                            <div class="point-descriptions">
                              <p>{{ $t("account.dont_like") }}</p>
                              <p>{{ $t("account.like") }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <!--    <section class="popup share">-->
    <!--      <div class="popup-header">-->
    <!--        <div class="header-icon">-->
    <!--          <img src="@/assets/images/icons/circle-x-white.svg" alt="">-->
    <!--        </div>-->
    <!--        <div class="approve-text">-->
    <!--          Paylaş-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="popup-body">-->

    <!--        <ul class="platforms">-->
    <!--          <li @click="socialModal('twitter')">-->
    <!--            <img src="@/assets/images/share/twitter.svg" alt="" />-->
    <!--            Twitter-->
    <!--          </li>-->
    <!--          <li @click="socialModal('facebook')">-->
    <!--            <img src="@/assets/images/share/facebook.svg" alt="" />Facebook-->
    <!--          </li>-->
    <!--          <li @click="socialModal('whatsapp')">-->
    <!--            <img src="@/assets/images/share/whatsapp.svg" alt="" />Whatsapp-->
    <!--          </li>-->
    <!--          <li @click="socialModal('instagram')">-->
    <!--            <img src="@/assets/images/share/instagram.svg" alt="" />Instagram-->
    <!--          </li>-->
    <!--        </ul>-->

    <!--        <div class="url-container" @click="copy(shareUrl)">-->
    <!--          <input-->
    <!--            ref="input"-->
    <!--            class="url"-->
    <!--            type="text"-->
    <!--            :value="(shareUrl)"-->
    <!--            @click="select"-->
    <!--          />-->
    <!--          <div class="button filled green w" @click="select">-->
    <!--            {{ copyText }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import { mapActions } from "vuex";
import CustomerService from "@/services/customer/customer-service";
import TicketService from "@/services/reservation/ticket-service";
import MessageBar from "@/components/main/MessageBar.vue";
import SharePopup from "@/components/main/SharePopup.vue";

const countryPhoneList = require("@/data/countryPhoneList.json");

export default {
  name: "Account",
  beforeCreate() {
    if (typeof this.$session.get("cusdet") === "undefined") {
      this.$router.push("/");
    }
  },
  async created() {
    await this.getUserInformation();
    this.getReservations();
  },
  components: {
    SharePopup,
    MessageBar,
    BackgroundMovement,
    Footer,
    HeaderNav
  },
  data() {
    return {
      activeTab: "tab-1",
      copyText: "KOPYALA",
      phoneCountryCodeList: countryPhoneList,
      isLoading: false,
      ticketLoader: false,
      showSharePopup: false,
      surveyTicket: "",
      shareType: "",
      shareUuid: "",
      accountDetail: this.$session.get("cusdet"),
      reservationList: [],
      shareUrl: "https://www.camlicakule.istanbul"
    };
  },
  methods: {
    select() {
      this.$refs.input.select();
    },
    async copy(url) {
      await navigator.clipboard.writeText(url);
      this.copyText = "KOPYALANDI";
      setTimeout(() => {
        this.copyText = "KOPYALA";
      }, 1000);
    },
    ...mapActions(["customerLoggedOut", "showMessageBar"]),
    selectTab(name) {
      this.activeTab = name;
    },
    giveSurvey(uuid) {
      this.surveyTicket = uuid;
    },
    closeSurveyPopup() {
      this.surveyTicket = "";
    },
    closeSharePopup() {
      this.showSharePopup = false;
      this.shareType = "";
      this.shareUuid = "";
    },
    submitSurvey(val) {
      const votingData = val.split("#");

      const requestData = {
        uuid: votingData[0],
        option: votingData[1]
      };

      const data = TicketService.submitTicketSurvey(requestData);

      data.then((res) => {
        if (res.status === 200) {
          this.showMessageBar({
            message: this.$t("generic.successful"),
            type: "success"
          });

          this.surveyTicket = "";
        } else {
          this.showMessageBar({
            message: res.message,
            type: "error"
          });
        }

        this.isLoading = false;
      });
    },
    updateInfo() {
      this.isLoading = true;
      const updateData = this.normalizeObject(this.accountDetail);
      updateData.phoneNumber = `${updateData.phoneCountryCode} ${updateData.phoneNumber.replace(
        /[^A-Z0-9]/gi,
        ""
      )}`;

      this.$validator.validateAll("account").then((result) => {
        if (result) {
          const data = CustomerService.updateInformation(updateData);

          data.then((res) => {
            if (res.status === 200) {
              this.$session.set("cusdet", this.decorateCustomerDetail(res.data));

              this.showMessageBar({
                message: this.$t("generic.successful"),
                type: "success"
              });
            } else {
              this.showMessageBar({
                message: `${this.$t("generic.unexpected_error")}(${res.message})`,
                type: "error"
              });
            }

            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
        }
      });
    },
    shareTicket(uuid) {
      this.shareUuid = uuid;
      this.shareType = "ticket";
      this.showSharePopup = true;
    },
    shareReservation(uuid) {
      this.shareUuid = uuid;
      this.shareType = "reservation";
      this.showSharePopup = true;
    },
    async getUserInformation() {
      const data = CustomerService.getInformation();

      await data.then((res) => {
        if (res.status === 200) {
          this.$session.set("cusdet", this.decorateCustomerDetail(res.data));
        } else {
          this.customerLoggedOut();
        }
      });
    },
    getReservations() {
      this.ticketLoader = true;
      const data = CustomerService.getReservations({});

      data.then((res) => {
        if (res.status === 200) {
          this.reservationList = res.data;
        }

        this.ticketLoader = false;
      });
    }
  }
};
</script>

<style scoped>
.popup-body {
  position: relative;
}
</style>
