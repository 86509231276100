<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="account hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('account.mail_verification_title') }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <MessageBar></MessageBar>
          </div>
          <div class="col-xs-12 col-md-12 no-gutter-xs">
            <div class="row">
              <div class="col-xs-12 col-md-12 margin-auto">
                <div class="bg-white">
                  <vue-element-loading :active="isLoading"
                                       background-color="rgba(255, 255, 255, 0.8)"
                                       color="#00baab" spinner="spinner"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import CustomerService from "@/services/customer/customer-service";
import { mapActions } from "vuex";
import MessageBar from "@/components/main/MessageBar.vue";

export default {
  name: "EmailVerification",
  components: { MessageBar, Footer, HeaderNav },
  data() {
    return {
      isLoading: false,
      uuid: this.$route.params.uuid,
      token: this.$route.params.token
    };
  },
  created() {
    if (
      typeof this.uuid !== "undefined"
      && this.uuid.length > 0
      && typeof this.token !== "undefined"
      && this.token.length > 0
    ) {
      this.verifyEmail();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions([
      "showMessageBar"
    ]),
    verifyEmail() {
      this.isLoading = true;
      const data = CustomerService.verifyEmail(this.uuid, this.token);
      data.then((res) => {
        if (res.status === 200) {
          this.showMessageBar({
            message: this.$t("generic.successful"),
            type: "success"
          });
          this.$router.push("/giris-yap");
        } else {
          this.showMessageBar({
            message: `${this.$t("generic.unexpected_error")}(${res.message})`,
            type: "error"
          });
        }

        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped>

</style>
