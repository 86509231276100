<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-xs-6 col-sm">
          <ul>
            {{
              $t("footer.site_map")
            }}
            <li>
              <a href="/">{{ $t("header.homepage") }}</a>
            </li>
            <li>
              <a href="/kule-hakkinda">{{ $t("header.about_us") }}</a>
            </li>
            <li>
              <a href="/bilet-al">{{ $t("header.reservation") }}</a>
            </li>
            <!--<li><a href="#">Tesisler ve Olanaklar</a></li>-->
            <!--<li><a href="#">Güvenlik Yönergeleri</a></li>-->
            <!--<li><a href="#">Etkinlikler</a></li>-->
            <li>
              <a href="/sss">{{ $t("header.faq") }}</a>
            </li>
            <li>
              <a href="/kisisel-verilerin-korunmasi-ve-isletilmesi-politikasi">
                {{ $t("footer.personal_data_protection") }}
              </a>
            </li>
            <li>
              <a href="/mesafeli-bilet-satis-sozlesmesi">
                {{ $t("footer.ticket_sale_agreement") }}
              </a>
            </li>
            <li>
              <a href="/site-kullanim-kosullari">
                {{ $t("footer.site_usage_condition") }}
              </a>
            </li>
            <li>
              <a href="/kurumsal-kimlik">{{ $t("footer.corporate_identity") }}</a>
            </li>
            <li>
              <a href="/iletisim">{{ $t("header.contact") }}</a>
            </li>
          </ul>
        </div>
        <!--<div class="col-xs-6 col-sm">
          <ul>Tesisler
            <li><a href="#">360 Restaurant</a></li>
            <li><a href="#">Panorama Cafe</a></li>
            <li><a href="#">Kule Giftshop</a></li>
            <li><a href="#">Seyir Terası 1</a></li>
            <li><a href="#">Seyir Terası 2</a></li>
            <li><a href="#">Bisiklet Rotası</a></li>
            <li><a href="#">Yürüyüş Yolu</a></li>
            <li><a href="#">Yeşil Bahçe</a></li>
            <li><a href="#">Oyun Alanları</a></li>
          </ul>
        </div>-->
        <div class="col-xs-6 col-sm">
          <ul>
            {{
              $t("footer.location")
            }}
            <li>
              <a href="https://goo.gl/maps/6MX24Z7fC8sZtFy2A">
                {{ $t("footer.address") }}:
                <div v-html="this.dynamicContents.address"/>
                <br class="hidden-xs"/><br class="hidden-xs"
              /></a>
            </li>
          </ul>
        </div>
        <div class="col-xs-6 col-sm">
          <ul>
            {{
              $t("header.contact")
            }}
            <li>
              <a :href="'tel:'+
              cleanSpace(this.dynamicContents.phone)">
                {{ $t("footer.phone") }}:
                {{ this.dynamicContents.phone }} </a>
            </li>
            <li>
              <a :href="'tel:'+
              cleanSpace(this.dynamicContents.phone_restaurant)">
                {{ $t("footer.phone_restaurant") }}:
                {{ this.dynamicContents.phone_restaurant }}
              </a>
            </li>
            <li>
              <a :href= "'mailto:' + this.dynamicContents.email " >
                {{ $t("footer.email") }}:
                <span class="g">{{ this.dynamicContents.email }}</span><br />
              </a>
            </li>
          </ul>
        </div>
        <div class="col-xs-12 col-lg-2">
          <ul>
            {{
              $t("footer.social_media")
            }}
            <li>
              <a target="_blank" rel="noreferrer"
              :href="this.dynamicContents.twitter_url"
                ><img src="@/assets/images/icons/twitter.svg" alt=""
              /></a>
              <a target="_blank" rel="noreferrer"
              :href="this.dynamicContents.facebook_url"
                ><img src="@/assets/images/icons/facebook.svg" alt=""
              /></a>
              <a target="_blank" rel="noreferrer"
              :href="this.dynamicContents.instagram_url"
                ><img src="@/assets/images/icons/instagram.svg" alt=""
              /></a>
              <a
                target="_blank"
                rel="noreferrer"
                :href="this.dynamicContents.youtube_url"
                ><img src="@/assets/images/icons/youtube.svg" alt=""
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      dynamicContents: {}
    };
  },
  async created() {
    this.dynamicContents = this.getDynamicContents();
  },
  methods: {
    cleanSpace(val) {
      // eslint-disable-next-line no-new-wrappers
      return new String(val).replace(/\s+/g, "");
    }
  }
};
</script>

<style scoped></style>
