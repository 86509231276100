<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="static hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('500.title') }}</div>
            <div class="section-description">
              <p>{{ $t('500.message') }}</p>
            </div>
            <a href="/" class="button filled green w">{{ $t('header.homepage') }}</a>
            <a href="/bilet-al" class="button filled green w">{{ $t('header.reservation') }}</a>
            <a href="/iletisim" class="button filled green w">{{ $t('header.contact') }}</a>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";

export default {
  name: "PageNotFound",

  components: {
    BackgroundMovement,
    Footer,
    HeaderNav
  }
};
</script>

<style scoped></style>
