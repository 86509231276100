<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="account hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('account.renew_password') }}</div>
          </div>
        </div>

        <div class="row center-xs">
          <div class="col-xs-12 col-md-6 no-gutter-xs">
            <MessageBar></MessageBar>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 col-md-6 no-gutter-xs">
            <div class="bg-white">
              <vue-element-loading
                :active="isLoading"
                background-color="rgba(255, 255, 255, 0.8)"
                color="#00baab"
                spinner="spinner"
              />
              <div class="section-description">
                <p>{{ $t('account.renew_password_desc') }}</p>
              </div>
              <form data-vv-scope="recover">
                <div class="material-form-group">
                  <input
                    v-model="passwordRecoverObject.newPassword"
                    v-validate="{ required: true }"
                    ref="password"
                    name="password"
                    type="password"
                    placeholder=" "
                  />
                  <label>{{ $t('register.password') }}</label>
                </div>
                <ul class="requirements">
                  <li :class="{ ok: passwordValidation.charLength }">
                    {{ $t('register.password_six_chars') }}
                  </li>
                  <li :class="{ ok: passwordValidation.uppercaseChar }">
                    {{ $t('register.password_uppercase') }}
                  </li>
                  <li :class="{ ok: passwordValidation.lowercaseChar }">
                    {{ $t('register.password_lowercase') }}
                  </li>
                  <li :class="{ ok: passwordValidation.number }">
                    {{ $t('register.password_number') }}
                  </li>
                </ul>
                <div class="material-form-group">
                  <input
                    v-validate="{ required: true, confirmed: 'password' }"
                    v-model="passwordRecoverObject.newPasswordAgain"
                    name="password_again"
                    type="password"
                    placeholder=" "
                  />
                  <label>{{ $t('register.password_reenter') }}</label>
                </div>
                <ul class="requirements">
                  <li :class="{ ok: passwordValidation.repeatPasswordSame }">
                    {{ $t('register.password_same') }}
                  </li>
                </ul>
                <div @click="sendNewPassword" class="button filled green b">
                  <vue-element-loading
                    :active="buttonLoader"
                    background-color="rgba(255, 255, 255, 0.8)"
                    color="#00baab"
                    spinner="spinner"
                  />
                  {{ $t('account.save') }}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/main/Footer.vue";
import HeaderNav from "@/components/main/HeaderNav.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import CustomerService from "@/services/customer/customer-service";
import { mapActions } from "vuex";
import MessageBar from "@/components/main/MessageBar.vue";

export default {
  name: "PasswordRecover",
  components: {
    MessageBar, BackgroundMovement, HeaderNav, Footer
  },
  data() {
    return {
      isLoading: false,
      buttonLoader: false,
      uuid: this.$route.params.uuid,
      token: this.$route.params.token,
      passwordRecoverObject: {
        newPassword: "",
        newPasswordAgain: ""
      },
      passwordValidation: {
        charLength: false,
        uppercaseChar: false,
        lowercaseChar: false,
        number: false,
        repeatPasswordSame: false
      }
    };
  },
  beforeCreate() {
    if (typeof this.$session.get("cusdet") !== "undefined") {
      this.$router.push("/");
    }
  },
  created() {
    if (
      typeof this.uuid !== "undefined"
      && this.uuid.length > 0
      && typeof this.token !== "undefined"
      && this.token.length > 0
    ) {
      this.verifyPasswordRecover();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions([
      "showMessageBar",
      "hideMessageBar"
    ]),
    verifyPasswordRecover() {
      this.isLoading = true;

      const data = CustomerService.verifyPasswordReset(this.uuid, this.token);

      data.then((res) => {
        if (res.status !== 200) {
          this.showMessageBar({
            message: `${this.$t("generic.unexpected_error")}(${res.message})`,
            type: "error"
          });
        }

        this.isLoading = false;
      });
    },
    sendNewPassword() {
      this.hideMessageBar();
      this.buttonLoader = true;

      this.$validator.validateAll("recover").then((result) => {
        if (result && this.checkObject(this.passwordValidation, true)) {
          const data = CustomerService.createNewPassword(
            this.uuid,
            this.token,
            this.passwordRecoverObject
          );

          data.then((res) => {
            if (res.status === 200) {
              this.showMessageBar({
                message: this.$t("generic.successful"),
                type: "success"
              });
              this.$router.push("/giris-yap");
            } else {
              this.showMessageBar({
                message: res.message,
                type: "error"
              });
            }

            this.buttonLoader = false;
          });
        } else {
          this.buttonLoader = false;
        }
      });
    }
  },
  watch: {
    "passwordRecoverObject.newPasswordAgain": {
      handler() {
        this.$validator.validate("recover.password_again").then((result) => {
          if (result) {
            this.passwordValidation.repeatPasswordSame = true;
          } else {
            this.passwordValidation.repeatPasswordSame = false;
          }
        });
      }
    },
    "passwordRecoverObject.newPassword": {
      handler(newVal) {
        const charRegex = RegExp(/^.{6,}$/);
        const uppercaseCharRegex = RegExp(/.*[A-Z].*/);
        const lowercaseCharRegex = RegExp(/.*[a-z].*/);
        const numberRegex = RegExp(/\d/);

        if (charRegex.test(newVal)) {
          this.passwordValidation.charLength = true;
        } else {
          this.passwordValidation.charLength = false;
        }

        if (uppercaseCharRegex.test(newVal)) {
          this.passwordValidation.uppercaseChar = true;
        } else {
          this.passwordValidation.uppercaseChar = false;
        }

        if (lowercaseCharRegex.test(newVal)) {
          this.passwordValidation.lowercaseChar = true;
        } else {
          this.passwordValidation.lowercaseChar = false;
        }

        if (numberRegex.test(newVal)) {
          this.passwordValidation.number = true;
        } else {
          this.passwordValidation.number = false;
        }

        if (this.passwordRecoverObject.newPassword.length > 0) {
          this.$validator.validate("recover.password_again").then((result) => {
            if (result) {
              this.passwordValidation.repeatPasswordSame = true;
            } else {
              this.passwordValidation.repeatPasswordSame = false;
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped></style>
