<template>
  <section class="popup verify-timer">
    <div class="popup-body">
      <div @click="closePopup" class="popup-closer"></div>
      <div class="popup-box-header">
        Paylaş
      </div>
      <div v-if="errorMessage.length > 0" class="section-description error">
        <p>{{ errorMessage }}</p>
      </div>
      <div v-if="successMessage.length > 0" class="section-description success">
        <p>{{ successMessage }}</p>
      </div>
      <form data-vv-scope="share">
        <div class="row">
          <div class="col-xs-5">
            <div class="material-form-group">
              <select name="Ülke Kodu" id="phoneCountryCode"
                    v-validate="{ required: true }"
                    :class="{
                      'invalid': errors.has('share.Ülke Kodu'),
                      'valid': phoneCountryCode.length > 0
                               && !errors.has('share.Ülke Kodu'),
                      'required': true
                    }"
                    v-model="phoneCountryCode">
                <option v-for="(item, index) in phoneCountryCodeList" v-bind:key="index"
                        :value="item.dial_code.substring(1)">
                  {{ item.name }} ({{ item.dial_code }})
                </option>
              </select>
              <label for="phoneCountryCode">Ülke Kodu</label>
              <span v-show="errors.has('share.Ülke Kodu')"
                    class="error-message">
                {{ errors.first("share.Ülke Kodu") }}
              </span>
            </div>
          </div>
          <div class="col-xs-7">
            <div class="material-form-group">
              <input
                v-model="phoneNumber"
                name="Telefon"
                :class="{
                          invalid: errors.has('share.Telefon'),
                          valid: phoneNumber.length > 0 &&
                            !errors.has('share.Telefon'),
                          required: true
                        }"
                type="tel"
                v-mask="phoneCountryCode === '90' ? '##########' : ''"
                v-validate="{ required: true, regex: /^[0-9]*$/ }"
                placeholder=" "
              />
              <label>Telefon</label>
              <span v-show="errors.has('share.Telefon')" class="error-message">
                {{ errors.first("share.Telefon") }}
              </span>
            </div>
          </div>
        </div>
        <div @click="submitShare" class="button filled green b">
          <vue-element-loading
            :active="isLoading"
            background-color="rgba(255, 255, 255, 1)"
            color="#00baab"
            spinner="spinner"
          />
          Gönder
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import OtpService from "@/services/notification/otp-service";

const countryPhoneList = require("@/data/countryPhoneList.json");

export default {
  name: "SharePopup",
  props: ["shareType", "uuid"],
  data() {
    return {
      isLoading: false,
      phoneCountryCodeList: countryPhoneList,
      phoneCountryCode: "90",
      phoneNumber: "",
      errorMessage: "",
      successMessage: ""
    };
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
    },
    submitShare() {
      this.errorMessage = "";
      this.successMessage = "";
      this.isLoading = true;
      let data = {};
      const phoneNumber = `${this.phoneCountryCode} ${this.phoneNumber.replace(/[^A-Z0-9]/ig, "")}`;

      if (this.shareType === "ticket") {
        data = OtpService.shareTicket({
          phoneNumber,
          uuid: this.uuid
        });
      } else {
        data = OtpService.shareReservation({
          phoneNumber,
          uuid: this.uuid
        });
      }

      data.then((res) => {
        if (res.status === 200) {
          this.successMessage = res.data;
        } else {
          this.errorMessage = res.message;
        }

        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped>
.popup-body {
  position: relative;
}
</style>
