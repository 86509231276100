<template>
  <div class="bg-white tab-ticket-type">
    <SmsVerification
      v-if="showSmsOTP"
      @phone-verified="onPhoneVerified"
      @sms-time-out="onSmsTimeOut"
      @cancel-validation="closeSmsPopup"
      v-bind:phoneNumber="customerPhoneNumber"
    >
    </SmsVerification>
    <vue-element-loading
      :active="isDataLoading"
      background-color="rgba(255, 255, 255, 1)"
      color="#00baab"
      spinner="spinner"
    />
    <form data-vv-scope="customer-info">
      <div class="row">
        <div class="col-xs-12">
          <div class="box-header">{{ $t("reservation.contact_info") }}</div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="material-form-group">
            <input
              v-model="stepData.customer_info.firstName"
              v-validate="{ required: true, min: 2 }"
              :class="{
                invalid: errors.has('customer-info.İsim'),
                valid:
                  stepData.customer_info.firstName.length > 0 && !errors.has('customer-info.İsim'),
                required: true
              }"
              name="İsim"
              type="text"
              placeholder=" "
            />
            <label>{{ $t("register.name") }}</label>
            <span v-show="errors.has('customer-info.İsim')" class="error-message">
              {{ errors.first("customer-info.İsim") }}
            </span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="material-form-group">
            <input
              v-model="stepData.customer_info.lastName"
              v-validate="{ required: true, min: 2 }"
              :class="{
                invalid: errors.has('customer-info.Soyisim'),
                valid:
                  stepData.customer_info.lastName.length > 0 &&
                  !errors.has('customer-info.Soyisim'),
                required: true
              }"
              name="Soyisim"
              type="text"
              placeholder=" "
            />
            <label>{{ $t("register.surname") }}</label>
            <span v-show="errors.has('customer-info.Soyisim')" class="error-message">
              {{ errors.first("customer-info.Soyisim") }}
            </span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="row">
            <div class="col-xs-5">
              <div class="material-form-group">
                <select
                  name="Ülke Kodu"
                  id="phoneCountryCode"
                  v-validate="{ required: true }"
                  :class="{
                    invalid: errors.has('customer-info.Ülke Kodu'),
                    valid:
                      stepData.customer_info.phoneCountryCode.length > 0 &&
                      !errors.has('customer-info.Ülke Kodu'),
                    required: true
                  }"
                  v-model="stepData.customer_info.phoneCountryCode"
                >
                  <option
                    v-for="(item, index) in phoneCountryCodeList"
                    v-bind:key="index"
                    :value="item.dial_code.substring(1)"
                  >
                    {{ item.name }} ({{ item.dial_code }})
                  </option>
                </select>
                <label for="phoneCountryCode">{{ $t("register.country_code") }}</label>
                <span v-show="errors.has('customer-info.Ülke Kodu')" class="error-message">
                  {{ errors.first("customer-info.Ülke Kodu") }}
                </span>
              </div>
            </div>
            <div class="col-xs-7">
              <div class="material-form-group">
                <input
                  v-model="stepData.customer_info.phoneNumber"
                  name="Telefon"
                  :class="{
                    invalid: errors.has('customer-info.Telefon'),
                    valid:
                      stepData.customer_info.phoneNumber.length > 0 &&
                      !errors.has('customer-info.Telefon'),
                    required: true
                  }"
                  type="tel"
                  v-mask="stepData.customer_info.phoneCountryCode === '90' ? '##########' : ''"
                  v-validate="{
                    required: true,
                    regex: /^[0-9]*$/
                  }"
                  placeholder=" "
                />
                <label>{{ $t("register.phone") }}</label>
                <span v-show="errors.has('customer-info.Telefon')" class="error-message">
                  {{ errors.first("customer-info.Telefon") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="material-form-group">
            <input
              v-model="stepData.customer_info.emailAddress"
              v-validate="{ required: true, email: true }"
              :class="{
                invalid: errors.has('customer-info.Email'),
                valid:
                  stepData.customer_info.emailAddress.length > 0 &&
                  !errors.has('customer-info.Email'),
                required: true
              }"
              name="Email"
              type="email"
              placeholder=" "
            />
            <label>{{ $t("register.email") }}</label>
            <span v-show="errors.has('customer-info.Email')" class="error-message">
              {{ errors.first("customer-info.Email") }}
            </span>
          </div>
        </div>

        <div class="col-xs-12">
          <div class="box-header">{{ $t("reservation.invoice_info") }}</div>
        </div>

        <div class="col-xs-12">
          <div class="billing-approve">
            <div class="material-radio-group">
              <input v-model="wantInvoice" type="checkbox" id="0300" name="ticket-type" />
              <label for="0300">{{ $t("reservation.invoice_desc") }}</label>
            </div>
          </div>
        </div>
        <div v-if="wantInvoice" class="invoice-detail">
          <div class="row no-margin type-selection">
            <div class="col-xs-6 col-md-3">
              <div class="material-radio-group invoice-radio">
                <input
                  v-model="accountInformation.currentCard.type"
                  id="INDIVIDUAL"
                  name="INDIVIDUAL"
                  value="INDIVIDUAL"
                  type="radio"
                />
                <label for="INDIVIDUAL"
                  ><b>{{ $t("reservation.personal") }}</b></label
                >
              </div>
            </div>
            <div class="col-xs-6 col-md-3">
              <div class="material-radio-group invoice-radio">
                <input
                  v-model="accountInformation.currentCard.type"
                  id="CORPORATE"
                  name="CORPORATE"
                  value="CORPORATE"
                  type="radio"
                />
                <label for="CORPORATE"
                  ><b>{{ $t("reservation.business") }}</b></label
                >
              </div>
            </div>
          </div>
          <div
            v-if="accountInformation.currentCard.type === 'INDIVIDUAL'"
            :key="invoiceValidation"
            class="row personal no-margin"
          >
            <div class="col-xs-12 col-md-6">
              <div class="material-form-group">
                <input
                  v-model="personalInvoice.firstName"
                  v-validate="{ required: true, min: 2 }"
                  :class="{
                    invalid: errors.has('customer-info.Fatura İsim'),
                    valid:
                      personalInvoice.firstName.length > 0 &&
                      !errors.has('customer-info.Fatura İsim'),
                    required: true
                  }"
                  name="Fatura İsim"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("register.name") }}</label>
                <span v-show="errors.has('customer-info.Fatura İsim')" class="error-message">
                  {{ errors.first("customer-info.Fatura İsim") }}
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="material-form-group">
                <input
                  v-model="personalInvoice.lastName"
                  v-validate="{ required: true, min: 2 }"
                  :class="{
                    invalid: errors.has('customer-info.Fatura Soyisim'),
                    valid:
                      personalInvoice.lastName.length > 0 &&
                      !errors.has('customer-info.Fatura Soyisim'),
                    required: true
                  }"
                  name="Fatura Soyisim"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("register.surname") }}</label>
                <span v-show="errors.has('customer-info.Fatura Soyisim')" class="error-message">
                  {{ errors.first("customer-info.Fatura Soyisim") }}
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="material-form-group">
                <input
                  v-model="personalInvoice.identificationNumber"
                  v-validate="{ required: true, min: 11, max: 11 }"
                  v-mask="'###########'"
                  :class="{
                    invalid: errors.has('customer-info.TCKN/VKN'),
                    valid:
                      personalInvoice.identificationNumber.length > 0 &&
                      !errors.has('customer-info.TCKN/VKN'),
                    required: true
                  }"
                  name="TCKN/VKN"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("reservation.tax_number") }}</label>
                <span v-show="errors.has('customer-info.TCKN/VKN')" class="error-message">
                  {{ errors.first("customer-info.TCKN/VKN") }}
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="material-form-group">
                <input
                  v-model="personalInvoice.taxOffice"
                  v-validate="{ required: true }"
                  :class="{
                    invalid: errors.has('customer-info.Vergi Dairesi'),
                    valid:
                      personalInvoice.taxOffice.length > 0 &&
                      !errors.has('customer-info.Vergi Dairesi'),
                    required: true
                  }"
                  name="Vergi Dairesi"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("reservation.tax_office") }}</label>
                <span v-show="errors.has('customer-info.Vergi Dairesi')" class="error-message">
                  {{ errors.first("customer-info.Vergi Dairesi") }}
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-md-12">
              <div class="material-form-group">
                <input
                  v-model="personalInvoice.address"
                  v-validate="{ required: true }"
                  :class="{
                    invalid: errors.has('customer-info.Adres'),
                    valid: personalInvoice.address.length > 0 && !errors.has('customer-info.Adres'),
                    required: true
                  }"
                  name="Adres"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("footer.address") }}</label>
                <span v-show="errors.has('customer-info.Adres')" class="error-message">
                  {{ errors.first("customer-info.Adres") }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="accountInformation.currentCard.type === 'CORPORATE'"
            :key="invoiceValidation"
            class="row company no-margin"
          >
            <div class="col-xs-12 col-md-6">
              <div class="material-form-group">
                <input
                  v-model="companyInvoice.companyTitle"
                  v-validate="{ required: true, min: 2 }"
                  name="Şirket Adı"
                  :class="{
                    invalid: errors.has('customer-info.Şirket Adı'),
                    valid:
                      companyInvoice.companyTitle.length > 0 &&
                      !errors.has('customer-info.Şirket Adı'),
                    required: true
                  }"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("reservation.company_name") }}</label>
                <span v-show="errors.has('customer-info.Şirket Adı')" class="error-message">
                  {{ errors.first("customer-info.Şirket Adı") }}
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="material-form-group">
                <input
                  v-model="companyInvoice.taxOffice"
                  v-validate="{ required: true }"
                  :class="{
                    invalid: errors.has('customer-info.Şirket Vergi Dairesi'),
                    valid:
                      companyInvoice.taxOffice.length > 0 &&
                      !errors.has('customer-info.Şirket Vergi Dairesi'),
                    required: true
                  }"
                  name="Şirket Vergi Dairesi"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("reservation.tax_office") }}</label>
                <span
                  v-show="errors.has('customer-info.Şirket Vergi Dairesi')"
                  class="error-message"
                >
                  {{ errors.first("customer-info.Şirket Vergi Dairesi") }}
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="material-form-group">
                <input
                  v-model="companyInvoice.taxNumber"
                  v-validate="{ required: true, min: 10, max: 11 }"
                  v-mask="'###########'"
                  :class="{
                    invalid: errors.has('customer-info.Şirket Vergi No'),
                    valid:
                      companyInvoice.taxNumber.length > 0 &&
                      !errors.has('customer-info.Şirket Vergi No'),
                    required: true
                  }"
                  name="Şirket Vergi No"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("reservation.tax_business") }}</label>
                <span v-show="errors.has('customer-info.Şirket Vergi No')" class="error-message">
                  {{ errors.first("customer-info.Şirket Vergi No") }}
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <div class="material-form-group">
                <input
                  v-model="companyInvoice.address"
                  v-validate="{ required: true }"
                  :class="{
                    invalid: errors.has('customer-info.Şirket Adres'),
                    valid:
                      companyInvoice.address.length > 0 &&
                      !errors.has('customer-info.Şirket Adres'),
                    required: true
                  }"
                  name="Şirket Adres"
                  type="text"
                  placeholder=" "
                />
                <label>{{ $t("footer.address") }}</label>
                <span v-show="errors.has('customer-info.Şirket Adres')" class="error-message">
                  {{ errors.first("customer-info.Şirket Adres") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-xs-12">
        <div class="box-header">{{ $t("reservation.ticket_type") }}</div>
        <div class="section-description">
          <p>{{ $t("reservation.ticket_type_desc") }}</p>
        </div>
      </div>
    </div>
    <div class="row" v-for="(item, index) in stepData.ticket_types" v-bind:key="index">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12">
            <div class="ticket-type">
              <div class="col-xs-12 col-xl-12 box-mini-header box-mini-prop">
                {{ index + 1 }}. {{ $t("reservation.ticket_type_prop") }}
              </div>
              <div class="col-xs-12 col-xl-12 box-mini-header">
                {{ $t("reservation.ticket_type_alt") }}
                <div class="section-description mb-5">
                  <p class="mb-0">{{ $t("reservation.ticket_type_alt_desc") }}</p>
                </div>
              </div>
              <div
                v-for="(type, typeIndex) in ticketTypeList"
                v-bind:key="typeIndex"
                :class="type.sale_price > 0 ? 'col-xs-6 col-lg' : ''"
              >
                <div v-if="type.sale_price > 0" class="material-radio-group">
                  <input
                    type="checkbox"
                    :id="`type-${index}-${typeIndex}`"
                    v-model="stepData.ticket_types[index].ticket_type_index"
                    :name="`ticket-type-${index}`"
                    :value="typeIndex"
                    :true-value="typeIndex"
                  />
                  <label :for="`type-${index}-${typeIndex}`">
                    <strong>{{ type.name }}</strong
                    >{{ currencyFormat(type.sale_price) }} TL
                    <span class="discount" v-if="type.discount_rate > 0">
                      {{ currencyFormat(type.discount_amount) }} TL {{ $t("reservation.discount") }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12" v-if="additionalTicketTypeKey >= 0">
            <div class="ticket-type additional-service-section">
              <div class="col-xs-12 col-xl-12 box-mini-header">
                {{ $t("reservation.add_type_alt") }}
                <div class="section-description mb-5">
                  <p class="mb-0">{{ $t("reservation.add_type_alt_desc") }}</p>
                </div>
              </div>
              <div
                v-for="(ids, additionalType) in additionalServiceList"
                v-bind:key="additionalType"
                class="col-xs-6 col-lg"
              >
                <div class="material-radio-group">
                  <input
                    type="checkbox"
                    :id="`ad-type-${index}-${additionalType}`"
                    :name="`ticket-type-ad-${index}-${additionalType}`"
                    :value="ids"
                    v-model="stepData.selected_additional_services[index]"
                    :true-value="[]"
                  />
                  <label :for="`ad-type-${index}-${additionalType}`">
                    <strong class="additional-name">{{ additionalType }}</strong>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from "@/services/reservation/ticket-service";
import CustomerService from "@/services/customer/customer-service";
import { mapActions, mapState } from "vuex";
import SmsVerification from "@/components/main/SmsVerification.vue";

const countryPhoneList = require("@/data/countryPhoneList.json");

export default {
  name: "VisitorInfo",
  components: { SmsVerification },
  props: ["reservationData", "validateStepData", "calculateObject"],
  computed: {
    ...mapState(["customerLoggedIn"])
  },
  data() {
    return {
      token: this.$session.get("restoken"),
      showSmsOTP: false,
      isSmsOTPActive: false,
      invoiceValidation: 0,
      isDataLoading: false,
      wantInvoice: false,
      phoneCountryCodeList: countryPhoneList,
      customerPhoneNumber: "",
      personalInvoice: {
        type: "INDIVIDUAL",
        firstName: "",
        lastName: "",
        identificationNumber: "",
        taxOffice: "",
        address: ""
      },
      companyInvoice: {
        type: "CORPORATE",
        taxNumber: "",
        companyTitle: "",
        taxOffice: "",
        address: ""
      },
      accountInformation: {
        currentCard: {
          type: "INDIVIDUAL",
          firstName: "",
          lastName: "",
          identificationNumber: "",
          taxNumber: "",
          companyTitle: "",
          taxOffice: "",
          address: ""
        }
      },
      ticketTypeList: {},
      additionalTicketTypeKey: null,
      additionalServiceList: [],
      showList: false,
      stepData: {
        customer_info: {
          firstName: "",
          lastName: "",
          phoneCountryCode: "90",
          phoneNumber: "",
          emailAddress: "",
          phoneNumberVerified: false,
          verifiedPhoneNumber: ""
        },
        accountInformation: {},
        ticket_types: [],
        ticket_details: [],
        selected_additional_services: {}
      }
    };
  },
  async mounted() {
    await this.getTicketTypes();
    await this.getGroupedAdditionalServiceIds();
    if (this.customerLoggedIn) {
      this.stepData.customer_info = this.$session.get("cusdet");
    }

    if (
      typeof this.reservationData.VisitorInfo !== "undefined"
      && this.reservationData.VisitorInfo.ticket_types.length
        === this.reservationData.DateTimeSelection.ticket_count
    ) {
      this.stepData = this.reservationData.VisitorInfo;
    } else {
      this.prepareStepData();
      this.prepareTicketDetail();
      this.setAdditionalServiceSelection();
    }

    this.isSmsOTPActive = this.getSmsOTPStatus();
  },
  watch: {
    "accountInformation.currentCard.type": {
      handler() {
        this.invoiceValidation += 1;
      }
    },
    "stepData.customer_info.phoneNumber": {
      handler() {
        this.customerPhoneNumber = this.normalizeObject(
          `${
            this.stepData.customer_info.phoneCountryCode
          } ${this.stepData.customer_info.phoneNumber.replace(/[^A-Z0-9]/gi, "")}`
        );
        this.isSmsOTPActive = this.getSmsOTPStatus();
      }
    },
    "stepData.customer_info.phoneCountryCode": {
      handler() {
        this.customerPhoneNumber = this.normalizeObject(
          `${
            this.stepData.customer_info.phoneCountryCode
          } ${this.stepData.customer_info.phoneNumber.replace(/[^A-Z0-9]/gi, "")}`
        );
        this.isSmsOTPActive = this.getSmsOTPStatus();
      }
    },
    "stepData.ticket_types": {
      deep: true,
      handler() {
        this.fillStepData();
        this.$emit("ticket-selected", this.stepData, 2);
      }
    },
    validateStepData: {
      handler(newVal) {
        if (newVal) {
          this.validateStep();
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "changePopupErrorStatus",
      "changeErrorMessage"
    ]),
    closeSmsPopup() {
      this.showSmsOTP = false;
      this.$emit("validation-failed");
    },
    setTicketType(index) {
      this.stepData.ticket_types[index].ticket_type_index = this.additionalTicketTypeKey;
    },
    setAdditionalServiceSelection() {
      if (this.reservationData.DateTimeSelection.buy_additional_services.length > 0) {
        this.reservationData.DateTimeSelection.buy_additional_services.forEach((item) => {
          this.stepData.ticket_types.forEach((_, index) => {
            this.stepData.selected_additional_services[index].push(
              this.additionalServiceList[item]
            );
            this.setTicketType(index);
          });
        });
      }
    },
    validateStep() {
      this.$validator.validateAll("customer-info").then(async (result) => {
        if (result) {
          const updateBlockedResult = await this.$store.dispatch("updateBlockedReservation", {
            token: this.token,
            tickets: this.calculateObject.tickets,
            step: 1
          });

          if (!updateBlockedResult) {
            this.$emit("validation-failed");
            return;
          }

          if (this.wantInvoice) {
            let invoiceData = {};

            if (this.accountInformation.currentCard.type === "INDIVIDUAL") {
              invoiceData = {
                ...this.accountInformation.currentCard,
                ...this.personalInvoice
              };
            } else {
              invoiceData = {
                ...this.accountInformation.currentCard,
                ...this.companyInvoice
              };
            }

            this.stepData.accountInformation.currentCard = invoiceData;
          }

          if (this.isSmsOTPActive) {
            this.showSmsOTP = true;
          } else {
            this.$emit("step-completed", this.stepData, 2);
          }
        } else {
          const firstErrorItem = "input[aria-invalid=\"true\"]";
          this.scrollToItem(firstErrorItem);

          this.$emit("validation-failed");
        }
      });
    },
    async getTicketTypes() {
      this.isDataLoading = true;
      const data = TicketService.getTicketTypes({});

      await data.then((res) => {
        if (res.status === 200) {
          this.ticketTypeList = res.data;
          this.additionalTicketTypeKey = Object.keys(this.ticketTypeList).find(
            (key) => this.ticketTypeList[key].sale_price === 0
          );
        }

        this.isDataLoading = false;
      });
    },
    async getGroupedAdditionalServiceIds() {
      this.isDataLoading = true;
      const data = TicketService.getGroupedAdditionalServiceIds();

      await data.then((res) => {
        if (res.status === 200) {
          const selectedTime = this.reservationData.DateTimeSelection.selected_time;
          this.additionalServiceList = Object.keys(res.data)
            .filter((key) => !(selectedTime > 19 && key.startsWith("Hedef")))
            .reduce((value, key) => ({
              ...value,
              [key]: res.data[key]
            }), {});
        }

        this.isDataLoading = false;
      });
    },
    fillStepData() {
      if (this.stepData.ticket_details.length > 0) {
        this.stepData.ticket_types.forEach((item, index) => {
          let ticketTypeIndex = item.ticket_type_index;
          this.stepData.ticket_details[index] = [];

          if (ticketTypeIndex === false) {
            ticketTypeIndex = this.additionalTicketTypeKey;
          }

          this.stepData.ticket_details[index] = this.ticketTypeList[ticketTypeIndex];
        });
      }
    },
    prepareStepData() {
      for (let i = 0; i < this.reservationData.DateTimeSelection.ticket_count; i += 1) {
        this.stepData.ticket_types.push({ ticket_type_index: 0 });
        this.stepData.selected_additional_services[i] = [];
      }
    },
    prepareTicketDetail() {
      for (let i = 0; i < this.reservationData.DateTimeSelection.ticket_count; i += 1) {
        this.stepData.ticket_details.push({});
      }

      this.fillStepData();
      this.$emit("ticket-selected", this.stepData, 2);
    },
    getSmsOTPStatus() {
      if (process.env.VUE_APP_SMS_VERIFICATION_STATUS === "false") {
        return false;
      }

      if (
        this.customerLoggedIn
        && this.stepData.customer_info.phoneNumberVerified
        && `${this.$session.get("cusdet").phoneCountryCode} ${
          this.$session.get("cusdet").phoneNumber
        }` === this.customerPhoneNumber
      ) {
        return false;
      }

      if (
        !this.customerLoggedIn
        && this.stepData.customer_info.verifiedPhoneNumber.length > 0
        && this.stepData.customer_info.verifiedPhoneNumber === this.customerPhoneNumber
      ) {
        return false;
      }

      return true;
    },
    onPhoneVerified() {
      if (this.customerLoggedIn) {
        const data = CustomerService.getInformation();

        data.then((res) => {
          if (res.status === 200) {
            this.$session.set("cusdet", this.decorateCustomerDetail(res.data));
          }
        });
      }

      this.stepData.customer_info.verifiedPhoneNumber = this.customerPhoneNumber;
      this.stepData.customer_info.phoneNumberVerified = true;
      this.$emit("step-completed", this.stepData, 2);
    },
    onSmsTimeOut() {
      this.$emit("sms-failed");
    }
  }
};
</script>

<style scoped>
.discount {
  display: block;
  color: #00ba59;
  font: 400 12px/1.38 "Poppins", sans-serif;
  margin-top: 4px;
}
.error-message {
  color: #d60d38;
  font: 400 10px/18px "Poppins", sans-serif;
}
form {
  margin-bottom: 10px;
}
.no-margin {
  margin: 0 !important;
}
.invoice-radio label {
  padding-top: 5px;
  box-shadow: none !important;
}
.invoice-detail .type-selection {
  padding-bottom: 20px;
}
.box-mini-prop {
  text-decoration: underline;
}
.box-mini-header {
  padding-bottom: 10px;
}
</style>
