import Vue from "vue";
import Vuex from "vuex";
import VueSession from "vue-session";
import router from "@/router";
import DataHelper from "@/mixins/DataHelper";
import TicketService from "@/services/reservation/ticket-service";

Vue.use(Vuex);
Vue.use(VueSession, { persist: true });

export default new Vuex.Store({
  state: {
    customerLoggedIn: Vue.prototype.$session.get("cusdet"),
    loaderStatus: false,
    registerSuccess: false,
    messageBar: {
      message: "",
      type: ""
    },
    showPopupError: false,
    showPopup: false,
    popupError: {
      message: "",
      title: "",
      type: ""
    },
    popupContent: {
      body: "",
      title: "",
      type: ""
    }
  },
  mutations: {
    CHANGE_LOADER_STATUS(state, payload) {
      state.loaderStatus = payload;
    },
    CHANGE_POPUP_ERROR_STATUS(state, payload) {
      state.showPopupError = payload;
    },
    CHANGE_POPUP_STATUS(state, payload) {
      state.showPopup = payload;
    },
    CHANGE_ERROR_MESSAGE(state, payload) {
      state.popupError = payload;
    },
    CHANGE_MESSAGE_BAR(state, payload) {
      window.scrollTo(0, 0);
      state.messageBar = payload;
    },
    CHANGE_REGISTER_STATUS(state, payload) {
      state.registerSuccess = payload;
    },
    HIDE_MESSAGE_BAR(state) {
      state.messageBar.message = "";
    },
    LOGIN_CUSTOMER(state, payload) {
      Vue.prototype.$session.start();
      Vue.prototype.$session.set("cusdet", DataHelper.methods.decorateCustomerDetail(payload));
      router.go(0);
    },
    LOGOUT_CUSTOMER() {
      Vue.prototype.$session.destroy();
      router.go(0);
    }
  },
  actions: {
    async updateBlockedReservation({ commit }, payload) {
      const { step } = payload;
      const data = await TicketService.updateBlockedReservationItem(payload, step);

      if (data.status !== 200) {
        commit("CHANGE_ERROR_MESSAGE", {
          title: "Hata",
          message: data.message,
          type: "validation"
        });
        commit("CHANGE_POPUP_ERROR_STATUS", true);

        return false;
      }
      return true;
    },
    changeLoaderStatus({ commit }, status) {
      commit("CHANGE_LOADER_STATUS", status);
    },
    changePopupErrorStatus({ commit }, status) {
      commit("CHANGE_POPUP_ERROR_STATUS", status);
    },
    changePopupStatus({ commit }, status) {
      commit("CHANGE_POPUP_STATUS", status);
    },
    changeRegisterSuccessStatus({ commit }, status) {
      commit("CHANGE_REGISTER_STATUS", status);
    },
    changeErrorMessage({ commit }, object) {
      commit("CHANGE_ERROR_MESSAGE", object);
    },
    showMessageBar({ commit }, object) {
      commit("CHANGE_MESSAGE_BAR", object);
    },
    customerLoggedIn({ commit }, object) {
      commit("LOGIN_CUSTOMER", object);
    },
    customerLoggedOut({ commit }) {
      commit("LOGOUT_CUSTOMER");
    },
    hideMessageBar({ commit }) {
      commit("HIDE_MESSAGE_BAR");
    }
  },
  modules: {
  }
});
