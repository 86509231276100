export default {
  methods: {
    decorateCustomerDetail(obj) {
      const data = obj;
      const phoneParams = data.phoneNumber.split(" ");
      if (phoneParams.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        data.phoneNumber = phoneParams[1];
        // eslint-disable-next-line prefer-destructuring
        data.phoneCountryCode = phoneParams[0];
      } else {
        data.phoneCountryCode = "90";
        // eslint-disable-next-line prefer-destructuring
        data.phoneNumber = phoneParams[0];
      }
      return data;
    }
  }
};
