<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="account hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t("header.login") }}</div>
          </div>
        </div>
        <div class="row login">
          <div class="col-xs-12">
            <MessageBar></MessageBar>
          </div>
          <div class="col-xs-12">
            <div class="bg-white">
              <div class="left">
                <div class="box-header">{{ $t("login.make_login") }}</div>
                <div class="section-description">
                  <p>{{ $t("login.login_desc") }}</p>
                </div>
                <form data-vv-scope="login">
                  <div class="material-form-group">
                    <input
                      v-model="loginObject.emailAddress"
                      v-validate="{ required: true }"
                      name="Email"
                      type="email"
                      placeholder=" "
                    />
                    <label>{{ $t("register.email") }}</label>
                    <span v-show="errors.has('login.Email')" class="error-message">
                      {{ errors.first("login.Email") }}
                    </span>
                  </div>
                  <div class="material-form-group">
                    <input
                      v-model="loginObject.password"
                      v-validate="{ required: true }"
                      name="Şifre"
                      type="password"
                      placeholder=" "
                      autocomplete="on"
                    />
                    <label>{{ $t("register.password") }}</label>
                    <span v-show="errors.has('login.Şifre')" class="error-message">
                      {{ errors.first("login.Şifre") }}
                    </span>
                  </div>
                  <a @click.prevent="$router.push('sifremi-unuttum')" href="#" class="show-more">
                    {{ $t("login.forgot_password") }}
                  </a>
                  <div @click="loginCustomer" class="button filled green b block">
                    <vue-element-loading
                      :active="isLoading"
                      background-color="rgba(255, 255, 255, 0.8)"
                      color="#00baab"
                      spinner="spinner"
                    />
                    {{ $t("header.login") }}
                  </div>
                </form>
              </div>
              <div class="right">
                <a href="#" class="box-header">{{ $t("login.dont_have_account") }}</a>
                <a href="" class="section-description">
                  <p>{{ $t("login.dont_have_account_desc") }}</p>
                </a>
                <div @click="$router.push('uye-ol')" class="button filled white g">
                  {{ $t("header.signup") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import CustomerService from "@/services/customer/customer-service";
import { mapActions } from "vuex";
import MessageBar from "@/components/main/MessageBar.vue";

export default {
  name: "Login",
  components: {
    MessageBar,
    BackgroundMovement,
    Footer,
    HeaderNav
  },
  beforeCreate() {
    if (typeof this.$session.get("cusdet") !== "undefined") {
      this.$router.push("/");
    }
  },
  data() {
    return {
      loginObject: {
        emailAddress: "",
        password: ""
      },
      errorMessage: "",
      isLoading: false
    };
  },
  methods: {
    ...mapActions(["customerLoggedIn", "showMessageBar"]),
    loginCustomer() {
      this.isLoading = true;
      this.errorMessage = "";

      this.$validator.validateAll("login").then((result) => {
        if (result) {
          const data = CustomerService.login(this.loginObject);

          data.then((res) => {
            if (res.status === 200) {
              this.customerLoggedIn(res.data);
            } else {
              this.showMessageBar({
                message: `${this.$t("generic.unexpected_error")}(${res.message})`,
                type: "error"
              });
              this.errorMessage = `${this.$t("generic.unexpected_error")}(${res.message})`;
            }

            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.error-message {
  color: #d60d38;
  font: 400 10px/18px "Poppins", sans-serif;
}
</style>
