<template>
  <section class="popup cc-not-approved">
    <div class="popup-header">
      <div class="approve-text">
        {{ popupError.title }}
      </div>
    </div>
    <div class="popup-body">
      <p class="section-description">{{ popupError.message }}</p>
      <div v-if="popupError.type === 'payment'">
        <a @click.prevent="closePopup" class="button solid mid g">{{
          $t("popup.update_card_info")
        }}</a>
        <a href="#" class="button filled green">{{ $t("popup.try_again") }}</a>
      </div>
      <div v-if="popupError.type !== 'payment'">
        <a @click.prevent="closePopup" class="button filled green">{{ $t("popup.ok") }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "PopupError",
  computed: {
    ...mapState(["popupError"])
  },
  methods: {
    ...mapActions(["changePopupErrorStatus"]),
    closePopup() {
      this.changePopupErrorStatus(false);
    }
  }
};
</script>

<style scoped></style>
