import HttpService from "@/services/http-service";

const announcementService = {
  getAllRecords(lang) {
    return HttpService.get(`${process.env.VUE_APP_CONTENT_API_URL}/content/announcement?lang=${lang}`);
  },
  getLast2Records(lang) {
    return HttpService.get(`${process.env.VUE_APP_CONTENT_API_URL}/content/announcement?page=0&size=2&lang=${lang}`);
  }
};

export default announcementService;
