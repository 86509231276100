import { render, staticRenderFns } from "./PersonalInfoPolicy.vue?vue&type=template&id=276922e9&scoped=true&"
import script from "./PersonalInfoPolicy.vue?vue&type=script&lang=js&"
export * from "./PersonalInfoPolicy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276922e9",
  null
  
)

export default component.exports