<template>
  <div class="bg-white tab-payment">
    <div class="row">
      <div class="col-xs-12">
        <div class="box-header">{{ $t("reservation.tab_payment") }}</div>
        <div class="section-description">
          <p>{{ $t("reservation.payment_desc") }}</p>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="paymentService.acsUrl.length > 0 && !retryPayment && show3D"
        :key="paymentFormKey"
        class="row between-xs fd-column"
      >
        <div class="col-xs-12 flex">
          <section class="popup">
            <div class="popup-body relative">
              <div @click="closePopup" class="popup-closer"></div>
              <iframe name="ThreeDSecureFrame" id="ThreeDSecureFrame"></iframe>
            </div>
          </section>
          <form
            :action="paymentService.acsUrl"
            name="card-form"
            id="card-form"
            method="POST"
            target="ThreeDSecureFrame"
          >
            <input type="hidden" name="TermUrl" v-model="paymentService.termUrl" />
            <input type="hidden" name="PaReq" v-model="paymentService.paReq" />
            <input type="hidden" name="MD" v-model="paymentService.md" />
          </form>
        </div>
      </div>
    </div>
    <div class="row between-xs fd-column card-form">
      <form data-vv-scope="payment">
        <div class="col-xs-12 col-md-6">
          <div class="material-form-group">
            <input
              v-mask="'####-####-####-####'"
              v-validate="{
                required: true,
                regex: /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/
              }"
              :class="{
                invalid: errors.has('payment.Kart Numarası'),
                valid: paymentObject.cardNumber.length > 0 && !errors.has('payment.Kart Numarası'),
                required: true
              }"
              type="text"
              name="Kart Numarası"
              v-model="paymentObject.cardNumber"
              placeholder=" "
            />
            <label>{{ $t("reservation.card_number") }}</label>
            <span v-show="errors.has('payment.Kart Numarası')" class="error-message">
              {{ errors.first("payment.Kart Numarası") }}
            </span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="material-form-group">
            <input
              type="text"
              name="Kart Üzerindeki İsim"
              v-validate="{
                required: true,
                min: 3
              }"
              :class="{
                invalid: errors.has('payment.Kart Üzerindeki İsim'),
                valid:
                  paymentObject.cardHolderName.length > 0 &&
                  !errors.has('payment.Kart Üzerindeki İsim'),
                required: true
              }"
              v-model="paymentObject.cardHolderName"
              placeholder=" "
            />
            <label>{{ $t("reservation.cardholder") }}</label>
            <span v-show="errors.has('payment.Kart Üzerindeki İsim')" class="error-message">
              {{ errors.first("payment.Kart Üzerindeki İsim") }}
            </span>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 flex">
          <div class="material-form-group">
            <select
              name="Son Kullanma Ay"
              id="months"
              v-validate="{
                required: true
              }"
              :class="{
                invalid: errors.has('payment.Son Kullanma Ay'),
                valid:
                  paymentObject.expireMonth.length > 0 && !errors.has('payment.Son Kullanma Ay'),
                required: true
              }"
              v-model="paymentObject.expireMonth"
            >
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <label>{{ $t("reservation.month") }}</label>
            <span v-show="errors.has('payment.Son Kullanma Ay')" class="error-message">
              {{ errors.first("payment.Son Kullanma Ay") }}
            </span>
          </div>

          <div class="material-form-group">
            <select
              name="Son Kullanma Yıl"
              id="years"
              v-validate="{
                required: true
              }"
              :class="{
                invalid: errors.has('payment.Son Kullanma Yıl'),
                valid:
                  paymentObject.expireYear.length > 0 && !errors.has('payment.Son Kullanma Yıl'),
                required: true
              }"
              v-model="paymentObject.expireYear"
            >
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2029">2030</option>
            </select>
            <label>{{ $t("reservation.year") }}</label>
            <span v-show="errors.has('payment.Son Kullanma Yıl')" class="error-message">
              {{ errors.first("payment.Son Kullanma Yıl") }}
            </span>
          </div>
          <div class="material-form-group">
            <input
              type="text"
              name="CVV Kodu"
              v-model="paymentObject.cvv"
              v-validate="{
                required: true,
                regex: /^[0-9]{3}$/
              }"
              :class="{
                invalid: errors.has('payment.CVV Kodu'),
                valid: paymentObject.cvv.length > 0 && !errors.has('payment.CVV Kodu'),
                required: true
              }"
              v-mask="'###'"
              placeholder=""
            />
            <label>{{ $t("reservation.cvc") }}</label>
            <span v-show="errors.has('payment.CVV Kodu')" class="error-message">
              {{ errors.first("payment.CVV Kodu") }}
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import TicketService from "@/services/reservation/ticket-service";
import { mapActions } from "vuex";

export default {
  name: "Payment",
  data() {
    return {
      show3D: false,
      paymentFormKey: 0,
      paymentAttempt: 0,
      paymentObject: {
        uuid: this.$session.get("restoken"),
        cardNumber: "",
        cardHolderName: "",
        cvv: "",
        expireMonth: "01",
        expireYear: "2021"
      },
      paymentService: {
        acsUrl: "",
        paReq: "",
        md: "",
        termUrl: ""
      },
      saveReservationObject: {
        token: this.$session.get("restoken"),
        date: "",
        ticketCount: 0,
        regularReservationTimeId: "",
        contactInformation: {
          firstName: "",
          lastName: "",
          email: "",
          phoneCountryCode: "",
          phoneNumber: ""
        },
        tickets: {}
      }
    };
  },
  props: [
    "validateStepData",
    "paymentApproved",
    "calculateObject",
    "reservationData",
    "retryPayment"
  ],
  watch: {
    validateStepData: {
      handler(newVal) {
        if (newVal) {
          this.validateStep();
        }
      }
    }
  },
  created() {
    if (this.retryPayment) {
      this.paymentAttempt += 1;
      this.paymentService = {
        acsUrl: "",
        paReq: "",
        md: "",
        termUrl: ""
      };
    }
    window.addEventListener("message", this.paymentFailHandler, false);
  },
  methods: {
    ...mapActions(["changeErrorMessage", "changePopupErrorStatus", "changeLoaderStatus"]),
    validateStep() {
      this.$validator.validateAll("payment").then((result) => {
        if (result) {
          this.makePayment();
        } else {
          this.$emit("validation-failed");
        }
      });
    },
    closePopup() {
      this.show3D = false;
      this.changePopupErrorStatus(false);
      this.$emit("validation-failed");
    },
    paymentFailHandler(event) {
      if (event.origin === process.env.VUE_APP_PAYMENT_REDIRECT_ORIGIN) {
        if (event.data.statusCode === "0000") {
          window.location.href = `/rezervasyon-detay/${event.data.uuid}`;
        } else {
          this.paymentAttempt += 1;
          this.paymentService = {
            acsUrl: "",
            paReq: "",
            md: "",
            termUrl: ""
          };
          this.changeErrorMessage({
            title: "Hata",
            message: event.data.statusMessage,
            type: "validation"
          });
          this.changePopupErrorStatus(true);
          this.$emit("validation-failed");
        }
      }
    },
    async saveReservation() {
      this.changeLoaderStatus(true);

      this.saveReservationObject.contactInformation = this.reservationData.VisitorInfo
        .customer_info;
      this.saveReservationObject.contactInformation.phoneNumber = `${
        this.saveReservationObject.contactInformation.phoneCountryCode
      } ${this.saveReservationObject.contactInformation.phoneNumber.replace(/[^A-Z0-9]/gi, "")}`;
      this.saveReservationObject.accountInformation = this.reservationData.VisitorInfo
        .accountInformation;
      this.saveReservationObject.date = this.getFormattedDate(
        new Date(this.reservationData.DateTimeSelection.selected_date)
      );
      this.saveReservationObject.ticketCount = this.reservationData.DateTimeSelection.ticket_count;
      this.saveReservationObject.regularReservationTimeId = this.reservationData
        .DateTimeSelection.selected_time;
      this.saveReservationObject.tickets = this.calculateObject.tickets;

      if (typeof this.$session.get("cusdet") !== "undefined") {
        this.saveReservationObject.accountInformation.customerUuid = this.$session.get(
          "cusdet"
        ).uuid;
      }

      const data = TicketService.saveReservation(this.saveReservationObject);

      await data.then((res) => {
        if (
          res.message
            === "Geçersiz bir token ile işlem yapamazsınız, lütfen geçerli bir token edinin!"
          || res.status !== 200
        ) {
          this.changeErrorMessage({
            title: "Hata",
            message: res.message,
            type: "validation"
          });
          this.changePopupErrorStatus(true);
          this.$emit("validation-failed");

          return false;
        }

        this.changeLoaderStatus(false);
        return true;
      });
    },
    async makePayment() {
      this.show3D = false;
      this.isDataLoading = true;

      const cardValid = await this.checkCardBin();
      if (!cardValid) {
        this.changeErrorMessage({
          title: "Hata",
          message: "Geçersiz ya da işleme izin verilmeyen bir kart numarası girdiniz!",
          type: "validation"
        });
        this.changePopupErrorStatus(true);
        this.$emit("validation-failed");
        this.changeLoaderStatus(false);
        this.isDataLoading = false;
        return;
      }

      this.paymentAttempt += 1;

      if (this.paymentAttempt <= 1) {
        await this.saveReservation();
      }
      this.$emit("payment-retried");
      this.changeLoaderStatus(true);

      const requestData = this.normalizeObject(this.paymentObject);
      requestData.cardNumber = requestData.cardNumber.replace(/[^A-Z0-9]/gi, "");
      const data = TicketService.purchaseReservation(requestData);

      data.then((res) => {
        if (res.status === 200) {
          this.paymentService = res.data;
          this.show3D = true;
          this.paymentFormKey += 1;
          this.$nextTick(() => {
            document.getElementById("card-form").submit();
          });
        } else {
          this.changeErrorMessage({
            title: "Hata",
            message: res.message,
            type: "validation"
          });
          this.changePopupErrorStatus(true);
          this.$emit("validation-failed");
        }

        this.changeLoaderStatus(false);
        this.isDataLoading = false;
      });
    },
    checkCardBin() {
      const ticketIds = [];

      this.reservationData.VisitorInfo.ticket_details.forEach((item) => {
        ticketIds.push(item.id);
      });
      const requestData = {
        binCode: this.paymentObject.cardNumber.replace(/[^A-Z0-9]/gi, "").substr(0, 6),
        ticketTypesIds: ticketIds
      };

      const data = TicketService.checkCardBin(requestData);
      return data.then((res) => res.status === 200);
    }
  }
};
</script>

<style scoped>
iframe {
  width: 100%;
  min-height: 600px;
  border: 0;
}
.card-form div,
.card-form form {
  z-index: 2;
}
section.popup .popup-body {
  padding: 0 !important;
}
</style>
