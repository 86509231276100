<template>
  <div class="bg-white tab-summary">
    <div class="row">
      <div class="col-xs-12">
        <div class="box-header text-center">{{ $t("timeoutbox.timeout") }}</div>
        <div class="col-xs-12 col-md-12 text-center">
          <div @click="refreshPage" class="button filled green b">
            {{ $t("timeoutbox.try_again") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeOutBox",
  methods: {
    refreshPage() {
      this.$router.push("/bilet-al");
      this.$router.go(0);
    }
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
