<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="personel-info-policy hero">
      <div class="container no-gutter-xs">
        <div class="row center-xs">
          <div class="col-xs-12">
            <div class="section-header">{{ $t('personal_info_policy.maintitle') }}</div>
          </div>
        </div>
        <div class="row personel-info-policy center-xs">
          <div class="col-xs-12 col-md-9">
            <div class="content">
              <div class="content-body">
                <p class="center">
                  <b>
                    {{ $t('personal_info_policy.title1') }}
                  </b>
                </p>
                <p>
                  <b>{{ $t('personal_info_policy.content1_1') }}:</b>
                  {{ $t('personal_info_policy.content1_2') }}
                </p>
                <p>{{ $t('personal_info_policy.content1_3') }} https://www.turktower.com.tr
                  {{ $t('personal_info_policy.content1_4') }}
                </p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title2') }}</b>
                </p>
                <p>
                  <ul>
                    <li>{{ $t('personal_info_policy.content2_1') }}</li>
                    <li>{{ $t('personal_info_policy.content2_2') }}</li>
                    <li>{{ $t('personal_info_policy.content2_3') }}</li>
                    <li>{{ $t('personal_info_policy.content2_4') }}</li>
                    <li>{{ $t('personal_info_policy.content2_5') }}</li>
                    <li>{{ $t('personal_info_policy.content2_6') }}</li>
                    <li>{{ $t('personal_info_policy.content2_7') }}</li>
                  </ul>
                  {{ $t('personal_info_policy.content2_8') }}
                </p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title3') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content3_1') }}</p>
                <p>
                  <ul>
                    <li>{{ $t('personal_info_policy.content3_2') }}</li>
                    <li>{{ $t('personal_info_policy.content3_3') }}</li>
                    <li>{{ $t('personal_info_policy.content3_4') }}</li>
                    <li>{{ $t('personal_info_policy.content3_5') }}</li>
                    <li>{{ $t('personal_info_policy.content3_6') }}</li>
                    <li>{{ $t('personal_info_policy.content3_7') }}</li>
                  </ul>
                </p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title4') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content4_1') }}</p>
                <p>
                  <ul>
                    <li>
                      <b>{{ $t('personal_info_policy.content4_2_1') }}</b>
                      {{ $t('personal_info_policy.content4_2_2') }}
                    </li>
                    <li>
                      <b>{{ $t('personal_info_policy.content4_3_1') }}</b>
                      {{ $t('personal_info_policy.content4_3_2') }}
                    </li>
                    <li>
                      <b>{{ $t('personal_info_policy.content4_4_1') }}</b>
                      {{ $t('personal_info_policy.content4_4_2') }}
                    </li>
                    <li>
                      <b>{{ $t('personal_info_policy.content4_5_1') }}</b>
                      {{ $t('personal_info_policy.content4_5_2') }}
                    </li>
                    <li>
                      <b>{{ $t('personal_info_policy.content4_6_1') }}</b>
                      {{ $t('personal_info_policy.content4_6_2') }}
                    </li>
                    <li>
                      <b>{{ $t('personal_info_policy.content4_7_1') }}</b>
                      {{ $t('personal_info_policy.content4_7_2') }}
                    </li>
                  </ul>
                </p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title5') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content5_1') }}</p>
                <p>
                  <ul>
                    <li>{{ $t('personal_info_policy.content5_2') }}</li>
                    <li>{{ $t('personal_info_policy.content5_3') }}</li>
                    <li>{{ $t('personal_info_policy.content5_4') }}</li>
                    <li>{{ $t('personal_info_policy.content5_5') }}</li>
                  </ul>
                </p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title6') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content6_1') }}</p>
                <p>
                  <ul>
                    <li>{{ $t('personal_info_policy.content6_2') }}</li>
                    <li>{{ $t('personal_info_policy.content6_3') }}</li>
                    <li>{{ $t('personal_info_policy.content6_4') }}</li>
                    <li>{{ $t('personal_info_policy.content6_5') }}</li>
                    <li>{{ $t('personal_info_policy.content6_6') }}</li>
                    <li>{{ $t('personal_info_policy.content6_7') }}</li>
                    <li>{{ $t('personal_info_policy.content6_8') }}</li>
                    <li>{{ $t('personal_info_policy.content6_9') }}</li>
                  </ul>
                </p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title7') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content7_1') }}</p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title8') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content8_1') }}</p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title9') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content9_1') }}</p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title10') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content10_1') }}</p>
                <p>{{ $t('personal_info_policy.content10_2') }}</p>
                <p class="center">
                  <b>{{ $t('personal_info_policy.title11') }}</b>
                </p>
                <p>{{ $t('personal_info_policy.content11_1') }}</p>
                <p>{{ $t('personal_info_policy.content11_2') }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";

export default {
  name: "PersonalInfoPolicy",
  components: { Footer, HeaderNav }
};
</script>

<style scoped>

</style>
