import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Reservation from "@/views/Reservation.vue";
import Faq from "@/views/Faq.vue";
import CorporateIdentity from "@/views/CorporateIdentity.vue";
import Contact from "@/views/Contact.vue";
import Announcement from "@/views/Announcement.vue";
import Account from "@/views/Account.vue";
import About from "@/views/About.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import EmailVerification from "@/views/EmailVerification.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import PasswordRecover from "@/views/PasswordRecover.vue";
import ReservationDetail from "@/views/ReservationDetail.vue";
import TicketDetail from "@/views/TicketDetail.vue";
import PaymentApproved from "@/components/reservation/PaymentApproved.vue";
import PageNotFound from "@/views/404.vue";
import ServerError from "@/views/500.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import PersonalInfoPolicy from "@/views/PersonalInfoPolicy.vue";
import TermsOfUse from "@/views/TermsOfUse.vue";
import DistanceTicketSalesContract from "@/views/DistanceTicketSalesContract.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Anasayfa - Çamlıca Kulesi"
    }
  },
  {
    path: "/bilet-al",
    name: "Reservation",
    component: Reservation,
    meta: {
      title: "Bilet Al - Çamlıca Kulesi"
    }
  },
  {
    path: "/sss",
    name: "Faq",
    component: Faq,
    meta: {
      title: "SSS - Çamlıca Kulesi"
    }
  },
  {
    path: "/kurumsal-kimlik",
    name: "CorporateIdentity",
    component: CorporateIdentity,
    meta: {
      title: "Kurumsal Kimlik - Çamlıca Kulesi"
    }
  },
  {
    path: "/iletisim",
    name: "Contact",
    component: Contact,
    meta: {
      title: "İletişim - Çamlıca Kulesi"
    }
  },
  {
    path: "/duyurular",
    name: "Announcement",
    component: Announcement,
    meta: {
      title: "Duyurular - Çamlıca Kulesi"
    }
  },
  {
    path: "/hesabim",
    name: "Account",
    component: Account,
    meta: {
      title: "Hesabım - Çamlıca Kulesi"
    }
  },
  {
    path: "/kule-hakkinda",
    name: "About",
    component: About,
    meta: {
      title: "Kule Hakkında - Çamlıca Kulesi"
    }
  },
  {
    path: "/giris-yap",
    name: "Login",
    component: Login,
    meta: {
      title: "Giriş Yap - Çamlıca Kulesi"
    }
  },
  {
    path: "/uye-ol",
    name: "Register",
    component: Register,
    meta: {
      title: "Üye Ol - Çamlıca Kulesi"
    }
  },
  {
    path: "/hesabim/sifre-degistir",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      title: "Şifre Değiştir - Çamlıca Kulesi"
    }
  },
  {
    path: "/rezervasyon-detay/:uuid",
    name: "PaymentApproved",
    component: PaymentApproved,
    meta: {
      title: "Rezervasyon Detay - Çamlıca Kulesi"
    }
  },
  {
    path: "/r/:shortNumber",
    name: "ReservationDetail",
    component: ReservationDetail,
    meta: {
      title: "Rezervasyon Detay - Çamlıca Kulesi"
    }
  },
  {
    path: "/b/:shortNumber",
    name: "TicketDetail",
    component: TicketDetail,
    meta: {
      title: "Bilet Detay - Çamlıca Kulesi"
    }
  },
  {
    path: "/sifremi-unuttum",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Şifremi Unuttum - Çamlıca Kulesi"
    }
  },
  {
    path: "/sifre-sifirla/:uuid/:token",
    name: "PasswordRecover",
    component: PasswordRecover,
    meta: {
      title: "Şifre Sıfırla - Çamlıca Kulesi"
    }
  },
  {
    path: "/eposta-dogrula/:uuid/:token",
    name: "EmailVerification",
    component: EmailVerification,
    meta: {
      title: "Eposta Doğrula - Çamlıca Kulesi"
    }
  },
  {
    path: "/site-kullanim-kosullari",
    name: "TermsOfUse",
    component: TermsOfUse,
    meta: {
      title: "Site Kullanım Koşulları - Çamlıca Kulesi"
    }
  },
  {
    path: "/gizlilik-ve-veri-guvenligi-politikasi",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "Gizlilik ve Veri Güvenliği Politikası - Çamlıca Kulesi"
    }
  },
  {
    path: "/kisisel-verilerin-korunmasi-ve-isletilmesi-politikasi",
    name: "PersonalInfoPolicy",
    component: PersonalInfoPolicy,
    meta: {
      title: "Kişisel Verilerin Korunması ve İşletilmesi Politikası - Çamlıca Kulesi"
    }
  },
  {
    path: "/mesafeli-bilet-satis-sozlesmesi",
    name: "DistanceTicketSalesContract",
    component: DistanceTicketSalesContract,
    meta: {
      title: "Mesafeli Bilet Satış Sözleşmesi - Çamlıca Kulesi"
    }
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      title: "Sayfa Bulunamadı - Çamlıca Kulesi"
    }
  },
  {
    path: "/500",
    name: "Server Error",
    component: ServerError
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
