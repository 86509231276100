<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="account hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('login.forgot_password') }}</div>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 col-md-6 no-gutter-xs">
            <MessageBar></MessageBar>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 col-md-6 no-gutter-xs">
            <div class="bg-white">
              <div class="section-description">
                <p>{{ $t('account.forgot_password_desc') }}</p>
              </div>
              <form data-vv-scope="forgot-password">
                <div class="material-form-group">
                  <input v-model="email" v-validate="{ required: true }"
                         name="Email"
                         type="email" placeholder=" " />
                  <label>Email</label>
                  <span v-show="errors.has('forgot-password.Email')" class="error-message">
                  {{ errors.first("forgot-password.Email") }}
                </span>
                </div>
                <div @click="sendRecoverEmail" class="button filled green b">
                  <vue-element-loading
                    :active="isLoading"
                    background-color="rgba(255, 255, 255, 0.8)"
                    color="#00baab"
                    spinner="spinner"
                  />
                  {{ $t('account.send') }}
                </div>
              </form>
            </div>
            <div class="bg-white">
              <div class="box-header">{{ $t('account.remember_password') }}</div>
              <div @click="$router.push('giris-yap')" class="button solid mid g">
                {{ $t('header.login') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/main/Footer.vue";
import HeaderNav from "@/components/main/HeaderNav.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import CustomerService from "@/services/customer/customer-service";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import MessageBar from "@/components/main/MessageBar.vue";
import { mapActions } from "vuex";

export default {
  name: "ForgotPassword",
  components: {
    MessageBar, BackgroundMovement, HeaderNav, Footer
  },
  beforeCreate() {
    if (typeof this.$session.get("cusdet") !== "undefined") {
      this.$router.push("/");
    }
  },
  data() {
    return {
      email: "",
      isLoading: false
    };
  },
  methods: {
    ...mapActions(["hideMessageBar", "showMessageBar"]),
    sendRecoverEmail() {
      this.isLoading = true;

      this.$validator.validateAll("forgot-password").then((result) => {
        if (result) {
          const hashedEmail = Base64.stringify(Utf8.parse(this.email));
          const data = CustomerService.sendForgotPasswordEmail(hashedEmail);

          data.then((res) => {
            if (res.status === 200) {
              this.showMessageBar({
                message: `${this.$t("generic.successful")}(${res.data})`,
                type: "success"
              });
            } else {
              this.showMessageBar({
                message: `${this.$t("generic.unexpected_error")}(${res.message})`,
                type: "error"
              });
            }

            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped></style>
