import HttpService from "@/services/http-service";

const ticketService = {
  getAvailableTimes(payload) {
    return HttpService.get(`${process.env.VUE_APP_API_URL}/reservation/available-reservation-times`, payload);
  },
  getTicketTypes(payload) {
    return HttpService.get(`${process.env.VUE_APP_API_URL}/reservation/ticket-types`, payload);
  },
  getAdditionalServices(payload) {
    return HttpService.get(`${process.env.VUE_APP_API_URL}/reservation/additional-services`, payload);
  },
  getGroupedAdditionalServiceIds() {
    return HttpService.get(`${process.env.VUE_APP_API_URL}/reservation/grouped-additional-service-ids`, {});
  },
  blockReservation(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/reservation/save-blocked-reservation`, payload);
  },
  deleteBlockedReservation(token, payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/reservation/delete-blocked-reservation/${token}`, payload);
  },
  purchaseReservation(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/reservation/purchase-reservation/intent`, payload);
  },
  getBlockDetail(token, payload) {
    return HttpService.get(`${process.env.VUE_APP_API_URL}/reservation/detail-blocked-reservation/${token}`, payload);
  },
  saveReservation(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/reservation/save-reservation`, payload);
  },
  updateBlockedReservationItem(payload, step) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/reservation/update-blocked-reservation-item/step-${step}`, payload);
  },
  calculateReservationPrice(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/reservation/preview-reservation`, payload);
  },
  getReservationDetailByUuid(uuid) {
    return HttpService.get(`${process.env.VUE_APP_API_URL}/reservation/detail-reservation-by-uuid/${uuid}`, {});
  },
  getReservationDetailByShortNumber(shortNumber) {
    return HttpService.get(`${process.env.VUE_APP_API_URL}/reservation/detail-reservation-by-short-number/${shortNumber}`, {});
  },
  getTicketDetailByShortNumber(shortNumber) {
    return HttpService.get(`${process.env.VUE_APP_API_URL}/reservation/detail-ticket-by-short-number/${shortNumber}`, {});
  },
  submitTicketSurvey(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/customer/survey/ticket`, payload);
  },
  checkCardBin(payload) {
    return HttpService.post(`${process.env.VUE_APP_API_URL}/reservation/bin-check`, payload);
  }
};

export default ticketService;
