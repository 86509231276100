<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="announcements hero">
      <div class="container">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t("header.announcement") }}</div>
          </div>
        </div>
        <div class="row center-xs">
          <!--<div class="col-xs-12 col-md-3">
            <ul class="sidebar-menu">
              <span>Güncel Duyurular</span>
              <li class="active">Ağustos 2020</li>
              <li>Temmuz 2020</li>
              <li>Haziran 2020</li>
              <li>Mayıs 2020</li>
              <li>Nisan 2020</li>
              <li>Mart 2020</li>
            </ul>
            <ul class="sidebar-menu">
              <span>Geçmiş Duyurular</span>
              <li>Ağustos 2020</li>
              <li>Temmuz 2020</li>
              <li>Haziran 2020</li>
              <li>Mayıs 2020</li>
              <li>Nisan 2020</li>
              <li>Mart 2020</li>
            </ul>
          </div>-->
          <div class="col-xs-12 col-md-9">
            <div
              class="horizontal-card"
              v-for="(announcement, index) in announcements"
              v-bind:key="index"
            >
              <div class="card-thumbnail">
                <img :src="appContentStorageUrl + announcement.image" alt="" />
              </div>
              <div class="card-content text-align-left">
                <div class="box-header">
                  {{ announcement.title }}
                </div>
                <div class="card-description" v-html="'<p>' + announcement.spot + '</p>'"></div>
                <!--<a href="#" class="show-more">
                  Detayları Gör <img src="@/assets/images/icons/right-arrow-green.svg" alt="">
                </a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import AnnouncementService from "@/services/content/announcement-service";

export default {
  name: "Announcement",
  components: { BackgroundMovement, Footer, HeaderNav },
  data() {
    return {
      lang: "tr",
      announcements: {},
      appContentStorageUrl: process.env.VUE_APP_CONTENT_STORAGE_URL
    };
  },
  async created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
    await this.getAllRecords();
  },
  methods: {
    async getAllRecords() {
      const data = AnnouncementService.getAllRecords(this.lang);
      await data.then((res) => {
        if (res.status === 200) {
          this.announcements = res.data.content;
        } else {
          this.$router.push("/404");
        }
      });
    }
  }
};
</script>

<style scoped></style>
