<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="account hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header RESERVATIONDETAIL">
              {{ $t('account.reservation_detail') }}
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs bg-white margin-auto">
            <vue-element-loading
              :active="isLoading"
              background-color="rgba(255, 255, 255, 0.8)"
              color="#00baab"
              spinner="spinner"
            />
          </div>
        </div>
        <div
          v-if="!isLoading && typeof reservation.tickets === 'undefined'"
          class="row middle-xs between-xs bg-white margin-auto"
        >
          <div class="col-xs-12 center-xs no-gutter-xs">
            <div class="section-description">
              <p>{{ $t('account.ticket_not_found') }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="typeof reservation.tickets !== 'undefined'"
          class="row between-xs middle-xs summary-content bg-white margin-auto"
        >
          <div class="col-xs-12 col-md-12 no-gutter-xs">
            <div class="row between-xs">
              <div class="col-xs-12 col-md-3 center-xs start-md">
                <div class="section-description">
                  {{ $t('account.reservation_no') }}: {{ reservation.shortNumber }}
                </div>
              </div>
              <div class="col-xs-12 col-md-4 center-xs">
                <div class="section-description">
                  {{ reservation.tickets[0].estimatedVisitDate }} -
                  {{ reservation.tickets[0].estimatedVisitTime }}
                </div>
                <div class="section-silent">
                  {{ reservation.ticketCount }} {{ $t('account.number_of_ticket') }}
                </div>
              </div>
              <div class="col-xs-12 col-md-3 center-xs end-md v-hr">
                <div class="total">{{ currencyFormat(reservation.totalSalePrice) }} TL</div>
                <div class="section-silent">{{ $t('account.credit_card') }}</div>
              </div>
            </div>
            <hr />
            <details
              v-bind:key="index2"
              v-for="(ticket, index2) in reservation.tickets"
              class="row summary-items summary-items--type2 "
            >
              <summary class="between-xs middle-xs">
                <div class="col-xs-12 col-md-3 center-xs start-md">
                  <div class="summary-text">
                    {{ ticket.type.name }} {{ $t('reservation.ticket') }}
                  </div>
                  <div
                    v-for="(additionalData, index3) in ticket.additionalServices"
                    v-bind:key="index3"
                    class="summary-silent-text"
                  >
                    {{ additionalData.name }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-3 center-xs end-md v-hr">
                  <div class="summary-text">{{ currencyFormat(ticket.totalSalePrice) }} TL</div>
                </div>
              </summary>
              <div class="col-xs-12 center-xs">
                <qrcode :value="ticket.barcode" :options="{ width: 120 }"></qrcode>
              </div>
              <div class="col-xs-12 center-xs">
                <a
                  v-bind:href="`${apiUrl}/ticket/download-passbook/${ticket.uuid}`"
                  class="button filled green w"
                >
                  <img src="@/assets/images/icons/wallet.svg" alt="" />
                  {{ $t('account.save_wallet') }}
                </a>
              </div>
            </details>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import TicketService from "@/services/reservation/ticket-service";

export default {
  name: "ReservationDetail",
  components: { Footer, HeaderNav },
  data() {
    return {
      isLoading: false,
      apiUrl: process.env.VUE_APP_API_URL,
      reservation: {},
      shortNumber: this.$route.params.shortNumber
    };
  },
  async created() {
    await this.getReservationDetailByShortNumber();
  },
  methods: {
    async getReservationDetailByShortNumber() {
      this.isLoading = true;

      const data = TicketService.getReservationDetailByShortNumber(this.shortNumber);

      await data.then((res) => {
        if (res.status === 200) {
          if (
            this.detectMobile() && res.data.channel.code === "MOBILE"
            && window.location.hostname !== process.env.VUE_APP_OPEN_URL.split("://").pop()
          ) {
            window.location = process.env.VUE_APP_OPEN_URL + window.location.pathname;
          }
          this.reservation = res.data;
        } else {
          this.$router.push("/404");
        }

        this.isLoading = false;
      });
    }
  }
};
</script>
