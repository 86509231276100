<template>
  <header id="nav" :class="{ scroll: scrollPosition > 100 }">
    <div class="container">
      <div class="row bottom-xs">
        <div class="col-md-1">
          <a href="/">
            <img
              class="brand-logo"
              src="@/assets/images/camlica-tower-brand-logo-white.svg"
              alt="Camlica Tower Brand Logo.svg"
            />
          </a>
          <div class="menu-opener" @click="menuOpen"></div>
        </div>
        <div class="col-md-11">
          <ul class="menu">
            <li><a href="/kule-hakkinda">{{ $t('header.about_us') }}</a></li>
            <li><a href="/bilet-al">{{ $t('header.reservation') }}</a></li>
            <li><a href="/sss">{{ $t('header.faq') }}</a></li>
            <li><a href="/duyurular">{{ $t('header.announcement') }}</a></li>
            <li><a href="/iletisim">{{ $t('header.contact') }}</a></li>
            <li v-if="!customerLoggedIn">
              <a href="/uye-ol" class="login-button">
                {{ $t('header.signup') }}
              </a>
            </li>
            <li v-if="!customerLoggedIn">
              <a href="/giris-yap" class="login-button">
                {{ $t('header.login') }}
              </a>
            </li>
            <li v-if="customerLoggedIn">
              <a href="/hesabim" class="login-button logged">
                <span class="button-icon">
                  <img src="@/assets/images/icons/user.svg" alt="" />
                </span>
                {{ $t('header.my_account') }}
              </a>
            </li>
            <li>
              <a href="#" @click="changeLanguage('tr')">
                TR
              </a>
            </li>
            <li>
              <a href="#" @click="changeLanguage('en')">
                EN
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderNav",
  data() {
    return {
      lang: "tr",
      scrollPosition: null
    };
  },
  computed: {
    ...mapState(["customerLoggedIn"])
  },
  created() {
    if (typeof this.$route.query.lang !== "undefined") {
      localStorage.setItem("kulewslang", this.$route.query.lang);
      this.$i18n.locale = this.$route.query.lang;
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      localStorage.setItem("kulewslang", this.$i18n.locale);
      this.lang = this.$i18n.locale;
    } else {
      this.$i18n.locale = localStorage.getItem("kulewslang");
      this.lang = localStorage.getItem("kulewslang");
    }
  },
  methods: {
    changeLanguage(code) {
      this.$i18n.locale = code;
      localStorage.setItem("kulewslang", code);
      this.$router.go(0);
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    menuOpen() {
      const element = document.getElementById("nav");

      if (element.classList) {
        element.classList.toggle("open");
      } else {
        const classes = element.className.split(" ");
        const i = classes.indexOf("open");

        if (i >= 0) {
          classes.splice(i, 1);
        } else {
          classes.push("open");
        }
        element.className = classes.join(" ");
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  }
};
</script>

<style scoped>
.lang-icon {
  height: 28px;
  width: 28px;
}
.lang-icon.en {
  width: 24px;
  height: 24px;
}
</style>
