<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="distance-ticket-sales-contract hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('distance_ticket_sales_contact.maintitle') }}</div>
          </div>
        </div>
        <div class="row distance-ticket-sales-contract center-xs">
          <div class="col-xs-12 col-md-12 no-gutter-xs">
            <div class="content">
              <div class="content-body">
                <p>{{ $t('distance_ticket_sales_contact.content1_1') }}
                  <b>“camlicakule.istanbul”</b>{{ $t('distance_ticket_sales_contact.content1_2') }}
                </p>
                <p>{{ $t('distance_ticket_sales_contact.content1_3') }}
                  <ul>
                    <li>{{ $t('distance_ticket_sales_contact.content1_4') }}</li>
                    <li>{{ $t('distance_ticket_sales_contact.content1_5') }}</li>
                    <li>{{ $t('distance_ticket_sales_contact.content1_6') }}</li>
                    <li>{{ $t('distance_ticket_sales_contact.content1_7') }}</li>
                    <li>{{ $t('distance_ticket_sales_contact.content1_8') }}</li>
                  </ul>
                </p>
                <p>{{ $t('distance_ticket_sales_contact.content1_9') }}</p>
                <p class="center">
                  <b>{{ $t('distance_ticket_sales_contact.title') }}</b>
                </p>
                <ol>
                  <li><b>{{ $t('distance_ticket_sales_contact.title_1') }}</b><br>
                    {{ $t('distance_ticket_sales_contact.title_1_con1') }}
                    <b>“camlicakule.istanbul”</b>
                    {{ $t('distance_ticket_sales_contact.title_1_con2') }}
                  </li>
                  <br>
                  <li><b>{{ $t('distance_ticket_sales_contact.title_2') }}</b><br>
                  {{ $t('distance_ticket_sales_contact.title_2_con1') }}
                    <b>“camlicakule.istanbul”</b>
                    {{ $t('distance_ticket_sales_contact.title_2_con2') }}
                  </li>
                  <br>
                  <li><b>{{ $t('distance_ticket_sales_contact.title_3') }}</b>
                  <br>{{ $t('distance_ticket_sales_contact.title_3_con1') }}
                  <b>“camlicakule.istanbul”</b>
                  {{ $t('distance_ticket_sales_contact.title_3_con2') }}
                  </li>
                  <br>
                  <li><b>{{ $t('distance_ticket_sales_contact.title_4') }}</b><br>
                    <ol>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con1') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con2_1') }}
                        <b>“camlicakule.istanbul”</b>
                        {{ $t('distance_ticket_sales_contact.title_4_con2_2') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con3') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con4_1') }}
                        <b>“camlicakule.istanbul”</b>
                        {{ $t('distance_ticket_sales_contact.title_4_con4_2') }}
                      </li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con5') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con6') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con7') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con8') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con9') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con10') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con11') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con12') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con13') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con14') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con15') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con16') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con17') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_4_con18') }}</li>
                    </ol>
                  </li>
                  <br>
                  <li>
                    <b>{{ $t('distance_ticket_sales_contact.title_5') }}</b><br>
                    <ol>
                      <li>{{ $t('distance_ticket_sales_contact.title_5_con1') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_5_con2_1') }}
                        <b>“camlicakule.istanbul”</b>
                        {{ $t('distance_ticket_sales_contact.title_5_con2_2') }}</li>
                      <li>{{ $t('distance_ticket_sales_contact.title_5_con3') }}</li>
                    </ol>
                  </li>
                  <br>
                  <li>
                   <b>{{ $t('distance_ticket_sales_contact.title_6') }}</b><br>
                    {{ $t('distance_ticket_sales_contact.title_6_con1') }}
                  </li>
                  <br>
                  <li>
                    <b>{{ $t('distance_ticket_sales_contact.title_7') }}</b><br>
                      <p>{{ $t('distance_ticket_sales_contact.title_7_con1') }}</p>
                      <p>{{ $t('distance_ticket_sales_contact.title_7_con2') }}</p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";

export default {
  name: "DistanceTicketSalesContract",
  components: { Footer, HeaderNav }
};
</script>

<style scoped></style>
