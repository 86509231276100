var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 no-gutter-xs"},[_c('div',{staticClass:"step-numbers",class:{ approved: _vm.paymentApproved }},[(_vm.paymentApproved)?_c('div',{staticClass:"header-icon"},[_c('img',{attrs:{"src":require("@/assets/images/icons/check-mark-white.svg"),"alt":""}})]):_vm._e(),(_vm.paymentApproved)?_c('div',{staticClass:"approve-text"},[_vm._v(" "+_vm._s(_vm.$t("step_nav.reservation_approved"))+"! ")]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md left-xs middle-xs flex",class:{
            active: _vm.activeStep === 1,
            checked: _vm.completedSteps.indexOf(_vm.stepMapping[1]) >= 0
          }},[_vm._m(0),_c('div',{staticClass:"step-text"},[_vm._v(_vm._s(_vm.$t("step_nav.datetime")))])]),_c('div',{staticClass:"col-md left-xs middle-xs flex",class:{
            active: _vm.activeStep === 2,
            checked: _vm.completedSteps.indexOf(_vm.stepMapping[2]) >= 0
          }},[_vm._m(1),_c('div',{staticClass:"step-text"},[_vm._v(_vm._s(_vm.$t("step_nav.visitor_info")))])]),_c('div',{staticClass:"col-md left-xs middle-xs flex",class:{
            active: _vm.activeStep === 3,
            checked: _vm.completedSteps.indexOf(_vm.stepMapping[3]) >= 0
          }},[_vm._m(2),_c('div',{staticClass:"step-text"},[_vm._v(_vm._s(_vm.$t("step_nav.additional_services")))])]),_c('div',{staticClass:"col-md left-xs middle-xs flex",class:{
            active: _vm.activeStep === 4,
            checked: _vm.completedSteps.indexOf(_vm.stepMapping[4]) >= 0
          }},[_vm._m(3),_c('div',{staticClass:"step-text"},[_vm._v(_vm._s(_vm.$t("step_nav.verification")))])]),_c('div',{staticClass:"col-md left-xs middle-xs flex",class:{
            active: _vm.activeStep === 5,
            checked: _vm.completedSteps.indexOf(_vm.stepMapping[5]) >= 0
          }},[_vm._m(4),_c('div',{staticClass:"step-text"},[_vm._v(_vm._s(_vm.$t("step_nav.payment")))])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button solid mid w"},[_c('span',[_vm._v("1")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button solid mid w"},[_c('span',[_vm._v("2")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button solid mid w"},[_c('span',[_vm._v("3")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button solid mid w"},[_c('span',[_vm._v("4")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button solid mid w"},[_c('span',[_vm._v("5")])])}]

export { render, staticRenderFns }