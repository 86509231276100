<template>
  <div class="bg-white tab-data-time">
    <div class="row">
      <div class="col-xs-12 col-lg-6 left-box">
        <div class="box-header">
          {{ $t('reservation.date_pick') }}
        </div>
        <div class="calendar">
          <table summary="calendar">
            <caption>
              <div class="div">
                <a @click.prevent="selectPreviousMonth" href="#" rel="prev">&lt;</a>
                {{ localMonthNameMapping[currentDate.current_month] }}
                {{ currentDate.current_year }}
                <a @click.prevent="selectNextMonth" href="#" rel="next">&gt;</a>
              </div>
            </caption>
            <thead>
            <tr>
              <th scope="col">{{ $t('reservation.monday_short') }}</th>
              <th scope="col">{{ $t('reservation.tuesday_short') }}</th>
              <th scope="col">{{ $t('reservation.wednesday_short') }}</th>
              <th scope="col">{{ $t('reservation.thursday_short') }}</th>
              <th scope="col">{{ $t('reservation.friday_short') }}</th>
              <th scope="col">{{ $t('reservation.saturday_short') }}</th>
              <th scope="col">{{ $t('reservation.sunday_short') }}</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(weeks, weekIndex) in calendarData" v-bind:key="weekIndex">
                <td v-for="(day, index) in weeks" v-bind:key="index">
                  <a href="#" v-on:click.prevent="changeTicketDate(day)" :class="{
                    selected: day.toString() === stepData.selected_date.toString(),
                    null: day.toString().length <= 0,
                    'past-day': day < currentDate.today || !isAvailableForBuying(day),
                  }">
                    {{ day | moment('D') }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6 right-box relative time-selection">
        <div class="box-header">{{ $t('reservation.time_pick') }}</div>
        <vue-element-loading :active="isDataLoading" background-color="rgba(255, 255, 255, 1)"
                             color="#00baab" spinner="spinner"/>
        <div v-if="errorMessage.length > 0" class="section-description">
          <p>{{ errorMessage }}</p>
        </div>

        <div :class="{ 'errortr': timeSelectionError }"
             v-if="availableTimeList.length > 0 && lang=='tr'" class="time-picker">
          <div class="selected-time" v-if="lang=='tr'">
            {{ stepData.selected_date | dateFormat('DD MMMM YYYY', dateFormatConfigTr) }}
          </div>
          <div v-for="(item, index) in availableTimeList"
               v-bind:key="index"
               class="material-radio-group"
               :class="{disabled:
               item.status === 'EXPIRED' ||
               item.status === 'UNAVAILABLE' ||
               item.status === 'CLOSED'}"
          >
            <input :disabled="
                      item.status === 'EXPIRED' ||
                      item.status === 'UNAVAILABLE' ||
                      item.status === 'CLOSED'"
                   v-model="stepData.selected_time"
                   @click="setSelectedTimeDescription(index)"
                   type="radio" :id="`time-${index}`" name="ticket-type" :value="item.time.id">
            <label :for="`time-${index}`">{{ item.time.interval }}</label>
          </div>
        </div>
        <div :class="{ 'erroren': timeSelectionError }"
             v-if="availableTimeList.length > 0 && lang=='en'" class="time-picker">
          <div class="selected-time" v-if="lang=='en'">
            {{ stepData.selected_date | dateFormat('DD MMMM YYYY', dateFormatConfigEn) }}
          </div>
          <div v-for="(item, index) in availableTimeList"
               v-bind:key="index"
               class="material-radio-group"
               :class="{disabled:
               item.status === 'EXPIRED' ||
               item.status === 'UNAVAILABLE' ||
               item.status === 'CLOSED'}"
          >
            <input :disabled="
                      item.status === 'EXPIRED' ||
                      item.status === 'UNAVAILABLE' ||
                      item.status === 'CLOSED'"
                   v-model="stepData.selected_time"
                   @click="setSelectedTimeDescription(index)"
                   type="radio" :id="`time-${index}`" name="ticket-type" :value="item.time.id">
            <label :for="`time-${index}`">{{ item.time.interval }}</label>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-lg-6 left-box">
        <div class="box-header">
          {{ $t('reservation.guest_count') }}
        </div>
        <div class="visitor-numbers">
          <div @click="decreaseTicket" class="remove black">
            <img src="@/assets/images/icons/minus.svg" alt="">
          </div>
          <div class="total">
            <strong>{{ stepData.ticket_count }}</strong> {{ $t('reservation.person') }}
          </div>
          <div @click="increaseTicket" class="add black">
            <img src="@/assets/images/icons/plus.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketService from "@/services/reservation/ticket-service";
import { mapActions } from "vuex";

export default {
  name: "DateTimeSelection",
  props: ["validateStepData", "reservationData"],
  data() {
    return {
      lang: "tr",
      isDataLoading: false,
      timeSelectionError: false,
      ticketCount: 1,
      ticketLimit: 9,
      availableTimeList: {},
      currentDate: this.getCurrentDate(),
      calendarData: {},
      errorMessage: "",
      stepData: {
        ticket_count: 1,
        selected_time: 0,
        selected_date: null,
        time_description: "",
        buy_additional_services: []
      },
      dateFormatConfigEn: {
        dayOfWeekNames: [
          "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday",
          "Friday", "Saturday"
        ],
        dayOfWeekNamesShort: [
          "Su", "Mo", "Tu", "We", "Tr", "Fr", "Sa"
        ],
        monthNames: [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ],
        monthNamesShort: [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ]
      },
      dateFormatConfigTr: {
        dayOfWeekNames: [
          "Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe",
          "Cuma", "Cumartesi"
        ],
        dayOfWeekNamesShort: [
          "Pa", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"
        ],
        monthNames: [
          "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
          "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ],
        monthNamesShort: [
          "Oca", "Şub", "Mar", "Nis", "May", "Haz",
          "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"
        ]
      }
    };
  },
  async created() {
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
    await this.prepareCalendar();

    if (typeof this.$route.query.ek_hizmet !== "undefined") {
      this.stepData.buy_additional_services = this.$route.query.ek_hizmet.split(",");
      this.stepData.buy_additional_services.forEach((item, i) => { this.stepData.buy_additional_services[i] = item.replace(/_/g, " "); });
    }
  },
  watch: {
    validateStepData: {
      handler(newVal) {
        if (newVal) {
          this.validateStep();
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "changeLoaderStatus",
      "changePopupErrorStatus",
      "changeErrorMessage",
      "showMessageBar"
    ]),
    validateStep() {
      if (
        this.stepData.ticket_count > 0
        && this.stepData.selected_time > 0
        && this.stepData.selected_date !== null
      ) {
        this.blockReservation();
      } else {
        this.$emit("validation-failed");
        this.timeSelectionError = true;
      }
    },
    getDayDifference(d1) {
      const today = new Date();
      const t2 = today.getTime();
      const t1 = d1.getTime();

      return parseInt((t1 - t2) / (24 * 3600 * 1000), 10);
    },
    selectPreviousMonth() {
      const newDate = this.stepData.selected_date;
      newDate.setMonth(this.stepData.selected_date.getMonth() - 1);

      if (newDate.getMonth() === this.currentDate.today.getMonth()) {
        newDate.setDate(this.currentDate.today.getDate());
      }

      this.currentDate.current_month = newDate.getMonth();
      this.currentDate.current_year = newDate.getFullYear();
      this.prepareCalendar(newDate);
    },
    selectNextMonth() {
      const newDate = this.stepData.selected_date;
      newDate.setDate(1);
      newDate.setMonth(this.stepData.selected_date.getMonth() + 1);

      if (newDate.getMonth() === this.currentDate.today.getMonth()) {
        newDate.setDate(this.currentDate.today.getDate());
      }

      this.currentDate.current_month = newDate.getMonth();
      this.currentDate.current_year = newDate.getFullYear();
      this.prepareCalendar(newDate);
    },
    async prepareCalendar(newDate = null) {
      this.calendarData = this.getCalendarObject(
        this.currentDate.current_month,
        this.currentDate.current_year
      );

      if (newDate !== null) {
        this.stepData.selected_date = newDate;
      } else {
        this.stepData.selected_date = this.currentDate.selected_date;
      }

      if (typeof this.reservationData.DateTimeSelection !== "undefined") {
        this.stepData = this.reservationData.DateTimeSelection;
      }

      await this.getAvailableTimes();
    },
    setSelectedTimeDescription(index) {
      this.timeSelectionError = false;
      this.stepData.time_description = this.availableTimeList[index].time.interval;
      this.$emit("time-selected", this.stepData);
    },
    increaseTicket() {
      if (this.stepData.ticket_count < this.ticketLimit) {
        this.stepData.ticket_count += 1;
        this.$emit("ticket-count-changed", this.stepData);
      }
    },
    changeTicketDate(date) {
      if (date < this.currentDate.today || !this.isAvailableForBuying(date)) {
        return;
      }
      this.scrollToItem(".time-selection");
      this.stepData.selected_date = date;
      this.stepData.selected_time = 0;
      this.getAvailableTimes();
    },
    blockReservation() {
      this.changeLoaderStatus(true);

      const requestData = {
        date: this.getFormattedDate(this.stepData.selected_date),
        ticketCount: this.stepData.ticket_count,
        regularReservationTimeId: this.stepData.selected_time
      };

      if (typeof this.$session.get("restoken") !== "undefined") {
        requestData.token = this.$session.get("restoken");
      }

      const data = TicketService.blockReservation(requestData);

      data.then((res) => {
        if (res.status === 200) {
          if (
            typeof this.$session.get("restoken") !== "undefined"
            && res.data.token !== this.$session.get("restoken")
          ) {
            this.$emit("reset-timer");
          }

          this.$session.start();
          this.$session.set("restoken", res.data.token);

          this.$emit("step-completed", this.stepData, 1);
        } else if (res.message === "Geçersiz bir token ile işlem yapamazsınız, lütfen geçerli bir token edinin!") {
          this.$emit("reservation-time-out");
        } else {
          this.$emit("validation-failed");
          this.changeErrorMessage({
            title: "Hata",
            message: res.message,
            type: "validation"
          });
          this.changePopupErrorStatus(true);
        }

        this.changeLoaderStatus(false);
      });
    },
    async getAvailableTimes() {
      this.timeSelectionError = false;
      this.isDataLoading = true;
      this.errorMessage = "";
      this.availableTimeList = {};
      const formattedDate = this.getFormattedDate(this.stepData.selected_date);

      const data = TicketService.getAvailableTimes({ date: formattedDate });

      await data.then((res) => {
        if (res.status === 200) {
          this.availableTimeList = res.data;
        } else {
          let customMessage = res.message;
          if (res.message === "Geçersiz bir hash gönderdiniz, lütfen geçerli bir hash değeri gönderin!") {
            customMessage = "Bilgisayarınızın tarih ve saati yanlış olduğundan bilet sistemine bir bağlantı kurulamıyor, lütfen güncel tarih ve saat kullanınız.";
          }
          this.changeErrorMessage({
            title: "Hata",
            message: customMessage,
            type: "validation"
          });
          this.changePopupErrorStatus(true);
        }

        this.isDataLoading = false;
      });
    },
    isAvailableForBuying(calendarDate) {
      return this.getDayDifference(calendarDate) < 3;
    },
    decreaseTicket() {
      if (this.stepData.ticket_count > 1) {
        this.stepData.ticket_count -= 1;
        this.$emit("ticket-count-changed", this.stepData);
      }
    }
  }
};
</script>

<style scoped>
</style>
