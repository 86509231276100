<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="booking hero">
      <div v-if="typeof reservation.tickets !== 'undefined'" class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header PAYMENTAPPROVED">
              {{ $t("payment_approved.reservation_detail") }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 no-gutter-xs">
            <div class="step-numbers approved">
              <div class="header-icon">
                <img src="@/assets/images/icons/check-mark-white.svg" alt="" />
              </div>
              <div class="approve-text">{{ $t("payment_approved.reservation_approved") }}!</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 no-gutter-xs">
            <div class="bg-white tab-approved">
              <SharePopup
                v-show="showSharePopup"
                @close-popup="closeSharePopup"
                v-bind:shareType="shareType"
                v-bind:uuid="shareUuid"
              ></SharePopup>
              <div class="row between-xs middle-xs summary-content">
                <div class="col-xs-12">
                  <div>
                    <div class="row between-xs">
                      <div class="col-xs-12 col-lg-3 center-xs start-lg">
                        <div class="section-description">
                          {{ $t("payment_approved.reservation_no") }}: {{ reservation.shortNumber }}
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-4 center-xs start-lg">
                        <div class="section-description">
                          {{ reservation.tickets[0].estimatedVisitDate }} -
                          {{ reservation.tickets[0].estimatedVisitTime }}
                        </div>
                        <div class="section-silent">
                          {{ reservation.tickets.length }} Adet Bilet
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-3 center-xs end-lg v-hr">
                        <div class="total">{{ currencyFormat(reservation.totalSalePrice) }} TL</div>
                        <div class="section-silent">Kredi Kartı</div>
                      </div>
                    </div>

                    <hr />
                    <div
                      v-bind:key="index"
                      v-for="(ticket, index) in reservation.tickets"
                      class="summary-items row between-xs summary-items--type4"
                    >
                      <div class="col-xs-12 col-lg-3 center-xs start-lg">
                        <div class="summary-text">
                          {{ ticket.type.name }} {{ $t("payment_approved.ticket") }}
                        </div>
                        <div
                          v-for="(additionalData, index2) in ticket.additionalServices"
                          v-bind:key="index2"
                          class="summary-silent-text "
                        >
                          {{ additionalData.name }}
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-4 center-xs start-lg">
                        <a :href="`/b/${ticket.shortNumber}`" class="summary-action">{{
                          $t("payment_approved.see_ticket")
                        }}</a>
                        <div @click="shareTicket(ticket.uuid)" class="summary-action">
                          {{ $t("payment_approved.share") }}
                        </div>
                      </div>
                      <div class="col-xs-12 col-lg-3 center-xs end-lg v-hr">
                        <div class="summary-text">
                          {{ currencyFormat(ticket.totalSalePrice) }} TL
                        </div>
                      </div>
                    </div>
                    <div class="row summary-actions between-xs">
                      <div class="col-xs-11 col-md-6">
                        <div
                          class="button solid mid g"
                          @click="$router.push(`/r/${reservation.shortNumber}`)"
                        >
                          {{ $t("payment_approved.see_all_tickets") }}
                        </div>
                      </div>
                      <div class="col-xs-11 col-md-6">
                        <div
                          @click="shareReservation(reservation.uuid)"
                          class="button filled green b"
                        >
                          {{ $t("payment_approved.share_all_tickets") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import TicketService from "@/services/reservation/ticket-service";
import Footer from "@/components/main/Footer.vue";
import SharePopup from "@/components/main/SharePopup.vue";
import CustomerService from "@/services/customer/customer-service";
import { mapActions, mapState } from "vuex";

export default {
  name: "PaymentApproved",
  components: { SharePopup, Footer, HeaderNav },
  data() {
    return {
      isLoading: false,
      reservation: {},
      uuid: this.$route.params.uuid,
      showSharePopup: false,
      shareType: "",
      shareUuid: ""
    };
  },
  async created() {
    await this.getReservationDetail();

    if (this.customerLoggedIn) {
      this.getUserInformation();
    }
  },
  computed: {
    ...mapState(["customerLoggedIn"])
  },
  methods: {
    ...mapActions(["customerLoggedOut"]),
    closeSharePopup() {
      this.showSharePopup = false;
      this.shareType = "";
      this.shareUuid = "";
    },
    shareTicket(uuid) {
      this.shareUuid = uuid;
      this.shareType = "ticket";
      this.showSharePopup = true;
    },
    shareReservation(uuid) {
      this.shareUuid = uuid;
      this.shareType = "reservation";
      this.showSharePopup = true;
    },
    async getReservationDetail() {
      this.isLoading = true;

      const data = TicketService.getReservationDetailByUuid(this.uuid);

      await data.then((res) => {
        if (res.status === 200) {
          this.reservation = res.data;
          this.$session.remove("restoken");
          this.$session.remove("resdet");
          this.$session.remove("resinfo");
          this.$session.remove("ressummary");
        }

        this.isLoading = false;
      });
    },
    getUserInformation() {
      const data = CustomerService.getInformation();

      data.then((res) => {
        if (res.status === 200) {
          this.$session.set("cusdet", this.decorateCustomerDetail(res.data));
        }
      });
    }
  }
};
</script>

<style scoped></style>
