<template>
  <section class="cta bg-green">
      <div class="section-mini-header">{{ $t('homepage.buy_ticket_title') }}</div>
      <div class="section-description">{{ $t('homepage.buy_ticket_desc') }}</div>
      <a href="/bilet-al" class="button filled black w">
        {{ $t('homepage.buy_ticket') }}
      </a>
    </section>
</template>

<script>
export default {
  name: "BuyTicketComponent"
};
</script>

<style scoped></style>
