<template>
  <section class="homepage-featured">
    <div class="container">
      <div class="row bottom-lg">
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-5">
          <img class="featured-image" v-bind:src="explore.image" />
        </div>
        <div class="col-xs-12 col-sm-10 col-md-9 col-lg-6">
          <div class="section-content">
            <div v-html="explore.title" class="section-header"></div>
            <div v-html="explore.description" class="section-description"></div>
            <a href="/kule-hakkinda" class="show-more">
              {{ $t("homepage.read_detail") }}
              <img src="@/assets/images/icons/right-arrow-green.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="background-image">
      <img src="@/assets/images/lines-1.svg" alt="" />
    </div>-->
  </section>
</template>

<script>
import ExploreService from "@/services/content/explore-service";

export default {
  name: "Featured",
  data() {
    return {
      lang: "tr",
      explore: { version: -1 }
    };
  },
  async created() {
    const self = this;
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
    if (this.lang === "tr" && localStorage.getItem("explore_tr") != null) {
      self.explore = JSON.parse(localStorage.getItem("explore_tr"));
    } else if (this.lang === "en" && localStorage.getItem("explore_en") != null) {
      self.explore = JSON.parse(localStorage.getItem("explore_en"));
    }
    await this.getExplore();
  },
  methods: {
    async getExplore() {
      const self = this;
      const data = ExploreService.getAllRecords(this.lang, self.explore.version);
      await data.then((res) => {
        if (res.status === 200) {
          if (res.data.numberOfElements > 0) {
            const first = res.data.content[0];
            self.explore = first;
            if (this.lang === "tr") {
              localStorage.setItem("explore_tr", JSON.stringify(self.explore));
            } else if (this.lang === "en") {
              localStorage.setItem("explore_en", JSON.stringify(self.explore));
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
