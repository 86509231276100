<template>
  <div class="bg-white tab-summary">
    <div class="row">
      <div class="col-xs-12">
        <div class="box-header">{{ $t('reservation.info_summary') }}</div>
        <div class="section-description">
          <p>{{ $t('reservation.info_summary_desc') }}</p>
        </div>
      </div>
    </div>
    <div class="row between-xs summary-header">
      <div v-bind:key="index"
           v-for="(ticket, index) in reservationSummary.tickets" class="col-xs-12">
        <div class="box-mini-header">{{ index+1 }}. {{ $t('reservation.ticket_detail') }}</div>
        <div class="summary-box middle-xs">
          <div class="col-xs-12 col-lg-3">
            <div class="section-description">{{ $t('reservation.ticket_type_single') }}:
              <div class="ticket-type-name"> {{ ticket.type.name }}</div>
            </div>

          </div>
          <div class="col-xs-12 col-lg-6">
            <div class="section-description">{{ $t('reservation.extra_service') }}:</div>
            <div v-for="(additionalData, index2) in ticket.additionalServices"
                 v-bind:key="index2"
                 class="detail">
              {{ additionalData.name }}
            </div>
            <div v-if="ticket.additionalServices.length == 0">
              {{ $t('reservation.extra_service_ns') }}
            </div>
          </div>
          <div class="col-xs-12 col-lg-3 seperator">
            <div class="total">{{ currencyFormat(ticket.total_price.total_sale_price) }} TL</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationPreview",
  props: ["validateStepData", "reservationSummary", "reservationData", "reservationTicketInfo"],
  data() {
    return {
      saveReservationObject: {
        token: this.$session.get("restoken"),
        date: "",
        ticketCount: 0,
        regularReservationTimeId: "",
        contactInformation: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: ""
        },
        tickets: {}
      }
    };
  },
  watch: {
    validateStepData: {
      handler(newVal) {
        if (newVal) {
          this.$session.set("resdet", this.reservationData);
          this.$session.set("ressummary", this.reservationSummary);
          this.$session.set("resinfo", this.reservationTicketInfo);
          this.validateStep();
        }
      }
    }
  },
  methods: {
    validateStep() {
      this.$emit("step-completed", this.stepData, 4);
    }
  }
};
</script>

<style scoped>

</style>
