<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="account hero">
      <div class="container no-gutter-xs">
        <div class="row middle-xs between-xs">
          <div class="col-xs-12 center-xs">
            <div class="section-header">{{ $t('account.change_password') }}</div>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 col-md-6 margin-auto">
            <MessageBar></MessageBar>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 col-md-6 no-gutter-xs">
            <div class="bg-white">
              <form data-vv-scope="change-password">
                <div class="material-form-group">
                  <input v-model="requestData.currentPassword"
                         v-validate="{ required: true, min: 6 }"
                         name="Mevcut Şifre"
                         type="password" placeholder=" " />
                  <label>{{ $t('account.current_password') }}</label>
                  <span v-show="errors.has('change-password.Mevcut Şifre')" class="error-message">
                    {{ errors.first("change-password.Mevcut Şifre") }}
                  </span>
                </div>
                <div class="material-form-group">
                  <input v-model="requestData.newPassword"
                         v-validate="{ required: true }"
                         name="new_password"
                         ref="new_password" type="password" placeholder=" " />
                  <label>{{ $t('account.new_password') }}</label>
                </div>
                <ul class="requirements">
                  <li :class="{ ok: passwordValidation.charLength }">
                    {{ $t('register.password_six_chars') }}
                  </li>
                  <li :class="{ ok: passwordValidation.uppercaseChar }">
                    {{ $t('register.password_uppercase') }}
                  </li>
                  <li :class="{ ok: passwordValidation.lowercaseChar }">
                    {{ $t('register.password_lowercase') }}
                  </li>
                  <li :class="{ ok: passwordValidation.number }">
                    {{ $t('register.password_number') }}
                  </li>
                </ul>
                <div class="material-form-group">
                  <input v-model="requestData.newPasswordAgain"
                         v-validate="{ required: true, confirmed: 'new_password' }"
                         name="new_password_again"
                         type="password" placeholder=" " />
                  <label>{{ $t('account.new_password_reenter') }}</label>
                </div>
                <ul class="requirements">
                  <li :class="{ ok: passwordValidation.repeatPasswordSame }">
                    {{ $t('register.password_same') }}
                  </li>
                </ul>
                <div @click="goBackAccount" class="button filled b grey">
                  {{ $t('account.cancel') }}
                </div>
                <div @click="changePassword" class="button filled green b">
                  <vue-element-loading
                    :active="isLoading"
                    background-color="rgba(255, 255, 255, 0.8)"
                    color="#00baab"
                    spinner="spinner"
                  />
                  {{ $t('account.save') }}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import CustomerService from "@/services/customer/customer-service";
import { mapActions } from "vuex";
import MessageBar from "@/components/main/MessageBar.vue";

export default {
  name: "ChangePassword",
  components: {
    MessageBar, BackgroundMovement, Footer, HeaderNav
  },
  beforeCreate() {
    if (typeof this.$session.get("cusdet") === "undefined") {
      this.$router.push("/");
    }
  },
  data() {
    return {
      isLoading: false,
      requestData: {
        currentPassword: "",
        newPassword: "",
        newPasswordAgain: ""
      },
      passwordValidation: {
        charLength: false,
        uppercaseChar: false,
        lowercaseChar: false,
        number: false,
        repeatPasswordSame: false
      }
    };
  },
  watch: {
    "requestData.newPasswordAgain": {
      handler() {
        this.$validator.validate("change-password.new_password_again").then((result) => {
          if (result) {
            this.passwordValidation.repeatPasswordSame = true;
          } else {
            this.passwordValidation.repeatPasswordSame = false;
          }
        });
      }
    },
    "requestData.newPassword": {
      handler(newVal) {
        const charRegex = RegExp(/^.{6,}$/);
        const uppercaseCharRegex = RegExp(/.*[A-Z].*/);
        const lowercaseCharRegex = RegExp(/.*[a-z].*/);
        const numberRegex = RegExp(/\d/);

        if (charRegex.test(newVal)) {
          this.passwordValidation.charLength = true;
        } else {
          this.passwordValidation.charLength = false;
        }

        if (uppercaseCharRegex.test(newVal)) {
          this.passwordValidation.uppercaseChar = true;
        } else {
          this.passwordValidation.uppercaseChar = false;
        }

        if (lowercaseCharRegex.test(newVal)) {
          this.passwordValidation.lowercaseChar = true;
        } else {
          this.passwordValidation.lowercaseChar = false;
        }

        if (numberRegex.test(newVal)) {
          this.passwordValidation.number = true;
        } else {
          this.passwordValidation.number = false;
        }

        if (this.requestData.newPasswordAgain.length > 0) {
          this.$validator.validate("change-password.new_password_again").then((result) => {
            if (result) {
              this.passwordValidation.repeatPasswordSame = true;
            } else {
              this.passwordValidation.repeatPasswordSame = false;
            }
          });
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "showMessageBar"
    ]),
    goBackAccount() {
      this.$router.push("/hesabim");
    },
    changePassword() {
      this.isLoading = true;

      this.$validator.validateAll("change-password").then((result) => {
        if (result && this.checkObject(this.passwordValidation, true)) {
          const data = CustomerService.changePassword(this.requestData);

          data.then((res) => {
            if (res.status === 200) {
              this.showMessageBar({
                message: this.$t("generic.successful"),
                type: "success"
              });
              this.$router.push("/hesabim");
            } else {
              this.showMessageBar({
                message: `${this.$t("generic.unexpected_error")}(${res.message})`,
                type: "error"
              });
            }

            this.isLoading = false;
          });
        } else {
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.error-message {
  color: #d60d38;
  font: 400 10px/18px "Poppins", sans-serif;
  text-align: left;
}
.button.grey {
  background-color: rgba(10,10,15,.1);
}
</style>
