<template>
  <section class="homepage-slider">
    <div class="container">
      <div class="row bottom-lg m-auto">
        <div class="col-xs-12 col-sm-12 col-lg-12">
          <div class="section-content">
            <splide :options="options">
              <splide-slide v-for="(item, index) in sliderData" :key="index">
                <a :href="`${item.url}`">
                  <img :src="`${item.image}`">
                 </a>
              </splide-slide>
            </splide>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slider",
  props: ["sliderData"],
  data() {
    return {
      options: {
        rewind: true,
        width: "100%",
        gap: "1rem",
        pagination: false,
        autoplay: true
      }
    };
  }
};
</script>

<style scoped>

</style>
