<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="about hero">
      <div class="container" v-html="about.content"></div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <!--<section class="feed bg-black">
      <div class="container">
        <div class="row no-wrap">
          <div class="col-xs-5 col-sm-4 col-md-3">
            <div class="section-mini-header">Bizi Takip Edin</div>
            <div class="section-header">Sosyal Medya</div>
          </div>
          <div class="col-xs-5 col-sm-4 col-md-3">
            <img src="@/assets/images/dummy-content/1080x1080.jpg" alt="">
          </div>
          <div class="col-xs-5 col-sm-4 col-md-3">
            <img src="@/assets/images/dummy-content/1080x1080.jpg" alt="">
          </div>
          <div class="col-xs-5 col-sm-4 col-md-3">
            <img src="@/assets/images/dummy-content/1080x1080.jpg" alt="">
          </div>
          <div class="col-xs-5 col-sm-4 col-md-3">
            <img src="@/assets/images/dummy-content/1080x1080.jpg" alt="">
          </div>
          <div class="col-xs-5 col-sm-4 col-md-3">
            <img src="@/assets/images/dummy-content/1080x1080.jpg" alt="">
          </div>
          <div class="col-xs-5 col-sm-4 col-md-3">
            <img src="@/assets/images/dummy-content/1080x1080.jpg" alt="">
          </div>
          <div class="col-xs-5 col-sm-4 col-md-3">
            <img src="@/assets/images/dummy-content/1080x1080.jpg" alt="">
          </div>
          <div class="col-xs-5 col-sm-4 col-md-3">
            <img src="@/assets/images/dummy-content/1080x1080.jpg" alt="">
          </div>
        </div>
      </div>
    </section>-->
    <BuyTicketComponent></BuyTicketComponent>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";
import BuyTicketComponent from "@/components/main/BuyTicketComponent.vue";
import Footer from "@/components/main/Footer.vue";

import PagesService from "@/services/content/pages-service";

export default {
  name: "About",
  data() {
    return {
      lang: "tr",
      about: { version: -1 }
    };
  },
  components: {
    HeaderNav,
    BackgroundMovement,
    BuyTicketComponent,
    Footer
  },
  async created() {
    const self = this;
    if (typeof this.$route.query.lang !== "undefined") {
      this.lang = this.$route.query.lang;
    } else if (localStorage.getItem("kulewslang") == null) {
      this.lang = this.$i18n.locale;
    } else {
      this.lang = localStorage.getItem("kulewslang");
    }
    if (this.lang === "tr" && localStorage.getItem("about_tr") != null) {
      self.about = JSON.parse(localStorage.getItem("about_tr"));
    } else if (this.lang === "en" && localStorage.getItem("about_en") != null) {
      self.about = JSON.parse(localStorage.getItem("about_en"));
    }
    await this.prepareAbout();
  },
  methods: {
    prepareAbout() {
      const self = this;
      const requestData = { lang: this.lang, version: self.about.version };
      const data = PagesService.getAboutPage(requestData);

      data.then((res) => {
        if (res.status === 200) {
          if (res.data.numberOfElements > 0) {
            const first = res.data.content[0];
            self.about = first;
            if (this.lang === "tr") {
              localStorage.setItem("about_tr", JSON.stringify(self.about));
            } else if (this.lang === "en") {
              localStorage.setItem("about_en", JSON.stringify(self.about));
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.full-width-text {
  padding-top: 40px;
}
</style>
