<template>
  <section class="popup">
    <div class="popup-body no-padding relative">
      <div @click="closePopup" class="popup-closer"></div>
      <img
        :src="isDynamic ? `${appContentStorageUrl}${image}` : require(`@/assets/images/${image}`)"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "BannerPopup",
  props: {
    image: {
      type: String,
      required: true
    },
    isDynamic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      appContentStorageUrl: process.env.VUE_APP_CONTENT_STORAGE_URL
    };
  },
  methods: {
    closePopup() {
      this.$emit("popup-closed");
    }
  }
};
</script>

<style scoped>
.no-padding {
  padding: 0 !important;
}
</style>
