<template>
  <div>
    <HeaderNav></HeaderNav>
    <section class="privacy-policy hero">
      <div class="container no-gutter-xs">
        <div class="row center-xs">
          <div class="col-xs-12 col-md-9">
            <div class="section-header">
              Gizlilik ve Veri Güvenlik Politikası
            </div>
          </div>
        </div>
      </div>
      <BackgroundMovement></BackgroundMovement>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/main/HeaderNav.vue";
import Footer from "@/components/main/Footer.vue";
import BackgroundMovement from "@/components/main/BackgroundMovement.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    BackgroundMovement,
    Footer,
    HeaderNav
  }
};
</script>
